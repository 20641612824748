<!--  -->
<template>
  <div class="headnav">

    <div class="clearfix">

      <div id="head">
        <img src="../assets/tubiao.png" alt="" class="logo" @click="logo" />
        <div class="head_btn">
          <button class="btn1 none" @click="btn3"  style="display:none">模型库</button>
          <button class="btn1 none" @click="btn1">服务升级</button>
          <button class="btn2 none" @click="btn2">上传模型</button>
          <div @click="msg()" style="cursor: pointer" class="pointer">
            <img src="../assets/ring.png" alt="" class="ring" />
            <span class="tips" v-if="num > 0">{{ num }}</span>
            <!-- <img src="../assets/tips.png" alt="" class="tips" /> -->
          </div>
          <el-dropdown v-if="show1" class="none">
            <el-button type="primary">
              <router-link to="/login">登录</router-link>
            </el-button>
          </el-dropdown>
          <el-dropdown v-if="show2">
            <el-button type="primary" >
              <img
                :src="url != null ? url : '../assets/personal.png'"
                alt=""
                class="icon head"
                :onerror="defal"
              />
              <p class="name" :title="username">个人中心</p>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item @click.native="img1_c()"
                ><img :src="img1" alt="" />工作台</el-dropdown-item
              >
              <el-dropdown-item @click.native="img2_c()"
                ><img :src="img2" alt="" />我的资产</el-dropdown-item
              >
              <!-- <el-dropdown-item @click.native="img3_c()"
                ><img :src="img3" alt="" />我的模型</el-dropdown-item
              > -->
              <el-dropdown-item @click.native="img4_c()"
                ><img :src="img4" alt="" />轻量化</el-dropdown-item
              >
              <el-dropdown-item @click.native="img5_c()"
                ><img :src="img5" alt="" />我的设置</el-dropdown-item
              >
              <el-dropdown-item @click.native="img6_c()"
                ><img :src="img6" alt="" />退出登录</el-dropdown-item
              >
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
    </div>
    <div class="phone">
        <div class="phone_box" @click="btn3()" ><img :src="currentIndex === 1 ?ic1_1:ic1" alt="" ><span>模型库</span></div>
        <div class="phone_box" @click="btn1()"><img :src="currentIndex === 2 ?ic2_1:ic2" alt=""  ><span>服务升级</span></div>
        <div class="phone_box" @click="btn2()"  ><img :src="currentIndex === 3 ?ic3_1:ic3" alt="" ><span>上传模型</span></div>
        <div class="phone_box"><el-dropdown v-if="show1">
            <el-button type="primary">
              <img :src="currentIndex === 4 ?ic4_1:ic4" alt=""> 
              <router-link to="/login"><span>登录</span></router-link>
            </el-button>
          </el-dropdown>
          <el-dropdown v-if="show2" >
            <el-button type="primary" @click.native="btn4()">
              <img :src="currentIndex === 4 ?ic4_1:ic4" alt=""> 
              <span :title="username">个人中心</span>
            </el-button>
            <el-dropdown-menu slot="dropdown" hide-on-click="true">
              <el-dropdown-item @click.native="img1_c()"
                ><img :src="img1" alt="" />工作台</el-dropdown-item
              >
              <el-dropdown-item @click.native="img2_c()"
                ><img :src="img2" alt="" />我的资产</el-dropdown-item
              >
              <!-- <el-dropdown-item @click.native="img3_c()"
                ><img :src="img3" alt="" />我的模型</el-dropdown-item
              > -->
              <el-dropdown-item @click.native="img4_c()"
                ><img :src="img4" alt="" />轻量化</el-dropdown-item
              >
              <el-dropdown-item @click.native="img5_c()"
                ><img :src="img5" alt="" />我的设置</el-dropdown-item
              >
              <el-dropdown-item @click.native="img6_c()"
                ><img :src="img6" alt="" />退出登录</el-dropdown-item
              >
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
  </div>
  
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import cookie from "../../public/js/cookie";
import axios from "../../untils/request";
export default {
  name: "headnav",

  //import引入的组件需要注入到对象中才能使用
  components: {},

  //父组件传入子组件数据
  props: {},

  //格式化数据
  filters: {},

  data() {
    //这里存放数据
    return {
      show1: false,
      show2: true,
      username: "",
      url: "",
      img1: require("../assets/bar1.png"),
      img2: require("../assets/bar2.png"),
      img3: require("../assets/bar3.png"),
      img4: require("../assets/bar4.png"),
      img5: require("../assets/bar5.png"),
      img6: require("../assets/bar6.png"),
      num: "",
      ic1: require("../assets/ic1.png"),
      ic1_1: require("../assets/ic1-1.png"),
      ic2: require("../assets/ic2.png"),
      ic2_1: require("../assets/ic2-1.png"),
      ic3: require("../assets/ic3.png"),
      ic3_1: require("../assets/ic3-1.png"),
      ic4: require("../assets/ic4.png"),
      ic4_1: require("../assets/ic4-1.png"),
      currentIndex: 1,
      defal: 'this.src="' + require("../assets/personal.png") + '"',
    };
  },

  //计算属性 类似于data概念
  computed: {},

  //监控data中的数据变化
  watch: {},

  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.getUser();
    this.getMsg();
    // if(this.currentIndex == 1){
    //   this.$router.push({ name: "list" });
    // }
  },

  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},

  //方法集合
  methods: {
    logo() {
      this.$router.push({ name: "index" });
      // window.open('https://www.wiiboox.net/wiibooxmeta.php')
      // window.location.href = 'https://www.wiiboox.net/wiibooxmeta.php'
    },
    btn4() {
      this.currentIndex = 4
      // this.show3 = true
    },
    btn2() {
      //
      this.currentIndex = 3
      if (cookie.get("token") == undefined) {
        this.$router.push({ name: "login" });
      } else {
        axios
          .get("/wiiboox-biz/tModelProject/uploadCheck", {
            headers: {
              "content-type": "application/x-www-form-urlencoded",
              Authorization: "Bearer " + cookie.get("token"),
            },
          })
          .then((res) => {
            if (res.data == true) {
              this.$router.push({ name: "upload_file" });
            } else {
              this.$message(res.message);
            }
          });
      }
    },
    btn1() {
      this.currentIndex = 2
      this.$router.push({ name: "subscribe" });
    },
    btn3() {
      this.currentIndex = 1
      this.$router.push({ name: "list" });
    },
    getUser() {
      let headers = {};
      if (cookie.get("token") == undefined) {
        headers = { "Content-type": "application/x-www-form-urlencoded" };
      } else {
        headers = {
          "Content-type": "application/x-www-form-urlencoded",
          Authorization: "Bearer " + cookie.get("token"),
        };
      }
      axios
        .get("/wiiboox-admin/sysUser/info", {
          headers: headers,
        })
        .then((res) => {
          this.username = res.data.username;
          this.url = res.data.icon;
          if (res.data.icon == null) {
            this.url = require("../assets/personal.png");
          }
        })
        .catch(() => {
          this.url = require("../assets/personal.png");
          this.username = "用户";
          this.show2 = false;
          this.show1 = true;
        });
    },
    getMsg() {
      let headers = {};
      if (cookie.get("token") == undefined) {
        headers = { "Content-type": "application/x-www-form-urlencoded" };
      } else {
        headers = {
          "Content-type": "application/x-www-form-urlencoded",
          Authorization: "Bearer " + cookie.get("token"),
        };
      }
      axios
        .get("/wiiboox-biz/tMessageNotice/getUnReadCount", {
          headers: headers,
        })
        .then((res) => {
          this.num = res.data;
          if (this.num > 99) {
            return (this.num = 99);
          }
        });
    },
    img6_c() {
      axios
        .get("/wiiboox-admin/sysUser/logout", {
          headers: {
            "Content-type": "application/x-www-form-urlencoded",
            Authorization: "Bearer " + cookie.get("token"),
          },
        })
        .then((res) => {
          cookie.delete("token");
          cookie.delete("userId");
          this.$router.push({ name: "login" });
        });
    },
    img2_c() {
      this.$router.push({ name: "upload" });
    },
    img3_c() {
      this.$router.push({ name: "myModel" });
    },
    img4_c() {
      this.$router.push({ name: "results" });
    },
    img5_c() {
      this.$router.push({ name: "personal" });
    },
    img1_c() {
      this.$router.push({ name: "index" });
    },
    img5_c() {
      this.$router.push({ name: "personal" });
    },
    msg() {
      this.$router.push({ name: "notice" });
    },
  },

  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>

<style scoped>
#head {
  width: 100%;
  min-height: 100%;
  height: 100%;
}
.clearfix {
  width: 1440px;
  margin: 0 auto;
  background: #000;
}
.clearfix:before,
.clearfix:after {
  content: " ";
  display: table;
}

.clearfix:after {
  clear: both;
}
.left {
  width: 10%;
  height: 100vh;
  float: left;
}
.right {
  width: 10%;
  height: 100vh;
  float: right;
}
.logo {
  padding-top: 10px;
  cursor: pointer;
}
.head_btn {
  float: right;
}
.el-dropdown {
  vertical-align: top;
}
.el-dropdown + .el-dropdown {
  margin-left: 15px;
}
.el-icon-arrow-down {
  font-size: 12px;
}
::v-deep .el-popper .popper__arrow {
  display: none !important;
}

.head_btn {
  display: flex;
  justify-content: space-between;
}
.btn1 {
  display: block;
  height: 25px;
  padding: 0 25px;
  background: #333333;
  border: 1px solid #666666;
  margin-top: 17px;
  color: #bfbfbf;
  border-radius: 3px;
  margin-right: 25px;
  cursor: pointer;
}
.btn2 {
  display: block;
  height: 25px;
  padding: 0 25px;
  background: #1a8aff;
  border: 1px solid #1a8aff;
  margin-top: 17px;
  color: #ffffff;
  border-radius: 3px;
  margin-right: 25px;
  cursor: pointer;
}
.ring {
  margin-top: 17px;
  margin-right: 30px;
}
.tips {
  font-size: 12px;
  position: relative;
  bottom: 36px;
  left: 10px;
  display: block;
  background: red;
  color: #fff;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  text-align: center;
  line-height: 20px;
}
.el-dropdown .el-button--primary {
  border: 0;
  background: #333333;
  min-height: 57px;
  height: 100%;
  border-radius: 0;
}
.el-button .el-button--primary .el-dropdown-selfdefine {
  padding: 0 36px;
}
.el-dropdown .el-button {
  padding: 0;
  width: 150px;
}
.el-dropdown .el-button span {
  display: block;
  width: 100%;
}
.el-dropdown .el-button span .head {
  float: left;
  /* padding-left: 25px; */
}
.el-dropdown .el-button span p {
  padding-top: 7px;
  margin-left: 62px;
  text-align: left;
}
.el-dropdown-menu {
  width: 150px;
  background-color: #1d1d1d;
  border: 0;
  border-radius: 0 !important;
  margin: 0 !important;
}
.el-popper {
  background-color: #1d1d1d !important;
  border: 0 !important;
}
.el-dropdown-menu .popper__arrow {
  display: none !important;
}
.el-dropdown-menu__item:focus,
.el-dropdown-menu__item:not(.is-disabled):hover {
  background: #333333 !important;
  color: #cccccc !important;
}
.el-dropdown-menu__item img {
  padding-right: 15px;
  position: relative;
  top: 5px;
}
.icon {
  /* width: 28px;
  border-radius: 50%; */
  /* width: 100%; */
  width: 28px;
  height: 28px;
  border-radius: 50%;
  display: block;
  position: absolute;
  left: 22px;
}
a {
  color: #fff;
  list-style: none;
  text-align: center;
  text-decoration: none;
}
.name {
  height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 88px;
}
.phone{
  display: none;
}
.none{
    display: block;
  }
@media only screen and (max-width: 767px) {
  .clearfix {
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    padding: 15px;
  }
  /* #head{
    position: fixed;
    top: 0;
  } */
  .el-dropdown .el-button {
    min-height: 50px;
  }
  .logo {
    width: 150px;
    padding: 0;
  }
  .btn1 {
    padding: 0;
    margin-right: 0;
    margin-top: 0;
    font-size: 12px;
    width: 25%;
    height: 50px;
    border: 0;
    border-radius: 0;
  }
  .btn2 {
    padding: 0;
    margin-right: 0;
    margin-top: 0;
    font-size: 12px;
    width: 25%;
    height: 50px;
    border: 0;
    border-radius: 0;
  }
  /* .ring{
  
  } */
  .pointer {
    margin-right: 10px;
    position: fixed;
    right: 10px;
    top: 0;
  }
  .text {
    display: flex;
  }
  .head_btn {
    width: 100%;
    background: #000000;
    position: fixed;
    bottom: 0;
    left: 0;

    z-index: 999;
  }
  .name {
    font-size: 12px;
  }
  .el-dropdown .el-button{
    /* width: 100%; */
  }
  .phone{
    display: block;
    position: fixed;
    left: 0;
    bottom: 0;
    z-index: 999;
    background: #000;
    width: 100%;
    color: #fff;
    font-size: 12px;
    display: flex;
    justify-content: space-around;

  }
  .phone .phone_box{
   width: 25%;
   padding: 10px 0;
  }
  .phone img{
    width: 25px;
    height: 25px;
   display: flex;
   margin: 0 auto;
  }
  .phone div span{
    display: block;
    width: 100%;
    text-align: center;
    margin-top: 5px;
    height: 16px;
    font-size: 12px;
  }
 
  .el-dropdown{
    width: 100%;
  }
  .el-dropdown .el-button--primary{
    background: #000;
    min-height: auto;
    width: 100%;
  }
  /* ::v-deep .el-dropdown .el-button{
    width: 100%;
  } */
  .active{
    color: #000;
  }
  .el-popper  ::v-deep{
    margin-bottom: 10px !important;
  }
  .none{
    display: none;
  }
}
</style>