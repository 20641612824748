import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'element-ui/lib/theme-chalk/index.css';
import ElementUI from 'element-ui';
import axios from 'axios'
import vueQr from 'vue-qr'
import CryptoJS from 'crypto-js'
import VueAxios from 'vue-axios'
import leftnav from '../src/views/left-nav'
import headnav from '../src/views/head'
import uploader from 'vue-simple-uploader'
import MainComment from '../src/views/model/mainComment.vue'
import parentCommit from '../src/views/model/parent.vue'
import MainComment1 from '../src/views/template/mainComment1.vue'
import parentCommit1 from '../src/views/template/parent1.vue'
import parentCommit2 from '../src/views/template/parent2.vue'
import uupy from '../src/views/template/uupy.vue'
import NProgress from "nprogress";
Vue.use(uploader)
import cookie from '../public/js/cookie.js'
import upload_file from '../src/views/template/upload_file.vue';
import qs from 'qs'
import Clipboard from 'clipboard'
import Router from 'vue-router'
import originJSONP from 'jsonp'
import { VueJsonp } from 'vue-jsonp'
// import screenfull from 'screenfull'
Vue.use(VueJsonp)
import * as THREE from "three";
// 导入 vue-router 模块
import VueRouter from 'vue-router'
Vue.use(VueRouter)
 
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
// 调用回调方法 让浏览器不再报出这个错误
  return originalPush.call(this, location).catch(err => err)
}
Vue.config.productionTip = false
Vue.directive('preventReClick', {
  inserted(el, binding) {
    el.addEventListener('click', () => {
      if (!el.disabled) {
        el.disabled = true
        setTimeout(() => {
          el.disabled = false
        }, binding.value || 3000)
      }
    })
  }
});
Vue.use(ElementUI,originJSONP,CryptoJS,NProgress,VueAxios,axios,qs,leftnav,cookie,headnav,upload_file,MainComment,parentCommit,MainComment1,parentCommit1,parentCommit2,uupy,vueQr,Clipboard);
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
