<!--  -->
<template>
  <div class="personal">
    <div class="header">
      <span>个人资料</span>
    </div>
    <div class="box">
      <div class="content">
        <div class="person1">
          <el-upload
            ref="uploadPic"
            action="/api/upload"
            class="avatar-uploader"
            :on-change="handleChange"
            :on-remove="handleRemove"
            :headers="getupload()"
            :auto-upload="false"
            :on-success="handleAvatarSuccess"
            :before-upload="beforeAvatarUpload"
          >
            <img v-if="imageUrl" :src="imageUrl" class="avatar" />
            <img v-else :src="url" alt="" class="avatar" />
            <!-- <i v-else class="el-icon-plus avatar-uploader-icon"></i> -->
            <el-button slot="trigger" size="small" type="primary"
              >上传头像</el-button
            >
            <p>最大512x512像素，JPG或PNG，最大2MB</p>
          </el-upload>
        </div>
        <div class="ipt1 ipt">
          <span>昵称：</span>
          <el-input v-model="nickname" placeholder="请输入内容"></el-input>
        </div>
        <div class="ipt" style="display: none">
          <span></span>
          <p>3个月修改一次用户名</p>
        </div>
        <div class="ipt">
          <span>性别：</span>
          <div style="height: 30px; line-height: 30px">
            <el-radio v-model="radio" label="0">男</el-radio>
            <el-radio v-model="radio" label="1">女</el-radio>
            <el-radio v-model="radio" label="2">保密</el-radio>
          </div>
        </div>
        <div class="ipt">
          <span>公司名称：</span
          ><el-input v-model="company" placeholder="请输入内容"></el-input>
        </div>
        <div class="ipt">
          <span>职务：</span
          ><el-input v-model="post" placeholder="请输入内容"></el-input>
        </div>
        <div class="ipt">
          <span>手机：</span
          ><el-input v-model="phone" placeholder="请输入内容"></el-input>
        </div>
        <div class="ipt">
          <span>邮箱号：</span
          ><el-input v-model="email" placeholder="请输入内容"></el-input>
        </div>
        <div class="btn">
          <button class="blue" @click="btn" v-preventReClick>保存个人信息</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import cookie from "../../../public/js/cookie";
import axios from "../../../untils/request";
import qs from "qs";
export default {
  name: "",
  inject: ["reload"],
  //import引入的组件需要注入到对象中才能使用
  components: {},

  //父组件传入子组件数据
  props: {
    name: {
      //上传字段的name
      type: [String],
      required: false,
      default: "files",
    },
    value: {
      type: String,
      default: "",
    },
    // 大小限制(MB)
    fileSize: {
      type: Number,
      default: 600,
    },
    // 是否显示提示
    isShowTip: {
      type: Boolean,
      default: true,
    },
  },

  //格式化数据
  filters: {},

  data() {
    //这里存放数据
    return {
      id: "",
      radio: "",
      imageUrl: "",
      isUploadPic: false,
      uploadDisabled: false,
      dialogVisible: false,
      fileList: [],
      nickname: "",
      phone: "",
      post: "",
      email: "",
      company: "",
      input: "",
      url: require("../../assets/personal.png"),
      fileList: [],
      uploadFiles: [], //待上传的文件列表
      // uploadImgUrl:"http://47.114.99.82:8201/" + "wiiboox-biz/tModelFile/upload", // 上传的图片服务器地址
      uploadImgUrl: "111",
      headers: {
        // "content-type": "application/x-www-form-urlencoded",
        Authorization: "Bearer " + cookie.get("token"),
      },
    };
  },

  //计算属性 类似于data概念
  computed: {},

  //监控data中的数据变化
  watch: {},

  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.getAll();
  },

  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},

  //方法集合
  methods: {
    //判断图片路径
    CheckImgExists(imgurl) {
      let that = this;
      return new Promise(function (resolve, reject) {
        var ImgObj = new Image(); //判断图片是否存在
        ImgObj.src = imgurl;
        ImgObj.onload = function (res) {
          return imgurl;
        };
        ImgObj.onerror = function (err) {
          return (that.url = require("../../assets/personal.png"));
        };
      });
    },
    getAll() {
      axios
        .get("/wiiboox-admin/sysUser/getUserInfo", {
          headers: {
            "content-type": "application/x-www-form-urlencoded",
            Authorization: "Bearer " + cookie.get("token"),
          },
        })
        .then((res) => {
          this.id = res.data.id;
          this.phone = res.data.phone;
          this.radio = res.data.sex;
          this.company = res.data.company;
          this.post = res.data.post;
          this.nickname = res.data.nickname;
          this.email = res.data.email;
          this.url = res.data.icon;
          // this.imageUrl = res.data.icon
          this.CheckImgExists(res.data.icon);
        });
    },
    btn() {
      let data = {
        company: this.company,
        email: this.email,
        id: this.id,
        nickname: this.nickname,
        file: this.file,
        phone: this.phone,
        post: this.post,
        sex: this.radio,
      };
      // let formData = new FormData()
      // formData.append("icon",this.file)
      let reg =
        /^1(3[0-9]|4[5,7]|5[0,1,2,3,5,6,7,8,9]|6[2,5,6,7]|7[0,1,7,8,6]|8[0-9]|9[1,8,9])\d{8}$/;
      let em = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-])+/;
      if (!reg.test(this.phone) || !this.phone.length == 11) {
        this.$message("手机号格式不正确");
      } else if (!em.test(this.email)) {
        this.$message("邮箱格式不正确");
      } else {
        axios
          .post("/wiiboox-admin/sysUser/update", data, {
            headers: {
              "content-type": "multipart/form-data",
              Authorization: "Bearer " + cookie.get("token"),
            },
          })
          .then((res) => {
            this.$message({
              showClose: true,
              message: res.message,
              type: "success",
            });
            this.reload();
          });
      }
    },
    getupload() {
      return { Authorization: "Bearer " + cookie.get("token") };
    },
    //点击 按钮 弹窗上传
    handleForUpload() {
      this.dialogVisible = true;
    },
    //点击 upload btn 开始 上传
    uploadPicture() {
      // 将上传的文件附件转成二进制转给后台 this.form就是表单输入框的内容
      const formData = new FormData();
      formData.append("file", this.file);
      this.isUploadPic = true;
      uploadOrganizationAvatar(formData).then(async (res) => {
        this.refresh_info();
        this.dialogVisible = false;
        this.isUploadPic = false;
      });
    },
    handleChange(file, fileList) {
      const isJPG = file.raw.type === "image/jpeg";
      const isPNG = file.raw.type === "image/png";
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG && !isPNG) {
        this.$message.error("上传头像图片只能是 JPG, PNG!");
      } else if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 2MB!");
      } else {
      URL.createObjectURL(file.raw);
      this.file = file.raw;
      this.fileName = file.name;
      this.url = URL.createObjectURL(file.raw);
      if (fileList.length >= 1) {
        // this.uploadDisabled = true;
      }
      }
      
      // return (isJPG || isPNG) && isLt2M;

    },
    handleRemove(file) {
      //imgDelete.post({pic_name : file.response.pic_name}).then();
      this.uploadDisabled = false;
    },
    handleAvatarSuccess(res, file) {
      this.$refs.uploadPic.clearFiles();
      this.imageUrl = res.data;
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === "image/jpeg";
      const isPNG = file.type === "image/png";
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG && !isPNG) {
        this.$message.error("File type only support JPG, PNG!");
      }
      if (!isLt2M) {
        this.$message.error("File size must lower than 2MB!");
      }
      return (isJPG || isPNG) && isLt2M;
    },
  },

  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>

<style scoped>
.box {
  width: 800px;
  height: 470px;
  border: 1px solid #4a4a4a;
  border-radius: 4px;
  position: relative;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.content {
  width: 400px;
  margin: 0 auto;
}
.person1 {
  margin-top: 50px;
}
.person1 img {
  float: left;
}
.person1 p {
  color: #666666;
  font-size: 14px;
  margin-top: 10px;
  margin-left: 20px;
  float: left;
}
.person1 button {
  background: none;
  color: #fff;
  border: 1px solid #666666;
  padding: 7px 40px;
  border-radius: 3px;
  margin-left: 20px;
}
::v-deep .avatar {
  width: 57px;
  height: 57px;
}
.ipt {
  margin-top: 10px;
}
.ipt span {
  display: block;
  float: left;
  width: 73px;
  color: #cccccc;
  font-size: 14px;
  height: 30px;
  line-height: 30px;
  padding-right: 20px;
}
.ipt1 {
  margin-top: 50px;
}
.el-input {
  width: 73%;
  height: 30%;
}
>>> .el-input__inner {
  height: 30px;
  border: 0;
  background: #2c2c2c;
}
.ipt p {
  color: #666666;
  font-size: 14px;
  padding: 3px 0;
}
::v-deep.el-radio__inner {
  border-radius: 2px;
  border: 1px solid #606266;
}
::v-deep.el-radio {
  margin-right: 10px;
}
.btn {
  width: 100%;
  margin: 130px auto;
}
.btn .blue {
  display: block;
  width: 40%;
  padding: 5px 20px;
  margin: 0 auto;
  background: #1a8aff;
  color: #fff;
  border: 0;
  border-radius: 3px;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
::v-deep .el-radio__inner {
  background: none;
  border-radius: 2px;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 57px;
  height: 57px;
  display: block;
}
.disabled .el-upload--picture-card {
  display: none !important;
}
::v-deep .el-upload-list {
  display: none !important;
}
::v-deep .el-radio__input.is-checked .el-radio__inner {
  background: none;
  border-color: #dcdfe6;
}
::v-deep .el-radio__input.is-checked + .el-radio__label {
  color: #fff !important;
}
@media only screen and (max-width: 767px) {
  .box{
    width: 100%;
    border: 0;
  }
  .content{
    width: 90%;
    border: 0;
  }
}
</style>