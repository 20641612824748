<!--  -->
<template>
  <div class="subscribe">
    <div class="header">
      <span>订阅升级</span>
      <button class="btn" @click="btn">卡密升级</button>
    </div>
    <div class="nav" v-show="phoneShow">
      <div @click="nav(0)" :class="{active: this.index1 == 0}">体验版</div>
      <div  @click="nav(1)" :class="{active: this.index1 == 1}">专业版</div>
      <div  @click="nav(2)" :class="{active: this.index1 == 2}">商务版</div>
    </div>
    <div class="content" v-show="pcShow">
      <div class="box" v-for="(item, i) in list" :key="i">
        <img
          src="../../assets/subscribe/zhuanye.png"
          alt=""
          v-if="item.name == '专业版'"
        />
        <img
          src="../../assets/subscribe/free.png"
          alt=""
          v-if="item.name == '体验版'"
        />
        <p v-if="item.name == '体验版'" class="ptext">免费体验15天</p>
        <img
          src="../../assets/subscribe/business.png"
          alt=""
          v-if="item.name == '企业版'"
        />
        <p v-if="item.name == '企业版'" class="ptext">面议</p>
        <p v-if="item.name == '专业版'" class="price">
          {{ year }}/年&nbsp;&nbsp;&nbsp;{{ month }}/月
        </p>
        <ul>
          <template >
            <li v-for="(b, index) in item.memberFunctionVos" :key="index">
              {{ b.comment }}  {{b.data}}{{b.unit}}
            </li>
          </template>
        </ul>
        <button v-if="item.name == '体验版'" @click="go()">立即体验</button>
        <button @click="btn2(item)" v-if="item.name == '专业版'">
          立即购买
        </button>
        <button v-if="item.name == '企业版'">联系客服</button>
      </div>
    </div>
    <div class="content" v-show="phoneShow">
      <div class="box" v-for="(item, i) in list" :key="i" :style="index1 === i ? 'display:block' : 'display:none'">
        <img
          src="../../assets/subscribe/zhuanye.png"
          alt=""
          v-if="item.name == '专业版'"
        />
        <img
          src="../../assets/subscribe/free.png"
          alt=""
          v-if="item.name == '体验版'"
        />
        <p v-if="item.name == '体验版'" class="ptext">免费体验15天</p>
        <img
          src="../../assets/subscribe/business.png"
          alt=""
          v-if="item.name == '企业版'"
        />
        <p v-if="item.name == '企业版'" class="ptext">面议</p>
        <p v-if="item.name == '专业版'" class="price">
          {{ year }}/年&nbsp;&nbsp;&nbsp;{{ month }}/月
        </p>
        <ul>
          <template >
            <li v-for="(b, index) in item.memberFunctionVos" :key="index">
              {{ b.comment }}  {{b.data}}{{b.unit}}
            </li>
          </template>
        </ul>
        <button v-if="item.name == '体验版'" @click="go()">立即体验</button>
        <button @click="btn2(item)" v-if="item.name == '专业版'">
          立即购买
        </button>
        <button v-if="item.name == '企业版'">联系客服</button>
      </div>
    </div>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import { inflateRawSync } from "zlib";
import cookie from "../../../public/js/cookie";
import axios from "../../../untils/request";
export default {
  name: "subscribe",

  //import引入的组件需要注入到对象中才能使用
  components: {},

  //父组件传入子组件数据
  props: {},

  //格式化数据
  filters: {},

  data() {
    //这里存放数据
    return {
      list: [],
      id: "",
      month: "",
      year: "",
      textList: [],
      id1: "",
      id2: "",
      id3: "",
      index1:1,
      show1:false,
      pcShow:true,
      phoneShow:false
    };
  },

  //计算属性 类似于data概念
  computed: {
    
  },

  //监控data中的数据变化
  watch: {
    
  },

  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.getList();
    if(window.innerWidth <= 767){
        this.pcShow = false
        this.phoneShow = true
      }
  },

  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    window.addEventListener("resize", this.resize.bind(this), false);
  },

  //方法集合
  methods: {
    
    resize() {
      if(window.innerWidth <= 767){
        this.pcShow = false
        this.phoneShow = true
      } else {
        this.pcShow = true
        this.phoneShow = false
      }
		// if (!this.el || !this.el.parentElement) return;
		// let { clientHeight, clientWidth } = this.el;
	  },
    nav(i){
      this.index1 = i
    },
    go(){
      this.$router.push({ name: "index"});
    },
    btn(){
      this.$router.push({ name: "bindingMember"});
    },
    btn2(item) {
      cookie.set('productId',item.id)
      this.$router.push({ name: "packageSelection", params: item });
    },
    getList() {
      axios
        .get("/wiiboox-biz/tMemberVersion/getVersionAndFunctionList", {
          headers: {
            "Content-type": "application/x-www-form-urlencoded",
            Authorization: "Bearer " + cookie.get("token"),
          },
        })
        .then((res) => {

          // this.list = res.data;

          this.id = res.data[1].id;
          // res.data.forEach((item) => {
          //   this.getFnlist(item.id);
          //   // if(item.text.length != 0){
               
          //   // }
          // })
          this.list =res.data
          // this.list = res.data
          // this.$nextTick(() => {
           
          // });
          this.getprice();
        });
    },
    getprice() {
      axios
        .get("/wiiboox-biz/tMemberVersion/getMeals?id=" + this.id, {
          headers: {
            "Content-type": "application/x-www-form-urlencoded",
            Authorization: "Bearer " + cookie.get("token"),
          },
        })
        .then((res) => {
          this.month = res.data[0].price;
          this.year = res.data[1].price;
        });
    }
  },

  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>

<style scoped>
.content {
  display: flex;
  justify-content: space-around;
  text-align: center;
  padding: 50px;
}
.box {
  background: #262626;
  padding: 50px;
  color: #999999;
  font-size: 14px;
  min-height: 500px;
  position: relative;
}
.box button {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translate(-50%, 0);
  background: #333333;
  border: 0;
  color: #fff;
  padding: 5px 30px;
}
.box ul {
  padding: 20px 0;
}
.box ul li {
  line-height: 25px;
}
.price {
  font-size: 16px;
  font-family: 苹方;
  color: #fff;
  margin-top: -25px;
}
button {
  cursor: pointer;
}
.btn{
  padding: 5px 10px;
  background: #1a8aff;
  border: 0;
  color: #ffffff;
  border-radius: 3px;
  font-size: 15px;
  float: right;
}
.ptext{
  font-size: 16px;
  color: #fff;
  margin-top: -25px;
}
@media only screen and (max-width: 767px) {
  .nav{
    color: #fff;
    width: 100%;
    display: flex;
    justify-content: space-around;
    margin-top: 20px;
  }
  .nav div{
    width: 33%;
    text-align: center;
    height: 30px;
    line-height: 30px;
  }
  .box{
    background: none;
  }
  .box button{
    background: #1a8aff;
  }
  .content{
    padding: 0;
  }
  .active{
    /* border-bottom: 3px solid #1a8aff; */
    background: #1a8aff;
  }
}
</style>