<!-- uupy -->
<template>
  <div class="uupy">
    <!-- <dashboard :uppy="uppy" :props="{meta: {'callback_url': 'http://47.114.99.82:8888/callback', 'path':'%{path}' }}"/> -->
    <dashboard :uppy="uppy" />
    <button @click="sendData(uppy)" class="up_file" v-if="show && this.path !=''">下一步</button>
    <!-- <div class="el-upload__tip" slot="tip" v-if="showTip">
      <p>上传须知：</p>
      1、支持模型格式{{ fileType.join("/") }}等模型格式。<br />
      2、支持ZIP压缩包上传。<br />
      3、纹理格式支持：JPG、PNG、JIF、BMP, TGA；
    </div> -->
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import cookie from "../../../public/js/cookie";
import axios from "../../../untils/request";
import qs from "qs";
import { Dashboard } from "@uppy/vue";

import "@uppy/core/dist/style.css";
import "@uppy/dashboard/dist/style.css";

import Uppy from "@uppy/core";
import chinese from "@uppy/locales/lib/zh_CN";
import Tus from "@uppy/tus";
import store from "../../store/index";
export default {
  name: "uupy",
  //import引入的组件需要注入到对象中才能使用
  //父组件传入子组件数据
  props: ["fileType"],
  //格式化数据
  filters: {},
  watch: {
    fileType(curVal, oldVal) {
      this.all = curVal;
      //  if(curVal){
      //    this.uploadImg=curVal;
      //   }
    },
  },
  data() {
    //这里存放数据
    return {
      files: [],
      show: false,
      uuid: "",
      path: "",
      all: "",
      size:""
    };
  },
  components: {
    Dashboard,
  },
  beforeDestroy() {
    this.uppy.close();
  },
  //计算属性 类似于data概念
  computed: {
    uppy() {
      var uppy = new Uppy({
        locale: chinese,
        allowMultipleUploads:false, 
        restrictions: {
          // allowedFileTypes: ["image/*", "application/pdf",".GLTF"],
          allowedFileTypes: this.fileType,
        },
        onBeforeFileAdded: (currentFile, files) => {
          let arr = []
          arr.push(currentFile.extension)
          let af = files
          let aadd  = []
          for (let key in af) {
            aadd.push({type:af[key].extension});
          }
          // if (currentFile.name === "my-file.jpg") {
          //   return Promise.resolve();
          // }
          // return Promise.reject("This is not the file I was looking for");
        },
        onBeforeUpload: (files) => {
          console.log(files)
          let type = []
          let zipArr = []
          let pngArr = []
          let modelArr =[]
          let mtlArr = []
          let size = 0
          let data = ''
          let msg = ''
          for (let key in files) {
            type.push({type:files[key].extension});
          }
          for(let key in files) {
            size += parseInt(files[key].size)
          }
        
          console.log(size)
          type.forEach(item=>{
            if(item.type == "zip" || item.type == "rar"){
              zipArr.push(item.type)
              // return this.$message("只能上传一个模型"),Promise.resolve(),this.show = false;
            } else if( item.type == 'jpg' || item.type == 'png'){
              pngArr.push(item.type)
            } else if( item.type == 'mtl' || item.type == 'bin'){
              mtlArr.push(item.type)
            } else {
              modelArr.push(item.type)
            }
          })
          if(modelArr.length!= 0 && zipArr.length!= 0) {
            return this.$message("只能上传一种格式模型文件"),Promise.resolve(),this.show = false;
          } 
          else if(modelArr.length> 1) {
            return this.$message("只能上传一种格式模型文件"),Promise.resolve(),this.show = false;
          }else if(zipArr.length> 1) {
            return this.$message("只能上传一种格式模型文件"),Promise.resolve(),this.show = false;
          }else if(pngArr.length != 0 && zipArr.length == 0 && modelArr.length == 0) {
            return this.$message("请上传一个模型"),Promise.resolve(),this.show = false;
          }
          else if(modelArr.length> 1 && zipArr.length>  1 && pngArr > 1 && mtlArr> 1){
             return this.$message("只能上传一个压缩包或者模型"),Promise.resolve(),this.show = false;
          } else if (modelArr.length !=0 && zipArr.length!=  0 && pngArr !=  0 && mtlArr!=  0){
            return this.$message("只能上传一个压缩包"),Promise.resolve(),this.show = false;
          } else if(size > this.size){
            
            return this.$message("文件超过限制" + this.size/1024/1024 + 'MB'),Promise.resolve(),this.show = false;
          }
          // if (Object.keys(files).length < 2) {
          //   return Promise.reject("Too few files :(");
          // }
          // return Promise.resolve();
        },
      })
        // .use(Tus, { endpoint: "http://47.114.99.82:8888/group1/big/upload/" })
        
        .on("complete", (result) => {
          console.log(result)
          if (result.successful) {
            this.show = true;
          } else {
            this.show = false;
          }
        })

        .use(Tus, { endpoint: "https://meta.wiiboox.net:8888/group1/big/upload/" })
        .on("file-added", (files) => {
          console.log(111)
          // files = []
          // console.log(files.type);
          // let filesType = [];
          // filesType.push(files.type);
          // console.log(filesType);
          // filesType.forEach((item) => {
          //   if (item.indexOf == -1) {
          //     return this.$message("文件重复");
          //   }
          // });
          // console.log("file名称:" + JSON.stringify(files));
        });
      uppy.setMeta({
        callback_url: "http://127.0.0.1/callback",
        path: this.path,
      });

      return uppy;
    },
  },
  //监控data中的数据变化
  // watch: {},

  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.getPath();
    this.getSize()
  },

  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},

  //方法集合
  methods: {
    addHandler() {
      this.compCount++;
      this.$emit("emitEvt", {
        count: this.compCount,
      });
    },
    getshow() {
    },
    //生成uuid
    guid() {
      return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
        /[xy]/g,
        function (c) {
          var r = (Math.random() * 16) | 0,
            v = c == "x" ? r : (r & 0x3) | 0x8;
          return v.toString(16);
        }
      );
    },
    getPath() {
      this.uuid = this.guid(this.uuid);
      axios
        .get("/wiiboox-biz/tModelProject/getUploadUrl?uuId=" + this.uuid, {
          headers: {
            "content-type": "application/x-www-form-urlencoded",
            Authorization: "Bearer " + cookie.get("token"),
          },
        })
        .then((res) => {
          this.path = res.data;
          // this.$message(this.path)
          //  this.path =  {meta: {'callback_url': 'http://47.114.99.82:8888/callback', 'path':res.data }}
        });
    },
    sendData(uppy) {
      // for (let key in uppy.state.files) {
      //     if(uppy[key].uploadURL == undefined) {
      //         return this.show = false
      //     } else {
      //         return this.show = true
      //     }
      // arr.push({
      //   md5: uppy[key].uploadURL
      // });
      //   }
      uppy.uuid = this.uuid;
      this.$emit("userData", uppy);
      // this.$emit("userData", uppy.state.files);
    },
    getSize(){
      axios
            .get("/wiiboox-biz/tModelProject/sizeCheck", {
              headers: {
                "content-type": "application/x-www-form-urlencoded",
                Authorization: "Bearer " + cookie.get("token"),
              },
            })
            .then((res) => {
              console.log(res)
              this.size = res.data
              // data = res.data
              // msg = res.message
              // if(res.data != true){
              //   this.$message(msg)
                
              //   return false
              // } else {

              // }
        });
    }
  },

  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>

<style scoped>
::v-deep .uppy-Dashboard-inner {
  width: 550px !important;
  height: 330px !important;
  background: none;
  border: none;
}
::v-deep .uppy-Dashboard-AddFiles {
  /* border: 1px solid #dfdfdf; */
  border-color: #4a4a4a;
}
/* ::v-deep .uppy-StatusBar is-waiting{
    height: 30px;
} */
::v-deep .uppy-StatusBar:not([aria-hidden="true"]).is-waiting {
  height: 48px;
  background: none;
}
::v-deep .uppy-StatusBar.is-waiting .uppy-StatusBar-actions {
  background: none;
}
::v-deep .uppy-StatusBar.is-waiting .uppy-StatusBar-actionBtn--upload {
  background: #1a8aff;
  width: auto;
  height: auto;
  padding: 5px 10px;
}
::v-deep .uppy-StatusBar::before {
  /* position: static; */
  background: none;
  height: 0;
}
::v-deep .uppy-StatusBar-actions {
  justify-content: right;
  margin-top: 5px;
}
::v-deep .uppy-Dashboard-Item-name {
  color: #fff;
}
::v-deep .uppy-Dashboard-Item{
  border-bottom:1px solid #666666;
  
}
::v-deep .uppy-DashboardContent-bar {
  color: #fff;
  background-color: #333333 !important;
   border-bottom:1px solid #666666
}
/* ::v-deep .uppy-DashboardContent-bar{
     color: #fff;
    background-color: none;
} */
::v-deep .uppy-Dashboard-AddFiles-title {
  color: #fff;
}
::v-deep .uppy-StatusBar {
  background-color: #333333 !important;
  color: #fff;
}
::v-deep .uppy-StatusBar-status {
  color: #fff;
}
::v-deep .uppy-StatusBar-actionBtn--done {
  display: none;
}
.up_file {
  background: #333;
  border: 1px solid #4a4a4a;
  color: #fff;
  position: absolute;
  right: 15px;
  bottom: 114px;
  z-index: 9999;
  cursor: pointer;
}
button {
  width: 90px;
  height: 23px;
  margin: 0 auto;
  border: 0;
  border-radius: 3px;
  cursor: pointer;
}
::v-deep .uppy-Dashboard-AddFiles-title{
  color: #999999;
}
::v-deep .uppy-StatusBar:not([aria-hidden=true]).is-waiting{
  border-top: 1px solid #666666;
}
</style>