<template>
<div style="padding:20px">
          <div class="list">
            <div class="list_head">
              <span class="sp1">用户：{{commentData.createBy}}</span
              ><span class="sp2">{{commentData.createTime}}</span>
              <p>对我的模型<b class="model" @click="model(commentData)">《{{commentData.objName}}》</b>发表了评论</p>
              <img src="../../assets/test.png" alt="" class="head_img" />
            </div>
            <div class="list_content">
              <p>
               {{commentData.commentContent}}
              </p>
            </div>
            <div class="list_text" >
              <div class="icon" v-if="num != 1">
                <span class="icon_1"
                  ><img src="../../assets/reply.png" alt="" />回复</span
                >
                <span class="icon_2"
                  ><img src="../../assets/jubao.png" alt="" />举报</span
                >
              </div>
              <el-input
                v-if="num != 1"
                type="textarea"
                :rows="3"
                placeholder="请输入内容"
                v-model="textareaList[commentData]"
              >
              </el-input>
              
              <div class="btn" v-if="num != 1">
                <button class="gray" @click="cancel" >取消</button>
                <button class="blue" @click="postReply(commentData)" v-preventReClick>确认</button>
              </div>
              <parentCommit1 :parentData="commentData" v-if="commentData && num != 1"  @fatherMethod="fatherMethod"/>
              <parentCommit2 :parentData="commentData" v-if="commentData && num == 1"  @fatherMethod="fatherMethod"/>
            </div>
          </div>
  <!-- <div class="msg">
    <img src="../../assets/test.png" alt="" class="portrait" />
    <div class="user_name">{{ commentData.createBy }}</div>
    <div class="time">{{ commentData.createTime }}</div>
    <p>{{ commentData.commentContent }}</p>
        <p class="msgBox">
      <span @click="msgBox(commentData)">回复</span><span>举报</span>
    </p>
        <div v-if="show == commentData">
      <el-input
        type="textarea"
        rows="2"
        placeholder="我来说两句"
        v-model="textareaList[commentData]"
      >
      </el-input>
      <div class="reply">
        <button class="gray" @click="postReply(commentData)">发送</button>
      </div>
    </div>
    <div>
        <parentCommit1 :parentData="commentData" v-if="commentData"  @fatherMethod="fatherMethod"/>
    </div>
    
  </div> -->
  
  </div>
  
</template>
 

<script>
import parentCommit1 from "../template/parent1.vue";
import parentCommit2 from "../template/parent2.vue";
import axios from "../../../untils/request.js";
import qs from "qs";
import cookie from "../../../public/js/cookie.js";

export default {
  props: ["commentData","num"],
  components: {
    parentCommit1,
    parentCommit2,
  },
  // inject:{reload},
  data(){
    return{
        show:'',
        textarea:'',
        textareaList:[]
    }
  },  
  methods:{
    cancel(){
      this.$router.go(-1)
    },
    model(commentData){
       this.$router.push({ path: '/model/modelDetail', query: { id: commentData.objId } });
    },
    msgBox(commentData) {
      this.show = commentData;
    },
    fatherMethod(){
        this.$parent.getAll();
    },
    postReply(commentData) {
      let data = {
        action: 2,
        comment: this.textareaList[commentData],
        objId: commentData.objId,
        parentId: commentData.id,
        type: 2,
      };
      axios
        .post("/wiiboox-biz/tUserReplay/replay", data, {
          headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + cookie.get("token"),
          },
        })
        .then((res) => {
            this.$parent.getlist();
        });
    },
    // postMsg() {
    //   console.log(1)
    //   let data = {
    //     action: 1,
    //     comment: this.textarea,
    //     objId: commentData.id,
    //     type: 1,
    //   };
    //   axios
    //     .post("/wiiboox-biz/tUserReplay/replay", data, {
    //       headers: {
    //         "Content-type": "application/json",
    //         Authorization: "Bearer " + cookie.get("token"),
    //       },
    //     })
    //     .then((res) => {
    //       console.log(res);
    //       this.getList();
    //       this.textarea = ''
    //     });
    // },
  }
};
</script>
 
<style scoped>
/* .box {
  border: 1px solid #e4e4e4;
  background-color: #eee;
  margin: 10px;
  padding: 10px;
} */
.gray {
  background: #333;
  border: 1px solid #4a4a4a;
  color: #fff;
  padding: 2px 15px;
  margin-left: 50px;
  border-radius: 5px;
  cursor: pointer;
}
.reply {
  display: flex;
  justify-content: right;
}
.msg {
  width: 100%;
  padding: 15px 0;
  border-bottom: 1px solid #262626;
}

.msg .portrait {
  width: 30px;
  height: 30px;
}
.portrait {
  width: 65px;
  height: 65px;
  border-radius: 50%;
  float: left;
}
.msg .user_name {
  font-size: 12px;
  padding-left: 10px;
}
.user_name {
  display: flex;
  color: #fff;
  padding-left: 15px;
  padding-top: 5px;
  text-align: left;
}
.msg img {
  position: relative;
  top: 8px;
}
.time {
  color: #606060;
  font-size: 12px;
  display: flex;
  margin-top: 1px;
  text-align: left;
  padding-left: 10px;
}
.msg p {
  font-size: 14px;
  color: #999999;
  padding-left: 40px;
  margin-top: 5px;
}
.msgBox {
  display: flex;
  justify-content: right;
  font-style:normal
}
.msgBox span {
  margin-left: 10px;
  color: #666666;
  font-size: 13px;
  cursor: pointer;
}
.reply button {
  margin-top: 13px;
}
.list {
  background: #2a2a2a;
  position: relative;
  padding: 30px;
  margin-top: 20px;
}
.list .head_img {
  width: 57px;
  height: 57px;
  position: absolute;
  right: 50px;
  top: 26px;
}
.sp1 {
  color: #fff;
}
.sp2 {
  color: #666666;
  margin-left: 12px;
  font-size: 14px;
}
.list p {
  color: #666666;
  padding-top: 7px;
}
.list_head {
  border-bottom: 1px solid #333333;
  padding-bottom: 15px;
}
.list_content {
  font-size: 14px;
  border-bottom: 1px solid #333333;
  padding-bottom: 15px;
}
.list_content p {
  color: #999999;
}
.list_text .icon {
  padding: 10px;
  color: #666666;
  font-size: 14px;
}
.list_text .icon img {
  position: relative;
  top: 3px;
  right: 4px;
}
.list_text .icon .icon_2 {
  margin-left: 30px;
}
.btn {
  display: flex;
  margin-top: 20px;
  justify-content: right;
}
.blue {
  background: #1a8aff;
  color: #fff;
  padding: 2px 15px;
  border: none;
  border-radius: 5px;
  margin-left: 10px;
}
::v-deep .el-textarea__inner {
  background: #2a2a2a;
  border: 1px solid #6666;
  margin-top: 10px;
}
.gray {
  background: #333;
  border: 1px solid #4a4a4a;
  color: #fff;
  padding: 2px 15px;

  border-radius: 5px;
}
.model{
  cursor: pointer;
  font-weight: normal;
  color: #1a8aff;
}
</style>
