import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    demoValue:{}
  },
  getters: {
    //get方法
    getDemoValue: state => state.demoValue
  },
  mutations: {
    setDemoValue(state,demoValue){
      state.demoValue = demoValue
   }
  },
  actions: {
  },
  
  modules: {
  }
})
