<!--  -->
<template>
  <div class="user">
    <div class="header">
      <span>订单管理</span>
    </div>
    <div class="table">
      <el-table
        ref="multipleTable"
        :data="tableData"
        row-key="id"
        tooltip-effect="dark"
        stripe
        @selection-change="handleSelectionChange"
        class="customer-table"
      >
        <el-table-column type="selection" width="55"> </el-table-column>
        <!-- <el-table-column width="70" label="序号" prop="parentIndex">
            <template slot-scope="scope">
              <span v-if="scope.row.isIndex">{{ scope.row.parentIndex }}</span>
            </template>
          </el-table-column> -->
        <el-table-column prop="orderNo" label="订单号" width="240">
        </el-table-column>
        <el-table-column prop="payAmount" label="支付金额" width="120">
          <template slot-scope="scope">
            <span>{{ scope.row.payAmount }}元</span>
          </template>
        </el-table-column>
        <el-table-column prop="payNumber" label="订单流水号" width="180">
        </el-table-column>
        <el-table-column prop="platform" label="平台" width="80">
          <template slot-scope="scope">
            <span v-if="scope.row.platform == 1">微信</span>
            <span v-if="scope.row.platform == 2">支付宝</span>
            <span v-if="scope.row.platform == 3">对公转账</span>
          </template>
        </el-table-column>

        <el-table-column prop="productName" label="产品名称" width="180">
        </el-table-column>
        <el-table-column prop="status" label="支付状态" width="120">
          <template slot-scope="scope">
            <span v-if="scope.row.status == 0">已取消</span>
            <span v-if="scope.row.status == 1">未付款</span>
            <span v-if="scope.row.status == 2">已付款</span>
            <span v-if="scope.row.status == 3">交易成功</span>
          </template>
        </el-table-column>
        <el-table-column prop="invoiceStatus" label="申请状态" width="80">
          <template slot-scope="scope">
            <span v-if="scope.row.invoiceStatus == 0">未申请</span>
            <span v-if="scope.row.invoiceStatus == 1">已申请</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="120">
          <template slot-scope="scope">
            <el-button
              size="mini"
              :disabled = "scope.row.invoiceStatus == 0 && (scope.row.status == 2 || scope.row.status == 3)"
              @click="handleEdit(scope.$index, scope.row)"
              >申请发票</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
    </div>
    <div class="paginationClass">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="page_current"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="100"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
    <el-dialog title="申请发票" :visible.sync="dialogTableVisible">
      <div class="box">
      <div class="ipt"> <span>金额:</span
      ><span style="width:70%;text-align: left;">{{count}}</span></div>
      <div class="ipt"><span>发票抬头:</span>
        <div>
          <el-select v-model="value" placeholder="请选择" @change="changeList(value)">
        <el-option
          v-for="item in options"
          :key="item.name"
          :label="item.name"
          :value="item"
        >
        </el-option>
      </el-select>
        </div>

    </div>
      <div class="ipt"><span>发票类型</span
      > 
      <div style="height: 30px; line-height: 30px">
      <el-radio v-model="type" label="0">个人</el-radio>
         <el-radio v-model="type" label="1">企业</el-radio>
         <el-radio v-model="type" label="2">事业单位</el-radio>
        </div>
        </div>
         <div class="ipt"><span>统一社会信用代码</span
      ><el-input v-model="code" placeholder="请输入内容"></el-input></div>
      <div class="ipt"><span>电话</span
      ><el-input v-model="phone" placeholder="请输入内容"></el-input></div>
      <div class="ipt"><span>地址</span
      ><el-input v-model="address" placeholder="请输入内容"></el-input></div>
      <div class="ipt"><span>开户银行账号</span
      ><el-input v-model="bankAccount" placeholder="请输入内容"></el-input></div>
      <div class="ipt"><span>开户银行名称</span
      ><el-input v-model="bankName" placeholder="请输入内容"></el-input></div>
      <button @click="userBtn()" class="btn1">确定</button>
    </div>
    </el-dialog>
  </div>
</template>
  
  <script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import cookie from "../../../../public/js/cookie";
import axios from "../../../../untils/request";
import qs from "qs";
export default {
  name: "",

  //import引入的组件需要注入到对象中才能使用
  components: {},

  //父组件传入子组件数据
  props: {},

  //格式化数据
  filters: {},

  data() {
    //这里存放数据
    return {
      //表格数据
      tableData: [],
      userList: [],
      checkList: [],
      dialogTableVisible: false,
      userId: "",
      total: 1,
      page_current: 1,
      page_size: 10,
      address: "",
      bankAccount: "",
      bankName: "",
      code: "",
      orderIds: [],
      phone: "",
      title: "",
      type: "",
      options: "",
      allList:'',
      value:'',
      count:'',
    };
  },

  //计算属性 类似于data概念
  computed: {},

  //监控data中的数据变化
  watch: {},

  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.getRole();
    this.getList();
  },

  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},

  //方法集合
  methods: {
    handleSelectionChange(val) {
      this.count = 0
      this.orderIds = []
      console.log(val)
      val.forEach(item=>{
        this.count += Number(item.payAmount)
        this.orderIds.push(item.id)
      })
      console.log(this.count,this.orderIds)
    },
    changeList(value,i){
      console.log(value)
      this.address = value.address
      this.bankAccount = value.bankAccount
      this.bankName = value.bankName
      this.code = value.code
      this.phone = value.phone
      this.title = value.title
      this.name = value.name
      this.type = value.type.toString()
      // if( value.type = 0) {
      //   this.type = '个人'
      // } else if( value.type = 1) {
      //   this.type = '企业'
      // } else {
      //   this.type = '事业单位'
      // }
    },
    handleEdit(i, row) {
      console.log(row)
      this.dialogTableVisible = true;
      if(this.count == '') {
        this.orderIds.push(row.id);
        this.count = row.payAmount
      }
     
      // let data = {
      //   address:this.address,
      //   bankAccount:this.bankAccount,
      //   bankName:this.bankName,
      //   code:this.code,
      //   orderIds:this.orderIds,
      //   phone:this.phone,
      //   title:this.title,
      //   type:0
      // }
      axios
        .get("/wiiboox-biz/tUserInvoiceTitle/getCurrentAll", {
          headers: {
            "content-type": "application/x-www-form-urlencoded",
            Authorization: "Bearer " + cookie.get("token"),
          },
        })
        .then((res) => {
          if (res.code == 200) {
            this.options = res.data;
            this.allList = res.data
          }
        });
    },
    btn() {
      let data = {
        address: this.address,
        bankAccount: this.bankAccount,
        bankName: this.bankName,
        code: this.code,
        orderIds: this.orderIds,
        phone: this.phone,
        title: this.title,
        type: this.type,
      };
      axios
        .post("/wiiboox-biz/tUserPayOrder/invoiceApply", data, {
          headers: {
            "content-type": "application/json",
            Authorization: "Bearer " + cookie.get("token"),
          },
        })
        .then((res) => {
          this.$message(res.message);
        });
    },
    getRole() {
      axios
        .get("/wiiboox-admin/sysRole/getAll", {
          headers: {
            "content-type": "application/x-www-form-urlencoded",
            Authorization: "Bearer " + cookie.get("token"),
          },
        })
        .then((res) => {
          console.log(res);
        });
    },
    getList() {
      axios
        .get("/wiiboox-biz/tUserPayOrder/page", {
          headers: {
            "content-type": "application/x-www-form-urlencoded",
            Authorization: "Bearer " + cookie.get("token"),
          },
        })
        .then((res) => {
          console.log(res.data.records);
          this.tableData = res.data.records;
          this.page_current = res.data.current;
          this.total = res.data.total;
          this.page_size = res.data.size;
        });
    },
    handRole(i, id) {
      this.checkList = [];
      console.log(this.checkList);
      this.dialogTableVisible = true;
      console.log(id);
      this.userId = id.id;
      axios
        .get("/wiiboox-admin/sysUser/sysRole/" + id.id, {
          headers: {
            "content-type": "application/x-www-form-urlencoded",
            Authorization: "Bearer " + cookie.get("token"),
          },
        })
        .then((res) => {
          console.log(res.data);
          res.data.forEach((item) => {
            this.checkList.push(item.id);
          });
          this.getuserlist();
        });
    },
    getuserlist() {
      this.userList = [];
      axios
        .get("/wiiboox-admin/sysRole/page/", {
          headers: {
            "content-type": "application/x-www-form-urlencoded",
            Authorization: "Bearer " + cookie.get("token"),
          },
        })
        .then((res) => {
          console.log(res.data.records);
          //   this.userList = res.data.records;
          res.data.records.forEach((item) => {
            this.userList.push({
              name: item.name,
              id: item.id,
            });
          });
        });
    },
    userBtn() {
      let data = {
        "address": this.address,
        "bankAccount": this.bankAccount,
        "bankName": this.bankName,
        "code": this.code,
        "invoiceAmount": this.count,
        "name": this.name,
        "orderIds": this.orderIds,
        "phone":this.phone,
        "type": this.type
      };
      console.log(data)
      axios({
        method: "post",
        url: "/wiiboox-biz/tUserPayOrder/invoiceApply",
        headers: {
          "content-type": "application/json",
          Authorization: "Bearer " + cookie.get("token"),
        },
        data: data,
      })
        .then((res) => {
         if(res.code == 200){
          this.dialogTableVisible = false
          this.getList()
         } else {
          this.$message(res.msg)
         }
        })
        .then((err) => {
          console.log("err");
        });
    },
    handleDelete(i, id) {
      let data = {
        ids: id.id,
      };
      axios({
        method: "delete",
        url: "/wiiboox-admin/sysUser/delByIds/",
        headers: {
          "content-type": "application/x-www-form-urlencoded",
          Authorization: "Bearer " + cookie.get("token"),
        },
        data: qs.stringify(data),
      })
        .then((res) => {
          console.log("成功");
          console.log(res);
        })
        .then((err) => {
          console.log("err");
        });
    },
    handleSizeChange(val) {
      axios
        .get(
          "/wiiboox-biz/tUserPayOrder/page?size=" +
            val +
            "&current=" +
            this.page_current,
          {
            headers: {
              "content-type": "application/x-www-form-urlencoded",
              Authorization: "Bearer " + cookie.get("token"),
            },
          }
        )
        .then((res) => {
          console.log(res.data.records);
          this.tableData = res.data.records;
        });
    },
    handleCurrentChange(val) {
      console.log(val);
      axios
        .get(
          "/wiiboox-biz/tUserPayOrder/page?current=" +
            val +
            "&size=" +
            this.page_size,
          {
            headers: {
              "content-type": "application/x-www-form-urlencoded",
              Authorization: "Bearer " + cookie.get("token"),
            },
          }
        )
        .then((res) => {
          console.log(res.data.records);
          this.tableData = res.data.records;
        });
    },
  },
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
  
  <style scoped>
.el-dialog {
  width: 30%;
}
.deletes {
  background: #f56c6c;
  padding: 5px 30px;
  color: #fff;
  border: 0;
  margin-bottom: 5px;
}
.btn {
  margin: 0 auto;
}
.el-table button {
  width: auto;
  padding: 3px 20px;
  border-radius: 25px;
  background: #333333;
  border: 1px solid #999999;
  color: #999999;
}
.el-table {
  background: #333333 !important;
  padding-left: 15px;
}
.el-table >>> tr {
  background: none !important;
  color: #999999 !important;
  border: none;
}
.el-table >>> th.el-table__cell {
  background: none !important;
  color: #ffffff !important;
}
>>> .el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background-color: #333333 !important;
  border-color: #999999 !important;
}
>>> .el-table--striped
  .el-table__body
  tr.el-table__row--striped
  td.el-table__cell {
  background: #2b2b2b !important;
  /* color: #ffffff !important; */
}
/* // 去掉表格单元格边框 */
.customer-table th {
  border: none;
}

/deep/ .el-table--scrollable-x .el-table__body-wrapper {
    overflow: auto;
}

/deep/ .gutter {
  width: 22px !important;
  display: inline-block !important;
}
.customer-table td,
.customer-table th.is-leaf {
  border: none;
}
/* // 表格最外边框 */
.el-table--border,
.el-table--group {
  border: none;
}
/* // 头部边框 */
.customer-table thead tr th.is-leaf {
  border: 1px solid #ebeef5;
  border-right: none;
}
.customer-table thead tr th:nth-last-of-type(2) {
  border-right: 1px solid #ebeef5;
}
/* // 表格最外层边框-底部边框 */
.el-table--border::after,
.el-table--group::after {
  width: 0;
}
.customer-table::before {
  width: 0;
}
.customer-table .el-table__fixed-right::before,
.el-table__fixed::before {
  width: 0;
}
/* // 表格有滚动时表格头边框 */
.el-table--border th.gutter:last-of-type {
  border: 1px solid #ebeef5;
  border-left: none;
}
>>> .el-table td.el-table__cell,
.el-table th.el-table__cell.is-leaf {
  border: 0 !important;
}
.el-table td.el-table__cell,
::v-deep .el-table th.el-table__cell.is-leaf {
  border: 0 !important;
}
::v-deep .el-table--enable-row-hover .el-table__body tr:hover > td {
  background-color: #383838 !important;
}
::v-deep .el-checkbox__inner:hover {
  border-color: #fff !important;
}
::v-deep .el-checkbox__input.is-focus .el-checkbox__inner {
  border-color: #fff !important;
}
.myCell .el-checkbox__input {
  display: none !important;
}
.paginationClass {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0);
  height: 40px;
  /* width: 100%; */
  text-align: center;
  /* padding-left: 15%; */
}
::v-deep .el-pager li {
  margin-left: 15px;
  background: #2b2b2b;
  color: #999999;
}
::v-deep .el-pagination .btn-next {
  margin-left: 15px;
}
::v-deep .el-pagination button:disabled {
  background: #2b2b2b !important;
  color: #999999;
}
::v-deep .el-pagination .btn-next {
  background: #2b2b2b !important;
  color: #999999;
}
::v-deep .el-pager li.btn-quicknext,
.el-pager li.btn-quickprev {
  background: #2b2b2b !important;
  color: #999999;
}
::v-deep .el-pagination .btn-prev {
  background: #2b2b2b !important;
  color: #999999;
}
::v-deep .el-pagination__editor.el-input .el-input__inner {
  background: #2b2b2b !important;
  color: #999999;
  border: 0;
}
::v-deep.el-pagination .el-select .el-input .el-input__inner {
  background: #2b2b2b !important;
  color: #999999;
  border: 0;
}
::v-deep .el-table--scrollable-x .el-table__body-wrapper {
  overflow: hidden;
}
::v-deep .el-table__empty-block {
  width: 100% !important;
}
.el-table {
  margin-bottom: 50px;
}
::v-deep .el-dialog__title {
  color: #fff;
}
::v-deep .el-dialog {
  background: #333333;
  width: 689px;
}
.el-input {
  width: 70%;
}
.title {
  display: block;
  /* float: left; */
}
.ipt span {
  display: block;
  float: left;
  width: 112px;
  color: #cccccc;
  font-size: 14px;
  height: 30px;
  line-height: 30px;
  padding-right: 20px;
  text-align: right;
}
.ipt {
  margin-top: 10px;
}
::v-deep .el-input__inner {
  height: 30px;
  border: 0;
  background: #2c2c2c;
}
.el-input {
}
.box {
  padding: 10px 50px;
  margin: 0 auto;
}
.btn1 {
  display: block;
  height: 30px;
  line-height: 30px;
  background: #1a8aff;
  border: 0;
  color: #fff;
  width: 80px;
  border-radius: 5px;
  margin: 30px auto;
}
@media only screen and (max-width: 767px) {
  ::v-deep .el-dialog {
  background: #333333;
  width: 100%;
}
.ipt span{
  width: auto;
}
.box{
  padding: 0;
}
.el-input {
  width: 56%;
}
.el-table .el-table__header-wrapper th{
   
    background-color: #f0f1ff;
  }
.el-table__body-wrapper::-webkit-scrollbar {
    width: 8px; /*滚动条宽度*/
    height: 8px; /*滚动条高度*/
  }
 .el-table__body-wrapper::-webkit-scrollbar-track {
    border-radius: 10px; /*滚动条的背景区域的圆角*/
    -webkit-box-shadow: inset 0 0 6px rgba(238,238,238, 0.3);
    background-color: #eeeeee; /*滚动条的背景颜色*/
  }
  .el-table__body-wrapper::-webkit-scrollbar-thumb {
    border-radius: 10px; /*滚动条的圆角*/
    -webkit-box-shadow: inset 0 0 6px rgba(145, 143, 0143, 0.3);
    background-color: rgb(145, 143, 143); /*滚动条的背景颜色*/
  }
  
  ::v-deep.el-table--scrollable-x .el-table__body-wrapper{
    overflow: auto !important;
  }
  .paginationClass{
    width: 100%;
  }
  ::v-deep .el-pager li{
    margin-left: 0;
  }
  ::v-deep .el-pagination .btn-next {
    margin-left: 0;
  }
  ::v-deep .el-pagination__jump{
    margin-left: 5px;
  }
}
</style>