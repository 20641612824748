<!--  -->s=
<template>
  <div class="myModel">
    <div class="header">
      <span>分类</span>
      <ul class="typeUl">
        <li
          v-for="(item, i) in listtype"
          :key="i"
          @click="typeClick(item, i)"
          :class="{ active: currentIndex === i }"
        >
          {{ item.comment }}
        </li>
      </ul>
    </div>
    <div>
      <div class="content">
        <el-tabs type="border-card" @tab-click="tabcLick">
          <el-tab-pane label="全部">
            <!-- <div slot="label">全部</div> -->
          </el-tab-pane>
          <el-tab-pane>
            <div slot="label">精品模型</div>
          </el-tab-pane>
          <el-tab-pane>
            <div slot="label">最新发布</div>
          </el-tab-pane>
          <el-tab-pane>
            <div slot="label">人气最高</div>
          </el-tab-pane>
        </el-tabs>
        <div class="search_box">
            <img src="../assets/search.png" alt="" class="serch">
          <el-input v-model="search" placeholder="请输入模型名称" class="ipt1" @keyup.enter.native="searchName()" @blur="searchName()"></el-input>
        </div>
      </div>
      <div class="table">
        
        <div class="list" v-for="(item, index) in list" :key="index" @click="detail(item)">
          <!-- <img src="../assets/test.png" alt="" /> -->
          <div style="width:100%;height:82%;overflow: hidden;" class="box">
          <img
            :src="
              item.thumbnailUrl != null
                ? item.thumbnailUrl
                : '../assets/noufound.png'
            "
            alt=""
            :onerror="defal"
          />
        </div>
          <div class="footer">
            <span class="sp1">
              <img
                :src="
                  item.iconUrl != null
                    ? item.iconUrl
                    : '../assets/noufound.png'
                "
                alt=""
                :onerror="defal1"
                class="userImg"
              /><span>{{ item.name }}</span></span
            >

            <span class="sp2">
              <img src="@/assets/browseNum.png" alt="" class="browseNum" /><span>{{
                item.browseNum
              }}</span></span
            >
          </div>
        </div>
      </div>
      <div class="paginationClass">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="page_current"
          :page-sizes="[20, 40, 60, 80]"
          :page-size="100"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import axios from "../../untils/request.js";
import qs from "qs";
import cookie from "../../public/js/cookie";
export default {
  name: "",
  //import引入的组件需要注入到对象中才能使用
  components: {},

  //父组件传入子组件数据
  props: {},

  //格式化数据
  filters: {},

  data() {
    //这里存放数据
    return {
      params: "",
      list: [],
      size: 20,
      total: 0,
      page_current: 0,
      value: "",
      url: "",
      username: "",
      time: "",
      type: -1,
      listtype: [],
      currentIndex: 0,
      modelSceneId: "",
      search: "",
      defal: 'this.src="' + require("../assets/notfound.jpg") + '"',
      defal1: 'this.src="' + require("../assets/userimg.png") + '"',
    };
  },

  //计算属性 类似于data概念
  computed: {},

  //监控data中的数据变化
  watch: {},

  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.getUser();
    this.getList();
    this.getInfo();
    this.gettype();
  },

  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},

  //方法集合
  methods: {
    searchName(){
        let headers = {};
      if (cookie.get("token") == undefined) {
        headers = { "Content-type": "application/x-www-form-urlencoded" };
      } else {
        headers = {
          "Content-type": "application/x-www-form-urlencoded",
          Authorization: "Bearer " + cookie.get("token"),
        };
      }
             axios
        .get(
          "/wiiboox-biz/tModelProject/dashboard?size=" +
            this.size + 
            (this.modelSceneId ? "&modelSceneId=" + this.modelSceneId : "") + '&name=' + this.search,
          {
            headers: headers,
          }
        )
        .then((res) => {
          this.list = res.data.records;
          this.total = res.data.total;
          this.page_current = res.data.current;
        });
    },
    all() {
      this.modelSceneId = "";
      this.getList();
    },
    typeClick(item, i) {
      this.currentIndex = i;
      this.modelSceneId = item.id;
      this.getList();
    },
    gettype() {
      let headers = {};
      if (cookie.get("token") == undefined) {
        headers = { "Content-type": "application/x-www-form-urlencoded" };
      } else {
        headers = {
          "Content-type": "application/x-www-form-urlencoded",
          Authorization: "Bearer " + cookie.get("token"),
        };
      }
      axios
        .get("/wiiboox-biz/tModelScene/getAll", {
          headers: headers,
        })
        .then((res) => {
          this.listtype = res.data;
          this.listtype.unshift({ comment: "全部" });
        });
    },
    btn() {
      this.$router.push({ name: "subscribe" });
    },
    online() {
      this.getLists("topQualityStatus", 1);
    },
    offline() {
      this.getLists("orderByPublishTime", true);
    },
    all() {
      this.getList;
    },
    edit() {
      this.$router.push({ name: "personal" });
    },
    tabcLick(el) {
      if (el.index == 0) {
        this.getList();
      } else if (el.index == 1) {
        this.online();
      } else if (el.index == 2) {
        this.offline();
      } else {
        this.getLists("orderByBrowseNum", true);
      }
    },
    getList() {
      let headers = {};
      if (cookie.get("token") == undefined) {
        headers = { "Content-type": "application/x-www-form-urlencoded" };
      } else {
        headers = {
          "Content-type": "application/x-www-form-urlencoded",
          Authorization: "Bearer " + cookie.get("token"),
        };
      }
      axios
        .get(
          "/wiiboox-biz/tModelProject/dashboard?size=" +
            this.size + 
            (this.modelSceneId ? "&modelSceneId=" + this.modelSceneId : ""),
          {
            headers: headers,
          }
        )
        .then((res) => {
          this.list = res.data.records;
          this.total = res.data.total;
          this.page_current = res.data.current;
        });
    },
    getLists(modelType, i) {
      let headers = {};
      if (cookie.get("token") == undefined) {
        headers = { "Content-type": "application/x-www-form-urlencoded" };
      } else {
        headers = {
          "Content-type": "application/x-www-form-urlencoded",
          Authorization: "Bearer " + cookie.get("token"),
        };
      }
      axios
        .get(
          "/wiiboox-biz/tModelProject/dashboard?" +
            modelType +
            "=" +
            i + 
            "&size=" +
            this.size +
            (this.modelSceneId ? "&modelSceneId=" + this.modelSceneId : ""),
          {
            headers: headers,
          }
        )
        .then((res) => {
          this.list = res.data.records;
          this.total = res.data.total;
          this.page_current = res.data.current;
        });
    },
    handleSizeChange(val) {
      let headers = {};
      if (cookie.get("token") == undefined) {
        headers = { "Content-type": "application/x-www-form-urlencoded" };
      } else {
        headers = {
          "Content-type": "application/x-www-form-urlencoded",
          Authorization: "Bearer " + cookie.get("token"),
        };
      }
      this.size = val;
      axios
        .get(
          "/wiiboox-biz/tModelProject/dashboard?size=" +
            this.size +
            "&current=" +
            this.page_current,
          {
            headers: headers,
          }
        )
        .then((res) => {
          this.list = res.data.records;
        });
    },
    handleCurrentChange(val) {
      let headers = {};
      if (cookie.get("token") == undefined) {
        headers = { "Content-type": "application/x-www-form-urlencoded" };
      } else {
        headers = {
          "Content-type": "application/x-www-form-urlencoded",
          Authorization: "Bearer " + cookie.get("token"),
        };
      }
      this.page_current = val;
      axios
        .get(
          "/wiiboox-biz/tModelProject/dashboard?current=" +
            val +
            "&size=" +
            this.size,
          {
            headers: headers,
          }
        )
        .then((res) => {
          this.list = res.data.records;
        });
    },
    detail(item) {
        // this.$router.push({
        //   path: "/model/modelDetail",
        //   query: { id: item.id},
        // })
        let routeData = this.$router.resolve({
          name: "modelDetail",
          query: { id: item.id},
        });
        window.open(routeData.href, '_blank');
    },
    getUser() {
      let headers = {};
      if (cookie.get("token") == undefined) {
        headers = { "Content-type": "application/x-www-form-urlencoded" };
      } else {
        headers = {
          "Content-type": "application/x-www-form-urlencoded",
          Authorization: "Bearer " + cookie.get("token"),
        };
      }
      axios
        .get("/wiiboox-admin/sysUser/info", {
          headers: headers,
        })
        .then((res) => {
          this.username = res.data.username;
          this.url = res.data.icon;
          if (res.data.icon == null) {
            this.url = require("../assets/personal.png");
          }
        })
        .catch(() => {
          this.url = require("../assets/personal.png");
        });
    },
    getInfo() {
      let headers = {};
      if (cookie.get("token") == undefined) {
        headers = { "Content-type": "application/x-www-form-urlencoded" };
      } else {
        headers = {
          "Content-type": "application/x-www-form-urlencoded",
          Authorization: "Bearer " + cookie.get("token"),
        };
      }
      axios
        .get("/wiiboox-biz/tUserVersion/info", {
          headers: headers,
        })
        .then((res) => {
          this.time = /\d{4}-\d{1,2}-\d{1,2}/g.exec(res.data.expireTime);
          this.type = res.data.type;
        });
    },
  },

  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>

<style scoped>
.header {
  color: #fff;
  font-size: 18px;
  padding: 20px 30px;
  display: flex;
}
.header .img1 {
  width: 66px;
  height: 66px;
  border-radius: 100%;
}
.text {
  width: 346px;
  display: flex;
  flex-wrap: wrap;
  margin-left: 10px;
}
.text span {
  height: 30px;
  line-height: 30px;
}
.short {
  width: 23%;
  font-size: 16px;
  color: #999999;
  cursor: pointer;
}
.long {
  width: 65%;
  font-size: 14px;
}
.name {
  font-size: 19px;
  color: #fff;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 35%;
  min-width: 13%;
}
.time {
  color: #cccccc;
}
.small_header {
  color: #fff;
  font-size: 14px;
  padding: 4px 30px;
  float: left;
}
.list {
  background: #2e2e2e;
  margin: 10px;
  height: 236px;
  cursor: pointer;
}
.list img {
  height: 100%;
  overflow: hidden;
  display: block;
  margin: 0 auto;
  /* width: 100%; */
  /* position: absolute; */
  /* width: 192px;
  height: 192px; */
}
.list p {
  width: 100%;
  /* background: #2e2e2e; */
}
.table {
  width: 100%;
  display: flex;
  padding: 5px;
  flex-wrap: wrap;
  margin-bottom: 50px;
}
.table .list {
  width: 23%;
}
.sp1 {
  padding-left: 10px;
  color: #999999;
  font-size: 14px;
  line-height: 40px;
}
.sp2 {
  padding-right: 10px;
  color: #999999;
  font-size: 14px;
  line-height: 40px;
  /* padding: 10px 10px; */
}
/* 下拉样式 */
.el-select ::v-deep .el-popper .popper__arrow {
  display: none !important;
}
::v-deep .el-select-dropdown {
  background-color: #2c2c2c;
  /* border: none; */
  border-color: #999999;
}
.el-select-dropdown__item {
  font-size: 13px;
  color: #999999;
  font-weight: 500;
}
::v-deep.el-select-dropdown__list {
  padding: 0;
}
.search_box{
    position: relative;
    float: right;
    margin-top: -45px;
}
.serch{
    position: absolute;
    top: 3px;
}
.ipt1 ::v-deep .el-input__inner{
    width: 161px !important;
    margin-left: 30px;
    height: 28px;
    margin-right: 25px;
    border: 1px solid #909399;
    /* background: #656565; */
}
::v-deep .el-input__inner {
   background: #2c2c2c;
  height: 30px;
  border: 0;
  width: 98px;
}
::v-deep.el-popper[x-placement^="bottom"] {
  margin-top: 4px;
}
.select_box {
  width: 179px;
  display: block;
  float: left;
}
.select_box span {
  font-size: 14px;
  color: #999999;
  padding-right: 10px;
}
/***分页样式 */
.paginationClass {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0);
  height: 40px;
  width: 100%;
  text-align: center;
  margin-top: 50px;

  /* padding-left: 15%; */
}
::v-deep .el-pager li {
  margin-left: 15px;
  background: #2b2b2b;
  color: #999999;
}
::v-deep .el-pagination .btn-next {
  margin-left: 15px;
}
::v-deep .el-pagination button:disabled {
  background: #2b2b2b !important;
  color: #999999;
}
.btn {
  padding: 2px 5px;
  /* background: #1a8aff; */
  line-height: 30px;
  background: none;
  border: 0;
  color: #1a8aff;
  border-radius: 3px;
  font-size: 12px;
  text-decoration: underline;
}
::v-deep .el-pagination .btn-next {
  background: #2b2b2b !important;
  color: #999999;
}
::v-deep .el-pager li.btn-quicknext,
.el-pager li.btn-quickprev {
  background: #2b2b2b !important;
  color: #999999;
}
::v-deep .el-pagination .btn-prev {
  background: #2b2b2b !important;
  color: #999999;
}
::v-deep .el-pagination__editor.el-input .el-input__inner {
  background: #2b2b2b !important;
  color: #999999;
  border: 0;
}
::v-deep.el-pagination .el-select .el-input .el-input__inner {
  background: #2b2b2b !important;
  color: #999999;
  border: 0;
}
::v-deep .el-input__icon {
  line-height: 30px;
}
::v-deep .el-input__inner {
  color: #fff;
}
::v-deep .el-tabs--border-card > .el-tabs__header {
  background: rgb(51, 51, 51);
}
 /* .el-tabs ::v-deep {
 width: 80%;
} */
.el-tabs--border-card {
  border: 0;
  box-shadow: none;
}
::v-deep.el-tabs--border-card > .el-tabs__header .el-tabs__item.is-active {
  color: #ffffff;
  border: 0;
  background: rgb(51, 51, 51);;
}
::v-deep.el-tabs--border-card > .el-tabs__header .el-tabs__item {
  border: 0;
  padding: 0 30px;
}
::v-deep
  .el-tabs--border-card
  > .el-tabs__header
  .el-tabs__item:not(.is-disabled):hover {
  color: #ffffff;
}
::v-deep .el-tabs__nav-wrap {
  margin-bottom: 0;
}
::v-deep .el-tabs__item.is-active {
  color: #ffffff;
}
::v-deep.el-tabs--border-card > .el-tabs__header {
  border: 0;
}
.el-tabs--border-card {
  background: #333333;
}
.content {
  margin-top: 30px;
}
::v-deep .el-tabs--border-card > .el-tabs__content {
  padding: 5px;
}
.add {
  position: relative;
  right: 68px;
  font-size: 14px;
  bottom: 4px;
}
.typeUl {
  width: 90%;
  display: flex;
  flex-wrap: wrap;
  margin-left: 15px;
  /* justify-content: space-between; */
}
.typeUl li {
  background: #2e2e2e;
  font-size: 14px;
  width: 6%;
  padding: 5px 10px;
  margin: 0 10px 10px 10px;
  border-radius: 25px;
  text-align: center;
  cursor: pointer;
  /* padding: 5px 10px; */
}
.active {
  background: #1a8aff !important;
  color: #fff;
}
.list .userImg {
  width: 30px;
  height: 30px;
  float: left;
  padding-top: 5px;
  margin-right: 10px;
  border-radius: 50%;
  /* margin-left: 10px;
    margin-top: 3px; */
}
.list .browseNum {
  float: left;
  width: 22px;
  height: 16px;
  margin-right: 4px;
  padding-top: 12px;
}
.footer {
  background: #1d1d1d;
  height: 40px;
  display: flex;
  justify-content: space-between;
}
b {
  font-weight: normal;
}
@media only screen and (max-width: 767px) {
  .table .list{
    width: 44%;
  }
  .typeUl li{
    padding: 5px 2px;
    width: 25%;
  }
  .search_box{
    margin-top: 0;
  }
  .header span{
    width: 50px;
  }
  .sp1 span{
    font-size: 12px;
    display: block;
    width: 50px;
    overflow: hidden;
    text-overflow:ellipsis;
    white-space:nowrap;
    margin-left: 30px;
  }
  .sp2 span{
    margin-left: 30px;
  }
  /* .tabs__header .el-tabs__item{
    padding: 0;
  } */
  ::v-deep .el-tabs--border-card > .el-tabs__header .el-tabs__item{
    padding: 0 25px;
  }
  .list .box{
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
  }
  .list img {
    position: absolute;
    text-align: center;
    height: 100%;
    /* width: 144px; */

  }
}
</style>