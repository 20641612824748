<!--  -->
<template>
  <div class="personal">
    <div class="header">
      <span>会员卡密</span>
    </div>
    <div class="box">
      <div class="content">
        <div class="title">
          <span>绑定会员卡</span>
        </div>
        <div class="ipt1 ipt">
          <span>卡号：</span>
          <el-input v-model="input1" placeholder="请输入16位卡号"></el-input>
        </div>
         <div class="ipt1 ipt">
          <span>密码：</span>
          <el-input v-model="input2" placeholder="请输入卡密" type="password"></el-input>
        </div>
        <div class="btn">
          <button class="blue" @click="btn()" v-preventReClick>立即绑定</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import cookie from "../../../public/js/cookie";
import axios from "../../../untils/request";
export default {
  name: "",

  //import引入的组件需要注入到对象中才能使用
  components: {},

  //父组件传入子组件数据
  props: {},

  //格式化数据
  filters: {},

  data() {
    //这里存放数据
    return {
      radio: 1,
      input1:'',
      input2:''
    };
  },

  //计算属性 类似于data概念
  computed: {},

  //监控data中的数据变化
  watch: {},

  //生命周期 - 创建完成（可以访问当前this实例）
  created() {},

  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},

  //方法集合
  methods: {
    btn(){
      let data ={
        cardNumber:this.input1,
        cardSecret:this.input2
      }
      axios
        .post("/wiiboox-biz/tMemberCard/bind",data, {
          headers: {
            "content-type": "application/json",
            Authorization: "Bearer " + cookie.get("token"),
          },
        })
        .then((res) => {
          if(res.code == 500) {
            this.$message({message:res.message,
              type:'error'
            })
          } else{
             this.$message({message:res.message,
              type:'success'
            })
            this.$router.push({ name: "index"});
          }
          
        });
    }
  },

  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>

<style scoped>
.box {
  width: 800px;
  height: 280px;
  border: 1px solid #4a4a4a;
  border-radius: 4px;
  position: relative;
  top: 35%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.content {
  width: 400px;
  margin: 0 auto;
}
.person1 {
  margin-top: 50px;
}
.person1 img {
  float: left;
}
.person1 p {
  color: #666666;
  font-size: 14px;
  margin-top: 10px;
  margin-left: 20px;
  float: left;
}
.person1 button {
  background: none;
  color: #fff;
  border: 1px solid #666666;
  padding: 3px 40px;
  border-radius: 3px;
  margin-left: 20px;
}
.ipt {
  margin-top: 10px;
}
.ipt span {
  display: block;
  float: left;
  width: 73px;
  color: #cccccc;
  font-size: 14px;
  height: 30px;
  line-height: 30px;
  padding-right: 20px;
}
.ipt1 {
  margin-top: 50px;
}
.el-input {
  width: 73%;
  height: 30%;
}
>>> .el-input__inner {
  height: 30px;
  border: 0;
  background: #2c2c2c;
}
.ipt p {
  color: #666666;
  font-size: 14px;
  padding: 3px 0;
}
::v-deep.el-radio__inner {
  border-radius: 2px;
}
::v-deep.el-radio {
  margin-right: 10px;
}
.btn{
    width: 100%;
    margin: 130px auto;
    
}
.btn .blue{
    display: block;
    width: 40%;
    padding: 5px 20px;
    margin: 0 auto;
    background: #1a8aff;
    color: #fff;
    border: 0;
    border-radius: 3px;
}
.title{
    text-align: center;
    color: #fff;
    padding-top: 30px;
}
</style>