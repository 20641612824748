<template>
<div style="padding:20px">
  <div class="msg">
    <img src="../../assets/test.png" alt="" class="portrait" />
    <div class="user_name">{{ commentData.createBy }}</div>
    <div class="time">{{ commentData.createTime }}</div>
    <p>{{ commentData.commentContent }}</p>
        <p class="msgBox">
      <span @click="msgBox(commentData)">回复</span><span>举报</span>
    </p>
        <div v-if="show == commentData">
      <el-input
        type="textarea"
        rows="2"
        placeholder="我来说两句"
        v-model="textareaList[commentData]"
      >
      </el-input>
      <div class="reply">
        <button class="gray" @click="postReply(commentData)">发送</button>
      </div>
    </div>
    <div>
        <parentCommit :parentData="commentData" v-if="commentData"  @fatherMethod="fatherMethod"/>
    </div>
    
  </div>
  
  </div>
  
</template>
 

<script>
import parentCommit from "../model/parent";
import axios from "../../../untils/request.js";
import qs from "qs";
import cookie from "../../../public/js/cookie.js";
export default {
  props: ["commentData"],
  components: {
    parentCommit,
  },
  data(){
    return{
        show:'',
        textareaList:[]
    }
  },  
  methods:{
    msgBox(commentData) {
      this.show = commentData;
    },
    fatherMethod(){
        this.$parent.getAll();
    },
    postReply(commentData) {
      let data = {
        action: 2,
        comment: this.textareaList[commentData],
        objId: commentData.objId,
        parentId: commentData.id,
        type: 2,
      };
      axios
        .post("/wiiboox-biz/tUserReplay/replay", data, {
          headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + cookie.get("token"),
          },
        })
        .then((res) => {
            this.$parent.getAll();
        });
    },
  }
};
</script>
 
<style scoped>
/* .box {
  border: 1px solid #e4e4e4;
  background-color: #eee;
  margin: 10px;
  padding: 10px;
} */
.gray {
  background: #333;
  border: 1px solid #4a4a4a;
  color: #fff;
  padding: 2px 15px;
  margin-left: 50px;
  border-radius: 5px;
  cursor: pointer;
}
.reply {
  display: flex;
  justify-content: right;
}
.msg {
  width: 100%;
  padding: 15px 0;
  border-bottom: 1px solid #262626;
}

.msg .portrait {
  width: 30px;
  height: 30px;
}
.portrait {
  width: 65px;
  height: 65px;
  border-radius: 50%;
  float: left;
}
.msg .user_name {
  font-size: 12px;
  padding-left: 10px;
}
.user_name {
  display: flex;
  color: #fff;
  padding-left: 15px;
  padding-top: 5px;
  text-align: left;
}
.msg img {
  position: relative;
  top: 8px;
}
.time {
  color: #606060;
  font-size: 12px;
  display: flex;
  margin-top: 1px;
  text-align: left;
  padding-left: 10px;
}
.msg p {
  font-size: 14px;
  color: #999999;
  padding-left: 40px;
  margin-top: 5px;
}
.msgBox {
  display: flex;
  justify-content: right;
  font-style:normal
}
.msgBox span {
  margin-left: 10px;
  color: #666666;
  font-size: 13px;
  cursor: pointer;
}
.reply button {
  margin-top: 13px;
}
@media only screen and (max-width: 767px) {
  .msg{float: left;}
}
</style>
