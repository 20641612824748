<!--  -->
<template>
  <div class="leftnav">
    <el-menu
      :default-active="$route.path"
      class="el-menu-vertical-demo"
      @open="handleOpen"
      @close="handleClose"
      menu-trigger
      background-color="#333333"
      text-color="#efefef"
      active-text-color="#1c98ff"
      active-background-color="#1c98ff"
      v-if="this.roles == 1"
    >
      <template v-for="item in list">
        <el-menu-item
          :index="item.path"
          :route="item.path"
          v-if="item.cd < 1"
          @click.native="rot(item)"
        >
          <template slot="title">
            <i :class="item.icon"></i>
            <span>{{ item.label }}</span>
          </template>
        </el-menu-item>
        <el-menu-item
          :index="item.path"
          :route="item.path"
          v-if="item.label == '我的资产'"
          @click.native="rot(item)"
        >
          <template slot="title">
            <i :class="item.icon"></i>
            <span>{{ item.label }}</span>
          </template>
        </el-menu-item>
        <el-submenu
          :index="item.path"
          :route="item.path"
          v-if="item.cd > 1 && item.label != '我的资产'"
          :default-active="$route.path"
        >
          <template slot="title">
            <i :class="item.icon"></i>
            <span>{{ item.label }}</span>
          </template>
          <el-submenu
            v-for="(child, a) in item.children"
            :key="a"
            :index="child.path"
            :route="child.path"
            v-if="child.children && child.type != 0"
            @click.native="rot(child)"
          >
            <template slot="title"
              ><span>{{ child.label }}</span></template
            >
            <el-menu-item
              v-for="(childs, b) in child.children"
              :key="b"
              :index="childs.path"
              :route="childs.path"
              @click.native="rot(childs)"
              >{{ childs.label }}</el-menu-item
            >
          </el-submenu>
          <el-menu-item
            v-for="(child, a) in item.children"
            :key="a"
            :index="child.path"
            :route="child.path"
            @click.native="rot(child)"
            v-if="!child.children && child.type == 0"
          >
            <template slot="title">
              <span>{{ child.label }}</span>
            </template>
          </el-menu-item>
        </el-submenu>
      </template>
    </el-menu>
    <el-menu
      :default-active="$route.path"
      class="el-menu-vertical-demo"
      @open="handleOpen"
      @close="handleClose"
      menu-trigger
      background-color="#333333"
      text-color="#efefef"
      active-text-color="#1c98ff"
      active-background-color="#1c98ff"
      v-if="this.roles == 0"
    >
      <template v-for="item in list">
        <el-menu-item   @click.native="rot(item)" :index="item.path" :route="item.path" v-if="item.cd < 1" >
          <template slot="title">
            <i :class="item.icon"></i>
            <span>{{ item.label }}</span>
          </template>
        </el-menu-item>
        <el-submenu
          :index="item.path"
          :route="item.path"
          v-if="item.cd > 1"
        >
          <template slot="title">
            <i :class="item.icon"></i>
            <span>{{ item.label }}</span>
          </template>
          <el-submenu
            v-for="(child, a) in item.children"
            :key="a"
            :index="child.path"
            :route="child.path"
            v-if="child.children"
          >
            <template slot="title"
              >{{ child.label }}</template
            >
            <el-menu-item
              v-for="(childs, b) in child.children"
              :key="b"
              :index="childs.path"
              :route="childs.path"
              @click.native="rot(childs)"
              >{{ childs.label }}</el-menu-item
            >
          </el-submenu>
          <el-menu-item
            v-for="(child, a) in item.children"
            :key="a"
            :index="child.path"
            :route="child.path"
            v-if="!child.children"
            @click.native="rot(child)"
          >
            <template slot="title">
              <span>{{ child.label }}</span>
            </template>
          </el-menu-item>
        </el-submenu>
      </template>
    </el-menu>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import cookie from "../../public/js/cookie";
import axios from "../../untils/request";
export default {
  name: "leftnav",

  //import引入的组件需要注入到对象中才能使用
  components: {},

  //父组件传入子组件数据
  props: {},

  //格式化数据
  filters: {},

  data() {
    //这里存放数据
    return {
      list: [],
      key: "",
      roles: "",
    };
  },

  //计算属性 类似于data概念
  computed: {},

  //监控data中的数据变化
  watch: {},

  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.token = cookie.get("token");
    this.getNav();
  },

  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},

  //方法集合
  methods: {
    rot(item) {
      if (item.label == "我的上传") {
        this.$router.push({ name: "upload", params: item });
      } else {
        this.$router.push({ path: item.path });
      }
    },
    handleOpen(key, keyPath) {
      if (key == "/biz/modelFile/index" || key == "/biz/user/index") {
        this.$router.push({ path: key.toString(), query: { path: key } });
      } 
    },
    handleClose(key, keyPath) {
      if (key == "/biz/modelFile/index" || key == "/biz/user/index") {
        this.$router.push({ path: key.toString(), query: { path: key } });
      }
    },
    getNav() {
      let headers = {};
      if (cookie.get("token") == undefined) {
        headers = { "Content-type": "application/x-www-form-urlencoded" };
      } else {
        headers = {
          "Content-type": "application/x-www-form-urlencoded",
          Authorization: "Bearer " + cookie.get("token"),
        };
      }
      axios
        .get("/wiiboox-admin/sysUser/info", {
          headers:headers,
        })
        .then((res) => {
          cookie.set("userId", res.data.id);
          res.data.roles.forEach((item) => {
            if (item == "ROLE_ADMIN") {
              this.roles = 0;
            } else {
              this.roles = 1;
            }
          });
          this.list = generateOptions(res.data.menus);
          let typeArr = [];
          this.list.forEach((item) => {
            item.cd = item.children.length.toString();
            if (item.children.length < 1) {
              delete item.children;
            }
          });
          // this.list.forEach((item) => {
          //   console.log('aaaaaaaaaaaaaaaaaa')
          //   if (item.label == "我的上传") {
          //     item.children.forEach((i) => {
          //       if (i.label == "3D编辑") {
          //         cookie.set("url", i.path);
          //       }
          //     });
          //   }
          // });
          function generateOptions(params) {
            //生成Cascader级联数据
            var result = [];
            for (let param of params) {
              if(param.parentId == null){
                return param.parentId = ''
              } else if(param.path == null){
                return param.path = ''
              }
              if (param.parentId.length <= 1) {
                //判断是否为顶层节点
                var parent = {
                  //转换成el-Cascader可以识别的数据结构
                  label: param.name,
                  value: param.id,
                  path: param.path,
                  type: param.type,
                  icon: param.icon
                };
                parent.children = getchilds(param.id, params); //获取子节点
                result.push(parent);
              }
            }
            return result;
          }

          function getchilds(id, array) {
            let childs = new Array();
            for (let arr of array) {
              //循环获取子节点
              if (arr.parentId == id) {
                childs.push({
                  label: arr.name,
                  value: arr.id,
                  path: arr.path,
                  type: arr.type,
                });
              }
            }
            for (let child of childs) {
              //获取子节点的子节点
              let childscopy = getchilds(child.value, array); //递归获取子节点
              if (childscopy.length > 0) {
                child.children = childscopy;
              }
            }
            return childs;
          }
        });
    },
  },

  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>

<style scoped>
.leftnav {
  width: 200px;
  height: 80vh;
  background: rgb(51, 51, 51);
  /* position: absolute;
  top: 10%;
  left: 10%; */
  min-width: 5%;
}
.el-menu {
  border: 0;
}
.menuitem .el-submenu__title:hover {
  background-color: #1c98ff !important;
}
::v-deep .el-menu-item.is-active{
  color: #fff !important;
  background: #1c98ff !important;
}
.el-icon-index{
  height: 20px;
  width: 20px;
  background: url('../assets/index.png') center no-repeat;
}
.el-icon-upload1{
  height: 20px;
  width: 20px;
  background: url('../assets/upload.png') center no-repeat;
}
.el-icon-results{
  height: 20px;
  width: 20px;
  background: url('../assets/results.png') center no-repeat;
}
.el-icon-cost{
  height: 22px;
  width: 20px;
  background: url('../assets/cost.png') center no-repeat;
}
.el-icon-myModel{
  height: 20px;
  width: 20px;
  background: url('../assets/myModel.png') center no-repeat;
}
.el-icon-msg{
  height: 20px;
  width: 20px;
  background: url('../assets/msg.png') center no-repeat;
}
.el-icon-data{
  height: 20px;
  width: 20px;
  background: url('../assets/data.png') center no-repeat;
}
span{
  margin-left: 10px;
}
@media only screen and (max-width: 767px) {
  .leftnav{
    display: none;
  }
}
</style>