<!-- 联系咨询 -->
<template>
  <div class="payment">
    <div class="header">
      <span>联系咨询</span>
    </div>
    <div class="content">
      <p class="title">
        非常感谢您对WiibooxMeta的关注，请您填写以下信息，我们会在一个工作日内与您联系。
      </p>
      <div class="text">
        <div class="ipt_box">
          <span>姓名</span
          ><el-input v-model="input" placeholder="请输入内容"></el-input>
        </div>
        <div class="ipt_box">
          <span>公司</span
          ><el-input v-model="input1" placeholder="请输入内容"></el-input>
        </div>
        <div class="ipt_box">
          <span>手机</span
          ><el-input v-model="input2" placeholder="请输入内容"></el-input>
        </div>
        <div class="ipt_box">
          <span>邮箱</span
          ><el-input v-model="input3" placeholder="请输入内容"></el-input>
        </div>
        <div class="ipt_box">
          <span>留言</span
          ><el-input
            type="textarea"
            :rows="5"
            placeholder="请输入内容"
            v-model="textarea"
          >
          </el-input>
        </div>
      </div>
      <div class="btn"><button>确定</button></div>
    </div>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';

export default {
  name: "",

  //import引入的组件需要注入到对象中才能使用
  components: {},

  //父组件传入子组件数据
  props: {},

  //格式化数据
  filters: {},

  data() {
    //这里存放数据
    return {
      radio: 3,
    };
  },

  //计算属性 类似于data概念
  computed: {},

  //监控data中的数据变化
  watch: {},

  //生命周期 - 创建完成（可以访问当前this实例）
  created() {},

  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},

  //方法集合
  methods: {},

  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>

<style scoped>
.el-radio-group {
  display: flex;
  flex-wrap: wrap;
}
.el-radio {
  width: 100%;
  /* margin-top: 20px; */
  padding: 10px 20px;
}
.content {
  width: 636px;
  position: relative;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.radio {
  padding: 20px 0;
  display: flex;
}
.radio span {
  /* float: left;
  */
  font-size: 14px;
  color: #999999;
}
.money {
  text-align: right;
  font-size: 14px;
  color: #999999;
  padding-bottom: 10px;
}

.content p {
  color: #fff;
  margin-bottom: 10px;
}
.text {
  border: 1px solid #4a4a4a;
  padding: 20px 100px;
  border-radius: 10px;
}
.text p {
  color: #999999;
  font-size: 12px;
}
button {
  width: 80px;
  height: 24px;
  background: #1a8aff;
  border: 0;
  color: #fff;
  border-radius: 5px;
}
.btn {
  margin-top: 30px;
  width: 100%;
  display: flex;
  justify-content: center;
}
.content .title {
  font-size: 14px;
  color: #999999;
  text-align: center;
}
.el-input {
  width: 385px;
}
::v-deep .el-input__inner {
  border: 0;
  background: #2c2c2c;
  height: 28px;
}
.ipt_box {
  display: flex;
  margin-top: 20px;
  color: #cccccc;
  font-size: 12px;
}
.ipt_box span {
  margin-right: 17px;
  height: 28px;
  line-height: 28px;
}
.el-textarea {
  width: 385px;
}
::v-deep .el-textarea__inner {
  border: 0;
  background: #2c2c2c;
}
</style>