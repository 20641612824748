
<template>
  <div class="iframe">
    <div class="img" id="container">
      <div id="loading" v-if="loading1">
        <div id="info">Loading...</div>
        <div id="infoNum">0%</div>
        <div id="parent">
          <div id="child"></div>
        </div>
      </div>
      <!-- <img
          src="../../assets/preview.png"
          alt=""
          class="preview"
          url=""
          @click="preview()"
        /> -->
      <!-- <img src="../../assets/test.png" alt=""  style=""/> -->
      <!-- <div id="container"></div> -->
      <!-- </div> -->
      <!-- <iframe
          :src="'/js/demo11.html?' + detail.previewUrl"
          frameborder="0"
          style="width: 100%; height: 100%"
          id="iframe"
        ></iframe> -->
    </div>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import axios from "../../../untils/request.js";
import qs from "qs";
import cookie from "../../../public/js/cookie.js";
import { OrbitControls } from "../../../public/js/OrbitControls.js";
import { GLTFLoader } from "../../../public/js/GLTFLoader.js";
import { DRACOLoader } from "../../../public/js/DRACOLoader.js";
import { RGBELoader } from "../../../public/js/RGBELoader.js";
import * as THREE from "three";
export default {
  name: "",

  //import引入的组件需要注入到对象中才能使用
  components: {},

  //父组件传入子组件数据
  props: {},

  //格式化数据
  filters: {},

  data() {
    //这里存放数据
    return {
      mesh: null,
      camera: null,
      scene: null,
      renderer: null,
      controls: null,
      loading1: true,
      detail: "",
      group: "",
      mixer: null,
      clock: new THREE.Clock(),
    };
  },

  //计算属性 类似于data概念
  computed: {},

  //监控data中的数据变化
  watch: {},

  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.getmodel();
    this.group = new THREE.Group();
    window.addEventListener("resize", this.onResize, false);
  },

  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},

  //方法集合
  methods: {
    //获取模型
    getmodel() {
      axios
        .get(
          "/wiiboox-biz/tModelProject/detail?id=" +
            this.$route.query.id +
            "&accuracyLevel=" +
            this.$route.query.accuracyLevel,
          {
            headers: {
              "Content-type": "application/x-www-form-urlencoded",
              Authorization: "Bearer " + cookie.get("token"),
            },
          }
        )
        .then((res) => {
          this.detail = res.data;
          this.init();
        });
    },
    // 解密
    decode(str) {
      return decodeURIComponent(
        atob(str)
          .split("")
          .map(function (c) {
            return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
          })
      );
    },
    init() {
      this.createScene(); // 创建场景
      // this.new()
      // this.loadRgb();
      this.loadGLTF(); // 加载GLTF模型
      this.createLight(); // 创建光源
      this.createCamera(); // 创建相机
      this.createRender(); // 创建渲染器
      // this.createControls(); // 创建控件对象
      this.render(); // 渲染
    },
    // 创建场景
    createScene() {
      this.scene = new THREE.Scene();
    },
    loadRgb() {
      //加载hdr

      var that = this;
      // 使用hdr作为背景色
      var pmremGenerator = new THREE.PMREMGenerator(this.renderer);
      pmremGenerator.compileEquirectangularShader(); //阴影
      new RGBELoader()
        .setDataType(THREE.UnsignedByteType)
        .load("/public/luori.hdr", (texture) => {
          const envMap = pmremGenerator.fromEquirectangular(texture).texture;
          this.scene.environment = envMap; // 给场景添加环境光效果
          //this.scene.background = envMap；// 给场景添加背景图
          pmremGenerator.dispose();
        });
    },
    new() {
      const pmremGenerator = new THREE.PMREMGenerator(this.renderer);

      pmremGenerator.compileEquirectangularShader();
      new RGBELoader()

        .setDataType(THREE.UnsignedByteType)
        .load("/room.hdr", (texture) => {
          const envMap = pmremGenerator.fromEquirectangular(texture).texture;

          this.scene.background = envMap;

          this.scene.environment = envMap;

          texture.dispose();

          pmremGenerator.dispose();
        });
    },
    loadGLTF() {
      // const loader = new GLTFLoader();
      // // loader.load(this.detail.previewUrl);
      // const dracoLoader = new DRACOLoader();
      // dracoLoader.setDecoderPath("/draco/");
      // dracoLoader.preload();
      // loader.setDRACOLoader(dracoLoader);
      // loader.setCrossOrigin("anonymous");
      // new RGBELoader().setDataType(THREE.UnsignedByteType).load("/luori.hdr", (texture)=> {
      //   texture.mapping = THREE.EquirectangularReflectionMapping;
      //   this.scene.background = texture;
      //   this.scene.environment = texture;
      this.detail.previewUrls.forEach((item, index) => {
        this.getload(item, index);
      });
    },
    getload(b, index) {
      if (this.detail.previewUrls.length < 2) {
        b = this.detail.previewUrls[0];
      }
      const manager = new THREE.LoadingManager();

      manager.onProgress = function (item, loaded, total) {};
      const loader = new GLTFLoader(manager);
      // loader.load(this.detail.previewUrl);
      const dracoLoader = new DRACOLoader();
      dracoLoader.setDecoderPath("/draco/");
      dracoLoader.preload();
      loader.setDRACOLoader(dracoLoader);
      loader.setCrossOrigin("anonymous");
      loader.load(
        // this.detail.previewUrls[0],
        b,
        (gltf) => {
          let self = this;
          const element = document.getElementById("container");
          const width = element.clientWidth; // 窗口宽度
          const height = element.clientHeight; // 窗口高度
          let x, y, z, i, m;
          i = height / width;
          let bbox = new THREE.Box3().setFromObject(gltf.scene);
          x = bbox.max.x - bbox.min.x;
          y = bbox.max.y - bbox.min.y;
          z = bbox.max.z - bbox.min.z;
          if (y / x >= i) {
            let h = y;
            let Fov = (this.camera.fov * Math.PI) / 240;
            m = h / (2 * Math.tan(Fov * 1));
            this.camera.position.y = 0;
            this.camera.position.z = 2 * m + z / 2;
            this.camera.position.x = 0;
          } else {
            let w = x;
            let h = w * i;
            let Fov = (this.camera.fov * Math.PI) / 240;
            m = h / (2 * Math.tan(Fov * 1));
            this.camera.position.y = 0;
            this.camera.position.z = 2 * m + z / 2;
            this.camera.position.x = 0;
          }
          var mesh = gltf.scene;
          if (gltf.animations.length > 0) {
            self.mixer = new THREE.AnimationMixer(mesh);
            var action = self.mixer.clipAction(gltf.animations[0]);
            action
              .stop()
              // .setDuration(5)//这个为播放速度
              .play();
          }
          this.group.position.set(
            -(bbox.max.x + bbox.min.x) / 2,
            -(bbox.max.y + bbox.min.y) / 2,
            -(bbox.max.z + bbox.min.z) / 2
          );
          this.group.add(gltf.scene);
          this.a++;
          this.num = (100 / this.detail.previewUrls.length) * this.a;
          var info = document.getElementById("info");
          var infoNum = document.getElementById("infoNum");
          var child = document.getElementById("child");
          info.innerText = "loading...";
          infoNum.innerText = Math.round(this.num, 2) + "%";
          child.style.width = Math.round(this.num, 2) + "%";
          if (index == this.detail.previewUrls.length - 1) {
            this.scene.add(this.group);
            this.loading1 = false;
          }
          this.createControls();
        },
        (xhr) => {
          if (this.detail.previewUrls.length < 2) {
            var percentComplete = (xhr.loaded / xhr.total) * 100;
            if (percentComplete == 100) {
              percentComplete = 80;
            }
            var percent = document.getElementById("percent");
            var info = document.getElementById("info");
            var infoNum = document.getElementById("infoNum");
            var child = document.getElementById("child");
            info.innerText = "loading...";
            infoNum.innerText = Math.round(percentComplete, 2) + "%";
            child.style.width = Math.round(percentComplete, 2) + "%";
          }
        },
        (error) => {}
      );
    },
    // 创建光源
    createLight() {
      // 环境光
      const ambientLight = new THREE.AmbientLight(0xffffff); // 创建环境光
      this.scene.add(ambientLight); // 将环境光添加到场景

      const spotLight = new THREE.DirectionalLight(0xffffff); // 创建聚光灯
      spotLight.position.set(-20, 20, -20);
      // spotLight.castShadow = true;
      this.scene.add(spotLight);
    },
    // 创建相机
    createCamera() {
      const element = document.getElementById("container");
      const width = element.clientWidth; // 窗口宽度
      const height = element.clientHeight; // 窗口高度
      const k = width / height; // 窗口宽高比
      // PerspectiveCamera( fov, aspect, near, far )
      // this.camera = new THREE.PerspectiveCamera(35, k, 0.1, 1000);
      this.camera = new THREE.PerspectiveCamera(45, k, 0.1, 100000000);
      // this.camera.position.set(150, 150, 150); // 设置相机位置

      // this.camera.lookAt(new THREE.Vector3(10, 40, 0)); // 设置相机方向
      this.scene.add(this.camera);
    },
    // 创建渲染器
    createRender() {
      const element = document.getElementById("container");
      this.renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });
      this.renderer.setSize(element.clientWidth, element.clientHeight); // 设置渲染区域尺寸
      this.renderer.shadowMap.enabled = true; // 显示阴影
      this.renderer.shadowMap.type = THREE.PCFSoftShadowMap;
      this.renderer.toneMapping = THREE.ACESFilmicToneMapping;
      this.renderer.toneMappingExposure = 0.5;
      this.renderer.outputEncoding = THREE.sRGBEncoding;
      this.renderer.setClearColor(0x3f3f3f, 1); // 设置背景颜色
      //this.renderer.setClearColor(0xf5f5f5, 1); // 设置背景颜色
      element.appendChild(this.renderer.domElement);
    },
    onResize() {
      //屏幕自适应
      this.camera.aspect = window.innerWidth / window.innerHeight;
      this.camera.updateProjectionMatrix();
      this.renderer.setSize(window.innerWidth, window.innerHeight);
    },
    render() {
      let that = this;
      if (this.mesh) {
        this.mesh.rotation.z += 0.006;
      }
      this.renderer.render(this.scene, this.camera);
      requestAnimationFrame(this.render);

      // TWEEN.update();
      var time = that.clock.getDelta();
      if (this.mixer) {
        this.mixer.update(time);
      }
    },
    // 创建控件对象
    createControls() {
      this.controls = new OrbitControls(this.camera, this.renderer.domElement);
      this.controls.dampingFactor = 0.05;
      let num =
        this.camera.position.x +
        this.camera.position.y +
        this.camera.position.z;
      this.controls.minDistance = num * 0.5;
      this.controls.maxDistance = num * 4;

      this.controls.update();
    },
  },

  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>

<style scoped>
#loading {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
#info {
  text-align: center;
  color: #1a8aff;
  font-size: 16px;
  margin-bottom: 5px;
}
#infoNum {
  float: right;
  margin-top: -22px;
  color: #1a8aff;
  font-size: 12px;
}

#parent {
  width: 277px;
  height: 3px;
  border-radius: 20px;
  background: #888888;
}

#child {
  width: 0%;
  height: 3px;
  background-color: #1a8aff;
  border-radius: 20px;
}
#container {
  width: 100%;
  height: 100vh;
}
.iframe{
  width: 100vw;
  height: 100vh;
}
</style>