<!--  -->
<template>
  <div class="user">
    <div>
        <button class="deletes">批量删除</button>
    </div>
    <div class="table">
      <el-table
        ref="multipleTable"
        :data="tableData"
        row-key="id"
        tooltip-effect="dark"
        stripe
         @selection-change="handleSelectionChange"
        class="customer-table"
      >
       
        <el-table-column type="selection" width="55">
        </el-table-column>
        <!-- <el-table-column width="70" label="序号" prop="parentIndex">
          <template slot-scope="scope">
            <span v-if="scope.row.isIndex">{{ scope.row.parentIndex }}</span>
          </template>
        </el-table-column> -->
        <el-table-column prop="username" label="用户名称" width="120">
        </el-table-column>
        <el-table-column prop="phone" label="手机号" width="120">
        </el-table-column>
        <el-table-column prop="createTime" label="创建日期" width="180">
        </el-table-column>
        <el-table-column prop="email" label="邮箱" width="180">
        </el-table-column>
        <el-table-column prop="status" label="状态" width="120">
        </el-table-column>
        <el-table-column label="操作" width="410">
          <template slot-scope="scope">
            <el-button size="mini" @click="handleEdit(scope.$index, scope.row)"
              >编辑</el-button
            >
            <el-button size="mini" @click="handRole(scope.$index, scope.row)"
              >角色分配</el-button
            >
            <el-button
              size="mini"
              type="danger"
              @click="handleDelete(scope.$index, scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
    </div>
    <div class="paginationClass">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="page_current"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="100"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
    <el-dialog title="角色分配" :visible.sync="dialogTableVisible">
      <el-checkbox-group v-model="checkList">
        <el-checkbox v-for="(item, i) in userList" :key="i" :label="item.id">{{
          item.name
        }}</el-checkbox>
      </el-checkbox-group>
      <button @click="userBtn()">确定</button>
    </el-dialog>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import cookie from "../../../../public/js/cookie";
import axios from "../../../../untils/request";
import qs from "qs";
export default {
  name: "",

  //import引入的组件需要注入到对象中才能使用
  components: {},

  //父组件传入子组件数据
  props: {},

  //格式化数据
  filters: {},

  data() {
    //这里存放数据
    return {
      //表格数据
      tableData: [],
      userList: [],
      checkList: [],
      dialogTableVisible: false,
      userId: "",
      total:1,
      page_current:1,
      page_size:10,
    };
  },

  //计算属性 类似于data概念
  computed: {},

  //监控data中的数据变化
  watch: {},

  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.getRole();
    this.getList();
  },

  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},

  //方法集合
  methods: {
    getRole() {
      axios
        .get("/wiiboox-admin/sysRole/getAll", {
          headers: {
            "content-type": "application/x-www-form-urlencoded",
            Authorization: "Bearer " + cookie.get("token"),
          },
        })
        .then((res) => {
          console.log(res);
        });
    },
    getList() {
      axios
        .get("/wiiboox-admin/sysUser/page", {
          headers: {
            "content-type": "application/x-www-form-urlencoded",
            Authorization: "Bearer " + cookie.get("token"),
          },
        })
        .then((res) => {
          console.log(res.data.records);
          this.tableData = res.data.records;
          this.page_current = res.data.current
          this.total = res.data.total
          this.page_size = res.data.size
        });
    },
    handRole(i, id) {
      this.checkList = [];
      console.log(this.checkList);
      this.dialogTableVisible = true;
      console.log(id);
      this.userId = id.id;
      axios
        .get("/wiiboox-admin/sysUser/sysRole/" + id.id, {
          headers: {
            "content-type": "application/x-www-form-urlencoded",
            Authorization: "Bearer " + cookie.get("token"),
          },
        })
        .then((res) => {
          console.log(res.data);
          res.data.forEach((item) => {
            this.checkList.push(item.id);
          });
          this.getuserlist();
        });
    },
    getuserlist() {
      this.userList = [];
      axios
        .get("/wiiboox-admin/sysRole/page/", {
          headers: {
            "content-type": "application/x-www-form-urlencoded",
            Authorization: "Bearer " + cookie.get("token"),
          },
        })
        .then((res) => {
          console.log(res.data.records);
          //   this.userList = res.data.records;
          res.data.records.forEach((item) => {
            this.userList.push({
              name: item.name,
              id: item.id,
            });
          });
        });
    },
    userBtn() {
    let data = { roleIds: this.checkList.toString(), userId: this.userId };
      axios({
        method: "put",
        url: "/wiiboox-admin/sysUser/sysRole/update/",
        headers: {
          "content-type": "application/x-www-form-urlencoded",
          Authorization: "Bearer " + cookie.get("token"),
        },
        data:qs.stringify(data),
      })
        .then((res) => {
          console.log("成功");
          console.log(res);
        })
        .then((err) => {
          console.log("err");
        });
    },
    handleDelete(i,id){
    let data = {
        ids:id.id
    }
      axios({
        method: "delete",
        url: "/wiiboox-admin/sysUser/delByIds/",
        headers: {
          "content-type": "application/x-www-form-urlencoded",
          Authorization: "Bearer " + cookie.get("token"),
        },
        data:qs.stringify(data),
      })
        .then((res) => {
          console.log("成功");
          console.log(res);
        })
        .then((err) => {
          console.log("err");
        });
    },
    handleSelectionChange(val) {
        console.log(val)
        let ids = []
        val.forEach(item=>{
            ids.push(item.id)
        })
        let data = {
            ids:ids
        }
      axios({
        method: "delete",
        url: "/wiiboox-admin/sysUser/delByIds/",
        headers: {
          "content-type": "application/x-www-form-urlencoded",
          Authorization: "Bearer " + cookie.get("token"),
        },
        data:qs.stringify(data),
      })
        .then((res) => {
          console.log("成功");
          console.log(res);
        })
        .then((err) => {
          console.log("err");
        });
    },
    handleSizeChange(val){
        console.log(val)
        axios
        .get("/wiiboox-admin/sysUser/page?size=" + val + '&current=' + this.page_current, {
          headers: {
            "content-type": "application/x-www-form-urlencoded",
            Authorization: "Bearer " + cookie.get("token"),
          },
        })
        .then((res) => {
          console.log(res.data.records);
          this.tableData = res.data.records;
        });
    },
    handleCurrentChange(val){
        console.log(val)
        axios
        .get("/wiiboox-admin/sysUser/page?current=" + val + '&size=' + this.page_size , {
          headers: {
            "content-type": "application/x-www-form-urlencoded",
            Authorization: "Bearer " + cookie.get("token"),
          },
        })
        .then((res) => {
          console.log(res.data.records);
          this.tableData = res.data.records;
        });
    },
  },
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>

<style scoped>
.el-dialog {
  width: 30%;
}
.deletes{
    background: #F56C6C;
    padding: 5px 30px;
    color: #fff;
    border: 0;
    margin-bottom: 5px;
}
</style>