<!--  -->
<template>
  <div class="payment">
    <div class="header">
      <span>付款页面</span>
    </div>
    <div class="content">
      <p>订单编号：{{ orderNo }}</p>
      <div class="radio">
        <span>选择付款方式：</span>
        <el-radio-group v-model="radio" @change="getbank()">
          <el-radio :label="1">微信</el-radio>
          <el-radio :label="2">支付宝</el-radio>
          <el-radio :label="3" @click.native="getbank()">对公转账</el-radio>
        </el-radio-group>
      </div>
      <div style="color:#999999;font-size:14px" v-if="show1">付款流水号：<el-input v-model="input" placeholder="请输入付款流水号"></el-input></div>
      <div class="money">支付金额：￥{{ price }}</div>
      <div class="btn" @click="btn" v-preventReClick><button>{{btntext}}</button></div>
    </div>
    <el-dialog
      class="qr_code"
      :visible="show"
      width="300px"
      @close="close"
      title="微信支付"
    >
      <div>
        <div id="qrCode" ref="qrCode">
          <vue-qr
            :text="imageUrl"
            color-dark="#000"
            color-light="#fff"
            :dot-scale="1"
            :logo-scale="0.2"
          />
        </div>
      </div>
      <div style="text-align: center">请用微信扫一扫</div>
      <!-- <div class="el-btn-box">
        <el-button type="primary" @click="getQrCodeImage()">下载</el-button>
        <el-button @click="close">取消</el-button>
      </div> -->
    </el-dialog>
    <el-dialog title="对公转账" :visible.sync="privacyDialogVisible" center>
      <el-card class="box-card" v-for="(item,i) in list" :key="i">
        <div class="text item">
          {{'收款方账户名称: ' + item.accountName }}<br>
          {{'开户银行: ' + item.bankName }}<br>
          {{'账号: ' + item.card }}
        </div>
      </el-card>
      <div class="demo-input-suffix" style="margin-bottom: 70px">
      </div>
      <div class="btn">
        <button @click="btn1()" class="next" v-preventReClick>我已完成转账</button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import cookie from "../../../public/js/cookie";
import axios from "../../../untils/request";
import VueQr from "vue-qr";
export default {
  name: "",

  //import引入的组件需要注入到对象中才能使用
  components: { VueQr },

  //父组件传入子组件数据
  props: {},

  //格式化数据
  filters: {},

  data() {
    //这里存放数据
    return {
      radio: 1,
      show: false,
      imageUrl: "",
      price:cookie.get('payAmount'),
      orderNo:cookie.get('orderNo'),
      T: "", //定时器的ID
      privacyDialogVisible:false,
      list:'',
      input:'',
      show1:false,
      btntext:'立即付款'
    };
  },

  //计算属性 类似于data概念
  computed: {},

  //监控data中的数据变化
  watch: {},

  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
  },

  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},

  //方法集合
  methods: {
    getbank(){
      this.show1 = false
      this.btntext = '立即付款'
      if(this.radio == 3) {
        this.getbanknum()
      }
    },

    close() {
      this.show = false;
    },
    getorder(){
      let data = {
        productType: "1",
        productId: cookie.get('productId'),
        mealsId: cookie.get('mealsId'),
        payAmount:cookie.get('payAmount')
      };
      if(this.price == undefined) {
        this.$message('请先选择套餐')
      } else {
      axios
        .post("/wiiboox-biz/tUserPayOrder/create", data, {
          headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + cookie.get("token"),
          },
        })
        .then((res) => {
          let data = res.data;
          data.price = this.price;
          this.$router.push({ name: "payment", params: data });
        });
      }
    },
    btn() {
      let data = {
        productType: "1",
        productId: cookie.get('productId'),
        mealsId: cookie.get('mealsId'),
        orderNo: cookie.get('orderNo'),
        platform: this.radio,
        id: cookie.get('id'),
        payAmount: cookie.get('payAmount'),
      };
      if (this.radio == 1) {
        this.show = true;
        axios
          .post("/wiiboox-biz/tUserPayOrder/activePay", data, {
            headers: {
              "Content-type": "application/json",
              Authorization: "Bearer " + cookie.get("token"),
            },
          })
          .then((res) => {
            this.imageUrl = res.data;
            this.loopOrderState(1);
            // this.$router.push({ name: "payment",params:res.data });
          });
      } else if(this.radio == 2){
        axios
          .post("/wiiboox-biz/tUserPayOrder/activePay", data, {
            headers: {
              "Content-type": "application/json",
              Authorization: "Bearer " + cookie.get("token"),
            },
          })
          .then((res) => {
            // this.$router.push({ name: "payment",params:res.data });
            const div = document.createElement("divform");
            div.innerHTML = res.data; // data就是接口返回的form 表单字符串
            document.body.appendChild(div);
            let len = document.forms.length - 1;
            // document.forms[len].setAttribute("target", "_blank"); // 新开窗口跳转
            document.forms[len].submit();
            this.loopOrderState(2);
          });
      } else if(this.radio == 3 && this.input != ''){
        let data1 = {
          orderNo: cookie.get('orderNo'),
          amount: cookie.get('payAmount'),
          payNumber:this.input
        }
        axios
          .post("/wiiboox-biz/tUserPayOrder/unionPay", data1, {
            headers: {
              "Content-type": "application/json",
              Authorization: "Bearer " + cookie.get("token"),
            },
          })
          .then((res) => {
            // this.$router.push({ name: "payment",params:res.data });
            if(res.code == 200){
              this.$message(res.message)
              this.$router.push({path:'/home'})
              
            }
          });
      } else {
        this.$message('请输入订单流水号')
      }
    },
    btn1(){
      this.privacyDialogVisible = false
      this.btntext = '确认'
      this.show1 = true
    },
    getbanknum(){
      
      axios
          .get("/wiiboox-biz/tUserPayOrder/getUnionAccount", {
            headers: {
              "Content-type": "application/x-www-form-urlencoded",
              Authorization: "Bearer " + cookie.get("token"),
            },
          })
          .then((res) => {
            this.privacyDialogVisible = true
            this.list = res.data
            // this.$router.push({ name: "payment",params:res.data });
          });
    },
    loopOrderState(i) {
      //轮询当前订单状态s
      this.T = setInterval(() => {
        axios
          .get(
            "/wiiboox-biz/tUserPayOrder/orderQuery?type=" +
              i +
              "&orderNo=" +
              this.$route.params.orderNo,
            {
              headers: {
                "Content-type": "application/json",
                Authorization: "Bearer " + cookie.get("token"),
              },
            }
          )
          .then((res) => {
            // if(res.status == 20){//10是未支付  20已付款
            //     clearInterval(this.T)
            //     this.goOrderList()
            // }
            if (res.data == 1) {
              clearInterval(this.T);
              this.$message({
                message: "付款成功",
                type: "success",
              });
              this.active()
            }
          });
      }, 1000);
    },
    active() {
      setTimeout(this.$router.push({ name: "index" }), 3000);
      // let data = {
      //   productType: "1",
      //   productId: this.$route.params.id,
      //   mealsId: this.$route.params.mealsId,
      //   orderNo: this.$route.params.orderNo,
      //   platform: this.radio,
      //   payAmount: this.$route.params.price,
      // };
      
      // axios
      //   .post("/wiiboox-biz/tUserPayOrder/activeMembers", data, {
      //     headers: {
      //       "Content-type": "application/json",
      //       Authorization: "Bearer " + cookie.get("token"),
      //     },
      //   })
      //   .then((res) => {
      //     console.log(res);
      //     // this.$router.push({ name: "payment",params:res.data });
      //       // setTimeout(this.$router.push({ name: "index" }), 3000);
      //   });
    },
  },

  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>

<style scoped>
.el-radio-group {
  display: flex;
  flex-wrap: wrap;
}
.el-radio {
  width: 100%;
  /* margin-top: 20px; */
  padding: 10px 20px;
}
.content {
  width: 636px;
  position: relative;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.radio {
  border-top: 1px solid #999999;
  padding: 20px 0;
  display: flex;
}
.radio span {
  /* float: left;
  */
  font-size: 14px;
  color: #999999;
}
.money {
  border-bottom: 1px solid #999999;
  text-align: right;
  font-size: 14px;
  color: #999999;
  padding-bottom: 10px;
}
.content p {
  color: #fff;
  margin-bottom: 10px;
}
button {
  width: 152px;
  height: 24px;
  background: #1a8aff;
  border: 0;
  color: #fff;
}
.btn {
  margin-top: 30px;
  width: 100%;
  display: flex;
  justify-content: right;
}
#qrCode img {
  width: 100% !important;
}
.qr_code {
  margin-top: 12vh;
}
::v-deep .el-dialog__title {
  color: #fff;
}
::v-deep .el-dialog {
  background: #333333;
  width: 689px;
}
.el-card{
  border: 0;
  background: #000;
  color: #fff;
  margin-top: 10px;
}
.el-input{
  width: 400px;
  


}
.el-input ::v-deep .el-input__inner{
  height: 30px;
  background: #2c2c2c;
  color: #fff;
  border: 0;
}
</style>