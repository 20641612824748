<!--  -->
<template>
<div class="black">
  <div class="error">
    
      <div class="text">
        <img src="../assets/weihulogo.png" alt="" />
        <p>
          WiibooxMeta即将上线，我们将邀请部分种子用户进行前期试用测试。<br />
          如果您对试用感兴趣，请联系我们的客服，留下您的联系方式。<br />
          WiibooxMeta团队将根据收集到的宝贵反馈进行优化，并争取尽快全面发布！<br />
          感谢您的关注！
        </p>
        <button @click="btn">联系我们</button>
      </div>
    </div>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';

export default {
  name: "",

  //import引入的组件需要注入到对象中才能使用
  components: {},

  //父组件传入子组件数据
  props: {},

  //格式化数据
  filters: {},

  data() {
    //这里存放数据
    return {};
  },

  //计算属性 类似于data概念
  computed: {},

  //监控data中的数据变化
  watch: {},

  //生命周期 - 创建完成（可以访问当前this实例）
  created() {},

  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},

  //方法集合
  methods: {
    btn(){
        window.open('https://www.wiiboox.net/contact.php')
    }
  },

  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>

<style scoped>
.text {
  color: #fff;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.error {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 680px;
  background: url("../assets/weihu.png") center / cover no-repeat;
  background-size: 100% 100%;
}
img {
  display: block;
  margin: 0 auto;
}
p {
  margin-top: 30px;
  text-align: center;
  font-size: 16px;
  font-family: 苹方;
  line-height: 40px;
}
button {
  display: block;
  padding: 6px 30px;
  background: #1a8aff;
  color: #fff;
  border: none;
  border-radius: 3px;
  margin: 20px auto;
  font-size: 16px;
}
.black{
    background: #000000;
    width: 100%;
    height: 100%;
}
</style>