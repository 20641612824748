<!--  -->
<template>
  <div class="modelDetail">
    <!-- <iframe :src="iframesrc" frameborder="0"  id="container3" style="width:100vw;height:100vh;display: none;">
    </iframe> -->
    <div class="header">
      <span>{{ detail.name }}</span>
    </div>

    <div v-show="show3" class="show2">
      {{ this.error }}
    </div>
    <div v-show="show4" class="show2 show4">
      请输入查看密码<br />
      <input
        type="text"
        name=""
        id=""
        placeholder="请输入密码"
        v-model="password"
      />
      <button @click="show4click" v-preventReClick>确认</button>
    </div>
    <div v-show="show1">
      <div class="model">
        <div class="img" id="img">
          <iframe
            :src="iframesrc"
            frameborder="0"
            :class="st"
            id="container"
            allowfullscreen="true"
          >
          </iframe>
          <div id="container1"></div>
          <div id="loading" v-if="loading1">
            <div id="info">Loading...</div>
            <div id="infoNum">0%</div>
            <div id="parent">
              <div id="child"></div>
            </div>
          </div>
          <!-- <img
              src="../../assets/preview.png"
              alt=""
              class="preview"
              url=""
              @click="preview()"
            /> -->
        </div>
        <div class="text">
          <h2>模型信息</h2>
          <ul>
            <li>发布日期：{{ detail.publishTime }}</li>
            <li>模型编号：{{ detail.id }}</li>
            <li>模型大小：{{ detail.size }}</li>
            <li>模型格式：{{ formats }}</li>
            <li>动画：{{ detail.animations == 1 ? "有" : "无" }}</li>
            <li>绑定：{{ detail.bind == 1 ? "有" : "无" }}</li>
            <li>贴图：{{ detail.textarea == 1 ? "有" : "无" }}</li>
            <li>材质：{{ detail.materials == 1 ? "有" : "无" }}</li>
            <li>点：{{ detail.pointNum }}</li>
            <li>面：{{ detail.sideNum }}</li>
          </ul>
          <div class="btn" style="display: none">
            <button class="blue">3D编辑</button>
            <button class="gray">模型编辑</button>
          </div>

          <div class="ul_list">
            <div
              @click="iframe"
              class="iframe"
              id="btn"
              :data-clipboard-text="copyUrl"
            >
              嵌入代码<img src="../../assets/model/lianjie.png" alt="" />
            </div>
            <!-- </router-link> -->
            <div
              class="iframe"
              :data-clipboard-text="furl"
              id="btn1"
              @click="copy()"
            >
              分享链接<img src="../../assets/model/share.png" alt="" />
            </div>
            <div @click="erweima" class="iframe">
              二维码<img src="../../assets/model/ma.png" alt="" />
            </div>

            <div style="display: none">
              导出<img src="../../assets/model/up.png" alt="" />
            </div>
          </div>
        </div>
        <el-dialog
          class="qr_code"
          :visible="show2"
          width="450px"
          @close="close"
        >
          <div>
            <div id="qrCode" ref="qrCode">
              <vue-qr
                :text="imageUrl"
                color-dark="#000"
                color-light="#fff"
                :dot-scale="1"
                :logo-scale="0.2"
              />
            </div>
          </div>
          <div class="el-btn-box">
            <el-button type="primary" @click="getQrCodeImage()">下载</el-button>
            <el-button @click="close">取消</el-button>
          </div>
        </el-dialog>
        <div class="model_footer">
          <img
            :src="url != null ? url : '../assets/personal.png'"
            alt=""
            class="portrait"
            :onerror="defal"
          />
          <div class="user_name">{{ username }}</div>
          <div class="follow">
            <div class="icon3" @click="icon3">
              <img :src="guanzhu" alt="" />{{ guanzhu_text }}
            </div>
            <div class="icon1">
              <img src="../../assets/model/icon1.png" alt="" />{{ browseNum }}
            </div>
            <div class="icon2" @click="icon2">
              <img :src="dianzan" alt="" />{{ likeNum }}
            </div>
          </div>
        </div>
      </div>
      <div class="describe">
        <div class="header">
          <span>模型描述</span>
          <div class="describe_text">
            {{ detail.comment }}
          </div>
        </div>
      </div>
      <div class="describe comment">
        <div class="header">
          <span>评论</span>
          <el-input
            type="textarea"
            rows="5"
            placeholder="我来说两句"
            v-model="textarea"
          >
          </el-input>
          <button class="gray" @click="postMsg">发布</button>
        </div>
      </div>
      <MainComment
        v-for="(comment, index) in msgList"
        :key="index"
        :commentData="comment"
      />
      <div class="msg_bottom"></div>
      <div class="paginationClass">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="page_current"
          hide-on-single-page
          :page-sizes="[10, 20, 30, 40]"
          :page-size="100"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import axios from "../../../untils/request.js";
import axi from "axios";
import { VueJsonp } from "vue-jsonp";
import qs from "qs";
import cookie from "../../../public/js/cookie.js";
import MainComment from "../model/mainComment.vue";
import { OrbitControls } from "../../../public/js/OrbitControls.js";
import { GLTFLoader } from "../../../public/js/GLTFLoader.js";
import { DRACOLoader } from "../../../public/js/DRACOLoader.js";
import { RGBELoader } from "../../../public/js/RGBELoader.js";
import VueQr from "vue-qr";
import Clipboard from "clipboard";
import * as THREE from "three";
import NProgress from "nprogress"; // 导入 nprogress模块
import "nprogress/nprogress.css"; // 导入样式，否则看不到效果
import { MaterialEdit } from "../../../public/js/materialEditor.js";
import jsonp from "jsonp";
export default {
  name: "modelDetail",
  //import引入的组件需要注入到对象中才能使用
  components: { MainComment, VueQr },

  //父组件传入子组件数据
  props: {},

  //格式化数据
  filters: {},

  data() {
    //这里存放数据
    return {
      //
      group: "",
      guanzhu_text: "关注",
      page_current: 0,
      guanzhu: require("../../assets/guangzhu.png"),
      size: 0,
      total: 0,
      textarea: "",
      params: "",
      detail: [],
      timer: null,
      reportUrl: "/demo1.html",
      defal: 'this.src="' + require("../../assets/personal.png") + '"',
      mesh: null,
      camera: null,
      scene: null,
      renderer: null,
      controls: null,
      loading1: false,
      operat: [],
      visitUserId: cookie.get("userId"),
      userId: "",
      st: "",
      likeStatus: "",
      msgList: [],
      dianzan: require("../../assets/model/icon2.png"),
      imageUrl: window.location.href,
      textareaList: [],
      show: "",
      show2: false,
      browseNum: "",
      likeNum: "",
      url: "",
      username: "",
      copyUrl: "",
      furl: "",
      detailList: "",
      error: "",
      show1: false,
      show3: false,
      show4: false,
      mixer: null,
      clock: new THREE.Clock(),
      num: "",
      a: 0,
      formats: "",
      json: "",
      state: "",
      password: "",
      iframesrc: "",
      getpassword: "",
      getname: "",
      fullscreen: false,
      modelSize:''
    };
  },
  //计算属性 类似于data概念
  computed: {},

  //监控data中的数据变化
  watch: {},

  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    NProgress.configure({
      minimum: 0.01, // 配置开始初始值，默认0.08
      template: `
      <div class="bar" id="info" role="bar">
            <div class="peg"></div>
        </div>
    `, // 配置模板
      easing: "linear", // 配置动画
      speed: 200, //配置速度
      trickle: true, // 是否让进度条自动增加
      trickleSpeed: 50, // 配置自动前进的频率ms
      showSpinner: false, //是否显示加载动画，如下图
      parent: "#container1", //配置挂载元素，即相对定位的元素
    });
    this.params = this.$route.query;
    // this.getDetail();

    this.getType();
    this.group = new THREE.Group();
    this.iframesrc = "https://meta.wiiboox.net:3030/edit/texture" + "?model=" + this.params.id + ( this.params.accuracyLevel ? "&accuracyLevel=" +  this.params.accuracyLevel : "")
  
  },

  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    const iframe = document.getElementById('container')
    setTimeout(() => {
      console.log(this.modelSize)
      if(this.detail.size.indexOf('MB') != -1){
        this.detail.size.substring(0,this.detail.size.length-2)
        console.log('有', this.detail.size.substring(0,this.detail.size.length-2))
        this.modelSize = this.detail.size.substring(0,this.detail.size.length-2) * 1024 * 1024
      }else if (this.detail.size.indexOf('KB') != -1){
        this.modelSize = this.detail.size.substring(0,this.detail.size.length-2) * 1024 
      } else if (this.detail.size.indexOf('B') != -1) {
        this.modelSize = this.detail.size.substring(0,this.detail.size.length-1)
      } else{
        
      }
      if(this.modelSize < 10000000){
        setTimeout(() => {
        iframe.style.width = '100%'
        iframe.style.height = '100%'
      }, 2000);
    } else if( 10000000 < this.modelSize && this.modelSize < 25000000) {
      setTimeout(() => {
        console.log('6666')
        iframe.style.width = '100%'
        iframe.style.height = '100%'
      }, 3500);
    } else if( 25000000 < this.modelSize  && this.modelSize < 100000000) {
      console.log(this.modelSize)
      setTimeout(() => {
        iframe.style.width = '100%'
        iframe.style.height = '100%'
      }, 7000);
    } else if(this.changeSym(this.detail.sideNum) > 100000000){
      setTimeout(() => {
        iframe.style.width = '100%'
        iframe.style.height = '100%'
      }, 18000);
    }
      // iframe.style.width = '100%'
      // iframe.style.height = '100%'
      // console.log(this.changeSym(this.detail.sideNum))
    }, 1000);
    
  },

  //方法集合
  methods: {
    //关注
    //去逗号
    changeSym(num) {
      if (typeof num == 'number'){
        num = num.toString()
      }
      return num.split(',').join('')
    },
    getguanzhu() {
      let headers = {};
      if (cookie.get("token") == undefined) {
        headers = { "Content-type": "application/x-www-form-urlencoded" };
      } else {
        headers = {
          "Content-type": "application/x-www-form-urlencoded",
          Authorization: "Bearer " + cookie.get("token"),
        };
      }
      axios
        .get(
          "/wiiboox-biz/tUserSubscribe/getSubscribeStatus?userId=" +
            this.userId +
            "&visitUserId=" +
            this.visitUserId,
          {
            headers: headers,
          }
        )
        .then((res) => {
          if (res.data == false) {
            this.guanzhu = require("../../assets/guangzhu.png");
            this.guanzhu_text = "关注";
          } else {
            this.guanzhu = require("../../assets/model/icon3.png");
            this.guanzhu_text = "已关注";
          }
          // this.guanzhu = require("../../assets/model/icon3.png")
          // this.guanzhu_text = '已关注'
        })
        .catch((err) => {});
    },
    icon3() {
      let headers = {};
      if (cookie.get("token") == undefined) {
        headers = { "Content-type": "application/x-www-form-urlencoded" };
      } else {
        headers = {
          "Content-type": "application/x-www-form-urlencoded",
          Authorization: "Bearer " + cookie.get("token"),
        };
      }
      axios
        .get("/wiiboox-biz/tUserSubscribe/subscribe/?userId=" + this.userId, {
          headers: headers,
        })
        .then((res) => {
          this.getguanzhu();
        })
        .catch((err) => {
          this.$confirm("登陆后才可关注, 是否继续?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              this.$router.push({ name: "login" });
            })
            .catch(() => {});
        });
    },
    encode(str) {
      return btoa(
        encodeURIComponent(str).replace(
          /%([0-9A-F]{2})/g,
          function toSolidBytes(match, p1) {
            return String.fromCharCode("0x" + p1);
          }
        )
      );
    },
    copy() {
      const Message = this.$message;
      var clipBoard = new Clipboard("#btn1");
      clipBoard.on("success", function () {
        clipBoard.destroy(); // 销毁上一次的复制内容
        clipBoard = new Clipboard("#btn1");
        Message.success("复制成功，您通过微信、QQ等工具分享给好友");
      });
      clipBoard.on("error", function () {
        Message.info("复制失败，请手动复制");
      });
    },
    iframe() {
      const Message = this.$message;
      var clipBoard = new Clipboard("#btn");
      clipBoard.on("success", function () {
        clipBoard.destroy(); // 销毁上一次的复制内容
        clipBoard = new Clipboard("#btn");
        Message.success("复制成功，您可以将此代码引用在网页中");
      });
      clipBoard.on("error", function () {
        Message.info("复制失败，请手动复制");
      });
    },
    //展示二维码
    close() {
      this.show2 = false;
    },
    // 获取二维码图片
    getQrCodeImage() {
      var canvasData = document
        .getElementById("qrCode")
        .getElementsByTagName("img");
      var a = document.createElement("a");
      a.href = canvasData[0].src;
      a.download = "二维码.png";
      a.click();
    },
    erweima() {
      this.show2 = true;
    },
    //test
    //评论分页
    handleSizeChange(val) {
      let headers = {};
      if (cookie.get("token") == undefined) {
        headers = { "Content-type": "application/x-www-form-urlencoded" };
      } else {
        headers = {
          "Content-type": "application/x-www-form-urlencoded",
          Authorization: "Bearer " + cookie.get("token"),
        };
      }
      this.size = val;
      axios
        .get(
          "/wiiboox-biz/tUserReplay/getReplayPage?objId=" +
            this.params.id +
            "&size=" +
            this.size +
            "&current=" +
            this.page_current,
          {
            headers: headers,
          }
        )
        .then((res) => {
          this.msgList = res.data.records;
          this.page_current = res.data.current;
          this.size = res.data.size;
          this.total = res.data.total;
        });
    },
    handleCurrentChange(val) {
      let headers = {};
      if (cookie.get("token") == undefined) {
        headers = { "Content-type": "application/x-www-form-urlencoded" };
      } else {
        headers = {
          "Content-type": "application/x-www-form-urlencoded",
          Authorization: "Bearer " + cookie.get("token"),
        };
      }
      this.page_current = val;
      axios
        .get(
          "/wiiboox-biz/tUserReplay/getReplayPage?objId=" +
            this.params.id +
            "&size=" +
            this.size +
            "&current=" +
            this.page_current,
          {
            headers: headers,
          }
        )
        .then((res) => {
          this.msgList = res.data.records;
          this.page_current = res.data.current;
          this.size = res.data.size;
          this.total = res.data.total;
        });
    },
    //回复评论
    postReply(i, item) {
      let headers = {};
      if (cookie.get("token") == undefined) {
        headers = { "Content-type": "application/json" };
      } else {
        headers = {
          "Content-type": "application/json",
          Authorization: "Bearer " + cookie.get("token"),
        };
      }
      let data = {
        action: 2,
        comment: this.textareaList[i],
        objId: this.params.id,
        parentId: item.id,
        type: 2,
      };
      axios
        .post("/wiiboox-biz/tUserReplay/replay", data, {
          headers: headers,
        })
        .then((res) => {});
    },
    msgBox(i) {
      this.show = i;
    },
    //点赞
    icon2() {
      if (this.likeStatus == 0) {
        this.likeStatus = 1;
        this.dianzan = require("../../assets/zan.png");
      } else {
        this.likeStatus = 0;
        this.dianzan = require("../../assets/model/icon2.png");
      }
      let data = {
        browseStatus: 0,
        collectStatus: 0,
        likeStatus: this.likeStatus,
        objType: 1,
        objId: this.params.id,
      };
      this.operate(data);
    },
    preview() {
      // window.open(
      //   "http://meta.wiiboox.net:3030/edit/texture" +
      //     "?model=" +
      //     this.params.id +
      //     "&accuracyLevel=" +
      //     this.params.accuracyLevel
      // );
      var full = document.getElementById("container");
      console.log(full)
      this.launchIntoFullscreen(full);
    },
    //全屏封装
    launchIntoFullscreen(element) {
      if (element.requestFullscreen) {
        element.requestFullscreen();
      } else if (element.mozRequestFullScreen) {
        element.mozRequestFullScreen();
      } else if (element.webkitRequestFullscreen) {
        element.webkitRequestFullscreen();
      } else if (element.msRequestFullscreen) {
        element.msRequestFullscreen();
      } else {
        var requestFullscreen =
          document.documentElement.requestFullscreen ||
          document.documentElement.webkitRequestFullscreen ||
          document.documentElement.mozRequestFullscreen ||
          document.documentElement.requestFullScreen ||
          document.documentElement.webkitRequestFullScreen ||
          document.documentElement.mozRequestFullScreen;

        if (requestFullscreen) {
          requestFullscreen.call(document.documentElement);
        } else {
          console.log("really!? come on...");
        }
      }
    },
    //退出全屏封装
    exitFullscreen() {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      }
    },
    getmaterial() {
      let headers = {};
      if (cookie.get("token") == undefined) {
        headers = { "Content-type": "application/x-www-form-urlencoded" };
      } else {
        headers = {
          "Content-type": "application/x-www-form-urlencoded",
          Authorization: "Bearer " + cookie.get("token"),
        };
      }
      axios
        .get("/wiiboox-biz/tModelPrivacy/getPrivacyInfo?id=" + this.params.id, {
          headers: headers,
        })
        .then((res) => {
          this.getpassword = res.data.password;
        });
      axios
        .get(
          "/wiiboox-biz/tModelProject/getAssets?id=" +
            this.params.id +
            (this.params.accuracyLevel
              ? "&accuracyLevel=" + this.params.accuracyLevel
              : ""),
          {
            headers: headers,
          }
        )
        .then((res) => {
          let name = this.returnName(res[0].modelsName);
          let a = res[0].configUrl.replace("config", name);
          axi.get(a).then((r) => {
            this.json = r.data;
          });
        });
    },
    returnName(data) {
      let index = data.lastIndexOf(".");
      if (index <= 0) {
        return null;
      } else {
        return data.slice(0, index);
      }
    },
    show4click() {
      let headers = {};
      if (cookie.get("token") == undefined) {
        headers = { "Content-type": "application/json" };
      } else {
        headers = {
          "Content-type": "application/json",
          Authorization: "Bearer " + cookie.get("token"),
        };
      }
      let data = {
        id: this.params.id,
        password: this.password,
      };
      if (this.password != "") {
        axios
          .post("/wiiboox-biz/tModelProject/verifyPrivacyPassword", data, {
            headers: headers,
          })
          .then((res) => {
            if (res.code == 200 && res.data == true) {
              this.show4 = false;
              cookie.set(this.params.id, this.password);
              document.getElementById("container").src =
                "https://meta.wiiboox.net:3030/edit/texture" +
                "?model=" +
                this.params.id +
                (this.params.accuracyLevel
                  ? "&accuracyLevel=" + this.params.accuracyLevel
                  : "");
              this.getDetail();
            } else {
              this.$message(res.message);
            }
          });
      } else {
        this.$message("请输入密码");
      }
    },

    getType() {
      let headers = {};
      if (cookie.get("token") == undefined) {
        headers = { "Content-type": "application/x-www-form-urlencoded" };
      } else {
        headers = {
          "Content-type": "application/x-www-form-urlencoded",
          Authorization: "Bearer " + cookie.get("token"),
        };
      }
      axios
        .get(
          "/wiiboox-biz/tModelProject/verifyModelView?id=" + this.params.id,
          {
            headers: headers,
          }
        )
        .then((res) => {
          if (
            res.code == 200 &&
            res.data == true &&
            cookie.get(this.params.id) == undefined
          ) {
            this.show4 = true;
            this.error = "";
          }
          if (
            res.code == 200 &&
            res.data == true &&
            cookie.get(this.params.id) != undefined
          ) {
            this.getmaterial();
            this.getDetail();

            document.getElementById("container").src =
              "https://meta.wiiboox.net:3030/edit/texture" +
              "?model=" +
              this.params.id +
              (this.params.accuracyLevel
                ? "&accuracyLevel=" + this.params.accuracyLevel
                : "");
          } else if (res.code == 200 && res.data == false) {
            this.getmaterial();
            this.getDetail();
            document.getElementById("container").src =
              "https://meta.wiiboox.net:3030/edit/texture" +
              "?model=" +
              this.params.id +
              (this.params.accuracyLevel
                ? "&accuracyLevel=" + this.params.accuracyLevel
                : "");
          } else if (res.code == 500) {
            this.show1 = false;
            this.show3 = true;
            this.error = res.message;
          }
        });
    },
    getDetail() {
      let headers = {};
      if (cookie.get("token") == undefined) {
        headers = { "Content-type": "application/json" };
      } else {
        headers = {
          "Content-type": "application/json",
          Authorization: "Bearer " + cookie.get("token"),
        };
      }

      this.copyUrl =
        "<iframe height='498' width='510' src=\"" +
        "https://meta.wiiboox.net:3030/edit/texture" +
        "?model=" +
        this.params.id +
        (this.params.accuracyLevel
          ? "&accuracyLevel=" + this.params.accuracyLevel
          : "") +
        '" frameborder=0 allowfullscreen></iframe>';
      let data = {
        accuracyLevel: this.params.accuracyLevel
          ? this.params.accuracyLevel
          : 7,
        id: this.params.id,
        password: cookie.get(this.params.id),
      };
      axios
        .post("/wiiboox-biz/tModelProject/detail", data, {
          headers: headers,
        })
        .then((res) => {
          if (res.code == 500) {
            this.show1 = false;
            this.show3 = true;
            this.error = res.message;
          } else {
            this.show1 = true;
            this.show3 = false;
            this.getAll();
            this.getLike();
            this.getguanzhu();
            this.getUser();
            this.detail = res.data;
            this.getname = res.data.name;
            this.formats = res.data.formats.toString();
            this.detailList = res.data.userInfo;
            this.userId = this.detailList.id;
            this.username = this.detailList.nickname;
            this.furl =
              "模型名称：" +
              this.getname +
              "\n" +
              window.location.href +
              "\n" +
              (this.getpassword != "" ? "分享密码：" + this.getpassword : "");
            this.url = this.detailList.icon;
            this.userId = this.detailList.id;
            this.detail.pointNum = this.detail.pointNum.toLocaleString();
            this.detail.sideNum = this.detail.sideNum.toLocaleString();
            // if(this.detail.size)
            let reg = new RegExp("MB");
            let a = this.detail.size.replace(reg, "");
            if (a < 10) {
              NProgress.settings.trickleSpeed = 50;
            } else if (100 > a > 50) {
              NProgress.settings.trickleSpeed = 1000;
            } else if (a > 100) {
              NProgress.settings.trickleSpeed = 5000;
            }
            // NProgress.settings.trickleSpeed
            if (this.show1) {
              return;
              this.init();
              NProgress.start();
              this.setTimer();
              var info = document.getElementById("info");
              var infoNum = document.getElementById("infoNum");
              var child = document.getElementById("child");
              var child1 = document.getElementsByClassName("bar");
              info.innerText = "loading...";
              infoNum.innerText =
                Math.round(Math.floor(this.timer * 100), 2) + "%";
              child.style.width =
                Math.round(Math.floor(this.timer * 100), 2) + "%";
            }
          }
        })
        .catch(() => {
          this.url = require("../../assets/personal.png");
        });
    },
    setTimer() {
      let timer = null;
      this.timer = NProgress.status;
      if (NProgress.status > 0.99) {
        infoNum.innerText = 99 + "%";
        child.style.width = 99 + "%";
        // NProgress.done();
      }
      infoNum.innerText = Math.round(Math.floor(this.timer * 100), 2) + "%";
      child.style.width = Math.round(Math.floor(this.timer * 100), 2) + "%";
      if (this.loading1 == true) {
        timer = setTimeout(() => {
          this.setTimer();
        }, 1000);
      } else {
        clearTimeout(timer); //清理定时任务
      }
    },
    browse() {
      let data = {
        // browseStatus: 1,
        collectStatus: 0,
        likeStatus: this.likeStatus,
        objType: 1,
        objId: this.params.id,
      };
      this.operate(data);
    },
    getLike() {
      let headers = {};
      if (cookie.get("token") == undefined) {
        headers = { "Content-type": "application/json" };
      } else {
        headers = {
          "Content-type": "application/json",
          Authorization: "Bearer " + cookie.get("token"),
        };
      }
      axios
        .get(
          "/wiiboox-biz/tUserBehavior/getUserBehavior?objId=" + this.params.id,
          {
            headers: headers,
          }
        )
        .then((res) => {
          this.likeStatus = res.data.likeStatus;
          this.browseNum = res.data.browseNum;
          this.likeNum = res.data.likeNum;
          if (this.likeStatus == 1) {
            this.dianzan = require("../../assets/zan.png");
          }
          // this.browse();
        });
    },
    operate(data) {
      let headers = {};
      if (cookie.get("token") == undefined) {
        headers = { "Content-type": "application/json" };
      } else {
        headers = {
          "Content-type": "application/json",
          Authorization: "Bearer " + cookie.get("token"),
        };
      }
      axios
        .post("/wiiboox-biz/tUserBehavior/operate", data, {
          headers: headers,
        })
        .then((res) => {
          this.operat = res.data;
          this.browseNum = this.operat.browseNum;
          this.likeNum = this.operat.likeNum;
        });
    },
    init() {
      this.createScene(); // 创建场景
      // this.new()
      // this.loadRgb();
      this.loadGLTF(); // 加载GLTF模型
      this.createLight(); // 创建光源
      this.createCamera(); // 创建相机
      this.createRender(); // 创建渲染器
      // 创建控件对象
      this.render(); // 渲染
    },
    // 创建场景
    createScene() {
      this.scene = new THREE.Scene();
    },
    loadRgb() {
      //加载hdr

      var that = this;
      // 使用hdr作为背景色
      var pmremGenerator = new THREE.PMREMGenerator(this.renderer);
      pmremGenerator.compileEquirectangularShader(); //阴影
      new RGBELoader()
        .setDataType(THREE.UnsignedByteType)
        .load("/public/luori.hdr", (texture) => {
          const envMap = pmremGenerator.fromEquirectangular(texture).texture;
          this.scene.environment = envMap; // 给场景添加环境光效果
          //this.scene.background = envMap；// 给场景添加背景图
          pmremGenerator.dispose();
        });
    },
    new() {
      const pmremGenerator = new THREE.PMREMGenerator(this.renderer);

      pmremGenerator.compileEquirectangularShader();
      new RGBELoader()

        .setDataType(THREE.UnsignedByteType)
        .load("/room.hdr", (texture) => {
          const envMap = pmremGenerator.fromEquirectangular(texture).texture;

          this.scene.background = envMap;

          this.scene.environment = envMap;

          texture.dispose();

          pmremGenerator.dispose();
        });
    },
    loadGLTF() {
      // const loader = new GLTFLoader();
      // // loader.load(this.detail.previewUrl);
      // const dracoLoader = new DRACOLoader();
      // dracoLoader.setDecoderPath("/draco/");
      // dracoLoader.preload();
      // loader.setDRACOLoader(dracoLoader);
      // loader.setCrossOrigin("anonymous");
      // new RGBELoader().setDataType(THREE.UnsignedByteType).load("/luori.hdr", (texture)=> {
      //   texture.mapping = THREE.EquirectangularReflectionMapping;
      //   this.scene.background = texture;
      //   this.scene.environment = texture;
      this.detail.previewUrls.forEach((item, index) => {
        this.getload(item, index);
      });
    },
    getload(b, index) {
      if (this.detail.previewUrls.length < 2) {
        b = this.detail.previewUrls[0];
      }
      const manager = new THREE.LoadingManager();

      manager.onProgress = function (item, loaded, total) {};
      const loader = new GLTFLoader(manager);
      // loader.load(this.detail.previewUrl);
      const dracoLoader = new DRACOLoader();
      dracoLoader.setDecoderPath("/draco/");
      dracoLoader.preload();
      loader.setDRACOLoader(dracoLoader);
      loader.setCrossOrigin("anonymous");
      loader.load(
        // this.detail.previewUrls[0],
        b,
        (gltf) => {
          let self = this;
          const element = document.getElementById("container");
          const width = element.clientWidth; // 窗口宽度
          const height = element.clientHeight; // 窗口高度
          let x, y, z, i, m;
          i = height / width;
          let bbox = new THREE.Box3().setFromObject(gltf.scene);
          x = bbox.max.x - bbox.min.x;
          y = bbox.max.y - bbox.min.y;
          z = bbox.max.z - bbox.min.z;
          // x = -(bbox.min.x + bbox.max.x) * 0.5;
          // y = -(bbox.min.y + bbox.max.y) * 0.5;
          // z = -(bbox.min.z + bbox.max.z) * 0.5;
          // this.scene.child
          if (y / x >= i) {
            let h = y;
            let Fov = (this.camera.fov * Math.PI) / 240;
            m = h / (2 * Math.tan(Fov * 1));
            this.camera.position.y = 0;
            this.camera.position.z = 2 * m + z / 2;
            this.camera.position.x = 0;
          } else {
            let w = x;
            let h = w * i;
            let Fov = (this.camera.fov * Math.PI) / 240;
            m = h / (2 * Math.tan(Fov * 1));
            this.camera.position.y = 0;
            this.camera.position.z = 2 * m + z / 2;
            this.camera.position.x = 0;
          }
          var mesh = gltf.scene;
          if (gltf.animations.length > 0) {
            self.mixer = new THREE.AnimationMixer(mesh);
            var action = self.mixer.clipAction(gltf.animations[0]);
            action
              .stop()
              // .setDuration(5)//这个为播放速度
              .play();
          }
          this.group.position.set(
            -(bbox.max.x + bbox.min.x) / 2,
            -(bbox.max.y + bbox.min.y) / 2,
            -(bbox.max.z + bbox.min.z) / 2
          );
          // gltf.scene.children[0].color = this.json.materialInfos[0].color
          let that = this;
          // for (let i = 0; i < this.json.length; i++) {
          // console.log(i)
          // }
          // gltf.parser.json.materials = this.json.materialInfos;
          let meshId = 0;
          let tempArr = [];
          var textureLoad = new THREE.TextureLoader();
          var gltf_json_materials = this.json;
          let modelId = "1560099067439136769&&yqz97786";
          let options = {};
          options.modelInfo = [];
          options.modelInfo.push(that.json);
          for (let i = 0; i < options.modelInfo.length; i++) {
            if (
              options.modelInfo[i].materialInfos &&
              options.modelInfo[i].materialInfos.length > 0
            ) {
              this.getMaterialDataFromOptions(
                options.modelInfo[i].materialInfos,
                options.modelInfo[i].assetid
              );
            }
          }
          // if (this.json.materialInfos != undefined) {
          //   gltf.scene.traverse(function (node) {
          //     return
          //     if (node.isMesh) {
          //       node.material.depthWrite = true;
          //       node.material.transparent = false;
          //       //	node.material.envMapIntensity = 1
          //       node.material.vertexColors = false;
          //       if (node.material.name != null && gltf_json_materials) {
          //         for (let i = 0; i < gltf_json_materials.length; i++) {
          //           if (gltf_json_materials[i].name == node.material.name) {
          //             if (
          //               gltf_json_materials[i].alphaMode != undefined &&
          //               gltf_json_materials[i].alphaMode == "BLEND"
          //             ) {
          //               node.material.transparent = true;
          //               if (
          //                 gltf_json_materials[i].pbrMetallicRoughness !=
          //                   undefined &&
          //                 gltf_json_materials[i].pbrMetallicRoughness
          //                   .baseColorFactor &&
          //                 gltf_json_materials[i].pbrMetallicRoughness
          //                   .baseColorFactor[3] < 1
          //               ) {
          //                 node.material.depthWrite = false;
          //               } else node.material.depthWrite = true;
          //             } else node.material.transparent = false;

          //             if (gltf_json_materials[i].doubleSided) {
          //               node.material.side = DoubleSide;
          //             }
          //             break;
          //           }
          //         }
          //       }

          //       //node.material.depthWrite = true;

          //       if (envMap) {
          //         node.material.envMap = envMap;
          //         //console.log("环境贴图：",node.material.envMap,node.material)
          //         node.material.envMapIntensity = self.state.envMapIntensity;
          //         node.material.needsUpdate = true;
          //       } else {
          //         node.material.envMapIntensity = 0;
          //       }

          //       if (node.isSkinnedMesh) {
          //         node.material.skinning = true;
          //         //console.log("修改了",node.material)
          //       }

          //       tempArr.push(self.getHashFromMesh(node));

          //       if (node.material.map && node.material.map) {
          //         let texture = parser.associations.get(node.material.map);
          //         node.material.mapindex = texture.index;
          //       }
          //       if (node.material.metalnessMap && node.material.metalnessMap) {
          //         let texture = parser.associations.get(
          //           node.material.metalnessMap
          //         );
          //         node.material.metalnessMapindex = texture.index;
          //       }
          //       if (node.material.roughnessMap && node.material.roughnessMap) {
          //         let texture = parser.associations.get(
          //           node.material.roughnessMap
          //         );
          //         node.material.roughnessMapindex = texture.index;
          //       }
          //       if (node.material.normalMap && node.material.normalMap) {
          //         let texture = parser.associations.get(
          //           node.material.normalMap
          //         );
          //         node.material.normalMapindex = texture.index;
          //       }
          //       if (node.material.aoMap && node.material.aoMap) {
          //         let texture = parser.associations.get(node.material.aoMap);
          //         node.material.aoMapindex = texture.index;
          //       }
          //       if (node.material.emissiveMap && node.material.emissiveMap) {
          //         let texture = parser.associations.get(
          //           node.material.emissiveMap
          //         );
          //         node.material.emissiveMapindex = texture.index;
          //       }
          //     }
          //     // if (child.isMesh) {

          //     //   // child.material.color.set(that.json.materialInfos[0].color);
          //     //   // child.material.emissive.set(
          //     //   //   that.json.materialInfos[0].emissive
          //     //   // );
          //     //   // textureLoad.load(
          //     //   //   that.json.materialInfos[0].map,
          //     //   //   function (texture) {}
          //     //   // );

          //     // }
          //   });
          // }
          this.group.add(gltf.scene);
          this.a++;
          this.num = (100 / this.detail.previewUrls.length) * this.a;
          var info = document.getElementById("info");
          var infoNum = document.getElementById("infoNum");
          var child = document.getElementById("child");
          var child1 = document.getElementsByClassName("bar");
          info.innerText = "loading...";
          // infoNum.innerText = Math.round(this.timer, 2) + "%";
          // child.style.width = Math.round(this.timer, 2) + "%";
          if (index == this.detail.previewUrls.length - 1) {
            this.scene.add(this.group);
            this.loading1 = false;
            NProgress.done();
            this.traverseNodes(
              gltf.scene,
              gltf.parser,
              gltf.parser.json.materials,
              null,
              modelId
            );
          }
          this.createControls();

          // NProgress.done();
        },
        (xhr) => {
          // if (this.detail.previewUrls.length < 2) {
          //   var percentComplete = (xhr.loaded / xhr.total) * 100;
          //   if (percentComplete == 100) {
          //     percentComplete = 80;
          //   }
          //   var percent = document.getElementById("percent");
          //   var info = document.getElementById("info");
          //   var infoNum = document.getElementById("infoNum");
          //   var child = document.getElementById("child");
          //   info.innerText = "loading...";
          //   infoNum.innerText = Math.round(percentComplete, 2) + "%";
          //   child.style.width = Math.round(percentComplete, 2) + "%";
          // }
        },
        (error) => {}
      );
    },
    getHashFromMesh(mesh) {
      let res = {
        hash: "",
        mesh: mesh,
      };
      if (!mesh || !mesh.isMesh) return res;
      res.hash += mesh.geometry.index.count;
      res.hash += mesh.geometry.attributes.position.count;
      res.hash += Math.floor(mesh.position.x * 1000);
      res.hash += Math.floor(mesh.position.y * 1000);
      res.hash += Math.floor(mesh.position.z * 1000);
      res.hash += Math.floor(mesh.quaternion.x * 10);
      res.hash += Math.floor(mesh.quaternion.y * 10);
      res.hash += Math.floor(mesh.quaternion.z * 10);
      res.hash += Math.floor(mesh.scale.x * 1000);
      res.hash += Math.floor(mesh.scale.y * 1000);
      res.hash += Math.floor(mesh.scale.z * 1000);
      return res;
    },
    //加载
    traverseNodes(scene, parser, gltf_json_materials, envMap, modelId) {
      let meshId = 0;
      let tempArr = [];

      scene.traverse((node) => {
        if (node instanceof THREE.Mesh) {
          node.material.depthWrite = true;
          node.material.transparent = false;
          //	node.material.envMapIntensity = 1
          node.material.vertexColors = false;
          if (node.material.name != null && gltf_json_materials) {
            for (let i = 0; i < gltf_json_materials.length; i++) {
              if (gltf_json_materials[i].name == node.material.name) {
                if (
                  gltf_json_materials[i].alphaMode != undefined &&
                  gltf_json_materials[i].alphaMode == "BLEND"
                ) {
                  node.material.transparent = true;
                  if (
                    gltf_json_materials[i].pbrMetallicRoughness != undefined &&
                    gltf_json_materials[i].pbrMetallicRoughness
                      .baseColorFactor &&
                    gltf_json_materials[i].pbrMetallicRoughness
                      .baseColorFactor[3] < 1
                  ) {
                    node.material.depthWrite = false;
                  } else node.material.depthWrite = true;
                } else node.material.transparent = false;

                if (gltf_json_materials[i].doubleSided) {
                  node.material.side = "DoubleSide";
                }
                break;
              }
            }
          }

          //node.material.depthWrite = true;
          if (envMap) {
            node.material.envMap = envMap;
            //console.log("环境贴图：",node.material.envMap,node.material)
            node.material.envMapIntensity = this.state.envMapIntensity;
            node.material.needsUpdate = true;
          } else {
            node.material.envMapIntensity = 0;
          }

          if (node.isSkinnedMesh) {
            node.material.skinning = true;
            //console.log("修改了",node.material)
          }

          tempArr.push(this.getHashFromMesh(node));

          if (node.material.map && node.material.map) {
            let texture = parser.associations.get(node.material.map);
            node.material.mapindex = texture.index;
          }
          if (node.material.metalnessMap && node.material.metalnessMap) {
            let texture = parser.associations.get(node.material.metalnessMap);
            node.material.metalnessMapindex = texture.index;
          }
          if (node.material.roughnessMap && node.material.roughnessMap) {
            let texture = parser.associations.get(node.material.roughnessMap);
            node.material.roughnessMapindex = texture.index;
          }
          if (node.material.normalMap && node.material.normalMap) {
            let texture = parser.associations.get(node.material.normalMap);
            node.material.normalMapindex = texture.index;
          }
          if (node.material.aoMap && node.material.aoMap) {
            let texture = parser.associations.get(node.material.aoMap);
            node.material.aoMapindex = texture.index;
          }
          if (node.material.emissiveMap && node.material.emissiveMap) {
            let texture = parser.associations.get(node.material.emissiveMap);
            node.material.emissiveMapindex = texture.index;
          }
        }
      });

      let newArr = tempArr.sort(function (a, b) {
        return a.hash.localeCompare(b.hash);
      });

      for (var i = 0; i < newArr.length; i++) {
        newArr[i].mesh.meshId = meshId + "_" + modelId;
        meshId++;
      }
    },
    // 创建光源
    createLight() {
      // 环境光
      let ambientLight = new THREE.AmbientLight(0xcccccc, 0.6);
      this.scene.add(ambientLight);
      let a = 1,
        b = 0.6,
        c = 10;
      let directionalLight1 = new THREE.DirectionalLight(0xffffff, b);
      directionalLight1.position.set(-a, -a, a * c).normalize();
      let directionalLight2 = new THREE.DirectionalLight(0xffffff, b);
      directionalLight2.position.set(a, -a, -a * c).normalize();
      let directionalLight3 = new THREE.DirectionalLight(0xffffff, b);
      directionalLight3.position.set(-a, a, -a * c).normalize();
      let directionalLight4 = new THREE.DirectionalLight(0xffffff, b);
      directionalLight4.position.set(a, a, a * c).normalize();
      this.scene.add(directionalLight1);
      this.scene.add(directionalLight2);
      this.scene.add(directionalLight3);
      this.scene.add(directionalLight4);
    },
    async getMaterialDataFromOptions(materialInfos, modelId) {
      for (let m = 0; m < materialInfos.length; m++) {
        var matData = materialInfos[m];

        var newMat = new THREE.MeshStandardMaterial();

        if (matData.flatShading) newMat.flatShading = true;
        else newMat.flatShading = false;
        newMat.name = matData.name;
        newMat.opacity = matData.opacity;
        if (matData.transparent) newMat.transparent = true;
        else newMat.transparent = false;
        newMat.metalness = matData.metalness;
        newMat.roughness = matData.roughness;
        newMat.modelId = modelId;

        newMat.side = matData.side;
        // if(matData.depthWrite){
        //     newMat.depthWrite = true;
        // }
        // else newMat.depthWrite = false;
        newMat.color = new THREE.Color(matData.color);
        newMat.emissive = new THREE.Color(matData.emissive);
        newMat.aoMapIntensity = matData.aoMapIntensity;
        newMat.emissiveIntensity = matData.emissiveMapIntensity
          ? matData.emissiveMapIntensity
          : 0;
        var scale = matData.normalScale.split("_");
        newMat.normalScale = new THREE.Vector2(
          parseFloat(scale[0]),
          parseFloat(scale[1])
        );
        if (matData.visible) newMat.visible = true;
        else newMat.visible = false;

        if (matData.map != null) {
          let url = matData.map;
          //if(matData.map.indexOf(';base64')<0) url = '/auth'+url;
          newMat.map = await this.setSomeMap(newMat.map, url); //从服务器获取的贴图路径都要加 '/auth'
          newMat.map.flipY = false;
          newMat.map.encoding = this.renderer.outputEncoding;

          this.setSomeMapMagFilter(
            newMat.map,
            null,
            matData.mapSetting_MagFilter
          );
          this.setSomeMapMinFilter(
            newMat.map,
            null,
            matData.mapSetting_MinFilter
          );
          this.setSomeMapWraS(newMat.map, null, matData.mapSetting_WrapS);
          this.setSomeMapWraT(newMat.map, null, matData.mapSetting_WrapT);
          if (matData.mapSetting_offset) {
            let offsets = matData.mapSetting_offset.split("_");
            newMat.map.offset = new THREE.Vector2(
              parseFloat(offsets[0]),
              parseFloat(offsets[1])
            );
          }
          if (matData.mapSetting_repeat) {
            let repeats = matData.mapSetting_repeat.split("_");
            newMat.map.repeat = new THREE.Vector2(
              parseFloat(repeats[0]),
              parseFloat(repeats[1])
            );
          }
        }
        if (matData.aoMap != null) {
          let url = matData.aoMap;
          //if(matData.aoMap.indexOf(';base64')<0) url = '/auth'+url;
          newMat.aoMap = await this.setSomeMap(newMat.aoMap, url);
          newMat.aoMap.flipY = false;
          newMat.aoMap.encoding = this.renderer.outputEncoding;
          //newMat.aoMap.repeat.y = -1;
          this.setSomeMapMagFilter(
            newMat.aoMap,
            null,
            matData.aoMapSetting_MagFilter
          );
          this.setSomeMapMinFilter(
            newMat.aoMap,
            null,
            matData.aoMapSetting_MinFilter
          );
          this.setSomeMapWraS(newMat.aoMap, null, matData.aoMapSetting_WrapS);
          this.setSomeMapWraT(newMat.aoMap, null, matData.aoMapSetting_WrapT);
          if (matData.aoMapSetting_offset) {
            let offsets = matData.aoMapSetting_offset.split("_");
            newMat.aoMap.offset = new THREE.Vector2(
              parseFloat(offsets[0]),
              parseFloat(offsets[1])
            );
          }
          if (matData.aoMapSetting_repeat) {
            let repeats = matData.aoMapSetting_repeat.split("_");
            newMat.aoMap.repeat = new THREE.Vector2(
              parseFloat(repeats[0]),
              parseFloat(repeats[1])
            );
          }
        }
        if (matData.emissiveMap != null) {
          let url = matData.emissiveMap;
          //if(matData.emissiveMap.indexOf(';base64')<0) url = '/auth'+url;
          newMat.emissiveMap = await this.setSomeMap(newMat.emissiveMap, url);
          newMat.emissiveMap.encoding = this.renderer.outputEncoding;
          newMat.emissiveMap.flipY = false;
          this.setSomeMapMagFilter(
            newMat.emissiveMap,
            null,
            matData.emissiveMapSetting_MagFilter
          );
          this.setSomeMapMinFilter(
            newMat.emissiveMap,
            null,
            matData.emissiveMapSetting_MinFilter
          );
          this.setSomeMapWraS(
            newMat.emissiveMap,
            null,
            matData.emissiveMapSetting_WrapS
          );
          this.setSomeMapWraT(
            newMat.emissiveMap,
            null,
            matData.emissiveMapSetting_WrapT
          );
          if (matData.emissiveMapSetting_offset) {
            let offsets = matData.emissiveMapSetting_offset.split("_");
            newMat.emissiveMap.offset = new Vector2(
              parseFloat(offsets[0]),
              parseFloat(offsets[1])
            );
          }
          if (matData.emissiveMapSetting_repeat) {
            let repeats = matData.emissiveMapSetting_repeat.split("_");
            newMat.emissiveMap.repeat = new Vector2(
              parseFloat(repeats[0]),
              parseFloat(repeats[1])
            );
          }
        }
        if (matData.metalnessMap != null) {
          let url = matData.metalnessMap;
          //if(matData.metalnessMap.indexOf(';base64')<0) url = '/auth'+url;
          newMat.metalnessMap = await this.setSomeMap(newMat.metalnessMap, url);
          newMat.metalnessMap.flipY = false;
          newMat.metalnessMap.encoding = this.renderer.outputEncoding;
          this.setSomeMapMagFilter(
            newMat.metalnessMap,
            null,
            matData.metalnessMapSetting_MagFilter
          );
          this.setSomeMapMinFilter(
            newMat.metalnessMap,
            null,
            matData.metalnessMapSetting_MinFilter
          );
          this.setSomeMapWraS(
            newMat.metalnessMap,
            null,
            matData.metalnessMapSetting_WrapS
          );
          this.setSomeMapWraT(
            newMat.metalnessMap,
            null,
            matData.metalnessMapSetting_WrapT
          );
          if (matData.metalnessMapSetting_offset) {
            let offsets = matData.metalnessMapSetting_offset.split("_");
            newMat.metalnessMap.offset = new Vector2(
              parseFloat(offsets[0]),
              parseFloat(offsets[1])
            );
          }
          if (matData.metalnessMapSetting_repeat) {
            let repeats = matData.metalnessMapSetting_repeat.split("_");
            newMat.metalnessMap.repeat = new Vector2(
              parseFloat(repeats[0]),
              parseFloat(repeats[1])
            );
          }
        }
        if (matData.normalMap != null) {
          let url = matData.normalMap;
          //if(matData.normalMap.indexOf(';base64')<0) url = '/auth'+url;
          newMat.normalMap = await this.setSomeMap(newMat.normalMap, url);
          //newMat.normalMap.repeat.y = -1;
          newMat.normalMap.flipY = false;
          newMat.normalMap.encoding = this.renderer.outputEncoding;
          this.setSomeMapMagFilter(
            newMat.normalMap,
            null,
            matData.normalMapSetting_MagFilter
          );
          this.setSomeMapMinFilter(
            newMat.normalMap,
            null,
            matData.normalMapSetting_MinFilter
          );
          this.setSomeMapWraS(
            newMat.normalMap,
            null,
            matData.normalMapSetting_WrapS
          );
          this.setSomeMapWraT(
            newMat.normalMap,
            null,
            matData.normalMapSetting_WrapT
          );
          if (matData.normalMapSetting_offset) {
            let offsets = matData.normalMapSetting_offset.split("_");
            newMat.normalMap.offset = new Vector2(
              parseFloat(offsets[0]),
              parseFloat(offsets[1])
            );
          }
          if (matData.normalMapSetting_repeat) {
            let repeats = matData.normalMapSetting_repeat.split("_");
            newMat.normalMap.repeat = new Vector2(
              parseFloat(repeats[0]),
              parseFloat(repeats[1])
            );
          }
        }
        if (matData.roughnessMap != null) {
          let url = matData.roughnessMap;
          // if(matData.roughnessMap.indexOf(';base64')<0) url = '/auth'+url;
          newMat.roughnessMap = await this.setSomeMap(newMat.roughnessMap, url);
          newMat.roughnessMap.flipY = false;
          newMat.roughnessMap.encoding = this.viewer.renderer.outputEncoding;
          this.setSomeMapMagFilter(
            newMat.roughnessMap,
            null,
            matData.roughnessMapSetting_MagFilter
          );
          this.setSomeMapMinFilter(
            newMat.roughnessMap,
            null,
            matData.roughnessMapSetting_MinFilter
          );
          this.setSomeMapWraS(
            newMat.roughnessMap,
            null,
            matData.roughnessMapSetting_WrapS
          );
          this.setSomeMapWraT(
            newMat.roughnessMap,
            null,
            matData.roughnessMapSetting_WrapT
          );
          if (matData.roughnessMapSetting_offset) {
            let offsets = matData.roughnessMapSetting_offset.split("_");
            newMat.roughnessMap.offset = new Vector2(
              parseFloat(offsets[0]),
              parseFloat(offsets[1])
            );
          }
          if (matData.roughnessMapSetting_repeat) {
            let repeats = matData.roughnessMapSetting_repeat.split("_");
            newMat.roughnessMap.repeat = new Vector2(
              parseFloat(repeats[0]),
              parseFloat(repeats[1])
            );
          }
        }

        var mat = {
          modelId: modelId,
          name: newMat.name,
          materialId: this.materialId,
          snapshotUrl: null,
        }; //name：当前材质的名字，materialId：当前材质的id
        // mat.snapshotUrl = this.getSnapshotUrl(newMat);
        // this.materialTree.push(mat);
        //保存材质与mehs的映射
        let matMap = {
          modelId: modelId,
          name: newMat.name,
          materialId: this.materialId,
          material: newMat,
          meshMap: [],
          data: null,
        }; //materialId：当前材质的id，material：模型中的材质数据，meshMap：模型中使用当前材质的所有mesh的id（mesh树中的id）

        let lastMap = {
          materialId: this.materialId,

          map: null,
          mapRepeat: null,
          mapOffset: null,
          mapSetting: new Object(),

          aoMap: null,
          aoMapRepeat: null,
          aoMapOffset: null,
          aoMapSetting: new Object(),

          emissiveMap: null,
          emissiveMapRepeat: null,
          emissiveMapOffset: null,
          emissiveMapSetting: new Object(),

          metalnessMap: null,
          metalnessMapRepeat: null,
          metalnessMapOffset: null,
          metalnessMapSetting: new Object(),

          normalMap: null,
          normalMapRepeat: null,
          normalMapOffset: null,
          normalMapSetting: new Object(),

          roughnessMap: null,
          roughnessMapRepeat: null,
          roughnessMapOffset: null,
          roughnessMapSetting: new Object(),
        };

        this.getMaterialData(newMat, matMap, lastMap, matData);

        // this.materialTreeMap.push(matMap);

        //设置材质和mesh的映射
        if (matData.meshMap && matData.meshMap.length > 0) {
          if (matData.meshMap != null) {
            var ind = matData.meshMap.split("@p_q@");
            //console.log("新建材质",newMat);

            for (let i = 0; i < ind.length; i++) {
              for (var k = 0; k < this.meshTreeMap.length; k++) {
                //console.log(this.meshTreeMap[k].mesh.meshId ,parseInt(ind[i]) + "_" + modelId)
                if (this.meshTreeMap[k].mesh.meshId == ind[i]) {
                  if (this.meshTreeMap[k].mesh.isSkinnedMesh) {
                    newMat.skinning = true;
                    //console.log(newMat);
                  }
                  //else newMat.skinning = false;
                  this.meshTreeMap[k].mesh.material = newMat;
                  this.meshTreeMap[k].mesh.material.needsUpdate = true;
                  this.meshTreeMap[k].materialId = this.materialId;
                  matMap.meshMap.push(this.meshTreeMap[k].mesh.meshId);
                  break;
                }
              }
            }
          }
        }
        newMat.needsUpdate = true;
        this.saveMaterialLastMap.push(lastMap);
        this.materialId++;
      }
    },
    //
    async getMaterialData(
      material,
      materialTreeNode,
      lastMap,
      matData,
      fromModel = false
    ) {
      let data = new Object();

      data.metalness = material.metalness; //金属度
      data.roughness = material.roughness; //粗糙度

      if (material.depthWrite) {
        data.depthWrite = 1;
      } else data.depthWrite = 0;

      data.transparent = material.transparent; //是否半透明
      data.opacity = material.opacity; //不透明度
      data.color = "#" + material.color.getHexString(); //漫反射颜色
      data.map = null; //漫反射贴图
      data.mapSetting = null; //漫反射贴图设置
      if (material.map != null) {
        lastMap.map = material.map.image.currentSrc
          ? material.map.image.currentSrc
          : material.map.source.data.currentSrc;
        lastMap.mapRepeat = material.map.repeat;
        lastMap.mapOffset = material.map.offset;
        lastMap.mapSetting.magFilter = material.map.magFilter;
        lastMap.mapSetting.minFilter = material.map.minFilter;
        lastMap.mapSetting.wrapS = material.map.wrapS;
        lastMap.mapSetting.wrapT = material.map.wrapT;
        if (fromModel) {
          //if(!material.modelId) console.log(material)
          lastMap.map = await this.defineLoadTexture.loadTexture(
            material.modelId,
            material.mapindex
          );
          if (!lastMap.map) lastMap.map = "";
        }
        if (lastMap.map.indexOf(";base64") > -1) {
          //base64
          data.map = lastMap.map;
          data.mapName = "map" + materialTreeNode.materialId;
        } else {
          data.map = this.getMapFileName(lastMap.map);
          let names = lastMap.map.split("/");
          data.mapName = names[names.length - 1];
        }

        //data.map = material.map.image.currentSrc;
        data.mapSetting = this.getMapSetting(material.map);
      }
      data.metalnessMap = null; //金属度贴图
      data.metalnessMapSetting = null; //金属度贴图设置
      if (material.metalnessMap != null) {
        lastMap.metalnessMap = material.metalnessMap.image.currentSrc;
        lastMap.metalnessMapRepeat = material.metalnessMap.repeat;
        lastMap.metalnessMapOffset = material.metalnessMap.offset;
        lastMap.metalnessMapSetting.magFilter = material.metalnessMap.magFilter;
        lastMap.metalnessMapSetting.minFilter = material.metalnessMap.minFilter;
        lastMap.metalnessMapSetting.wrapS = material.metalnessMap.wrapS;
        lastMap.metalnessMapSetting.wrapT = material.metalnessMap.wrapT;
        if (fromModel)
          lastMap.metalnessMap = await this.defineLoadTexture.loadTexture(
            material.modelId,
            material.metalnessMapindex
          );
        if (lastMap.metalnessMap.indexOf(";base64") > -1) {
          //base64
          data.metalnessMap = lastMap.metalnessMap;
          data.metalnessMapName = "metalnessMap" + materialTreeNode.materialId;
        } else {
          data.metalnessMap = this.getMapFileName(lastMap.metalnessMap);
          let names = lastMap.metalnessMap.split("/");
          data.metalnessMapName = names[names.length - 1];
        }

        data.metalnessMapSetting = this.getMapSetting(material.metalnessMap);
      }
      data.roughnessMap = null; //粗糙度贴图
      data.roughnessMapSetting = null; //粗糙度贴图设置
      if (material.roughnessMap != null) {
        lastMap.roughnessMap = material.roughnessMap.image.currentSrc;
        lastMap.roughnessMapRepeat = material.roughnessMap.repeat;
        lastMap.roughnessMapOffset = material.roughnessMap.offset;
        lastMap.roughnessMapSetting.magFilter = material.roughnessMap.magFilter;
        lastMap.roughnessMapSetting.minFilter = material.roughnessMap.minFilter;
        lastMap.roughnessMapSetting.wrapS = material.roughnessMap.wrapS;
        lastMap.roughnessMapSetting.wrapT = material.roughnessMap.wrapT;
        if (fromModel)
          lastMap.roughnessMap = await this.defineLoadTexture.loadTexture(
            material.modelId,
            material.roughnessMapindex
          );
        if (lastMap.roughnessMap.indexOf(";base64") > -1) {
          //base64
          data.roughnessMap = lastMap.roughnessMap;
          data.roughnessMapName = "roughnessMap" + materialTreeNode.materialId;
        } else {
          data.roughnessMap = this.getMapFileName(lastMap.roughnessMap);
          let names = lastMap.roughnessMap.split("/");
          data.roughnessMapName = names[names.length - 1];
        }

        data.roughnessMapSetting = this.getMapSetting(material.roughnessMap);
      }
      data.normalMap = null; //法线贴图
      data.normalMapSetting = null; //法线贴图设置
      data.normalScale = new THREE.Vector2(1, 1); //法线贴图对材质的影响程度 （0-1）
      if (material.normalMap != null) {
        lastMap.normalMap = material.normalMap.image.currentSrc;
        lastMap.normalMapRepeat = material.normalMap.repeat;
        lastMap.normalMapOffset = material.normalMap.offset;
        lastMap.normalMapSetting.magFilter = material.normalMap.magFilter;
        lastMap.normalMapSetting.minFilter = material.normalMap.minFilter;
        lastMap.normalMapSetting.wrapS = material.normalMap.wrapS;
        lastMap.normalMapSetting.wrapT = material.normalMap.wrapT;
        if (fromModel)
          lastMap.normalMap = await this.defineLoadTexture.loadTexture(
            material.modelId,
            material.normalMapindex
          );
        if (lastMap.normalMap.indexOf(";base64") > -1) {
          //base64
          data.normalMap = lastMap.normalMap;
          data.normalMapName = "normalMap" + materialTreeNode.materialId;
        } else {
          data.normalMap = this.getMapFileName(lastMap.normalMap);
          let names = lastMap.normalMap.split("/");
          data.normalMapName = names[names.length - 1];
        }

        data.normalMapSetting = this.getMapSetting(material.normalMap);
        data.normalScale = material.normalScale;
      }
      data.aoMap = null; //ao贴图
      data.aoMapSetting = null; //ao贴图设置
      data.aoMapIntensity = 1; //ao贴图强度
      if (material.aoMap != null) {
        lastMap.aoMap = material.aoMap.image.currentSrc;
        lastMap.aoMapRepeat = material.aoMap.repeat;
        lastMap.aoMapOffset = material.aoMap.offset;
        lastMap.aoMapSetting.magFilter = material.aoMap.magFilter;
        lastMap.aoMapSetting.minFilter = material.aoMap.minFilter;
        lastMap.aoMapSetting.wrapS = material.aoMap.wrapS;
        lastMap.aoMapSetting.wrapT = material.aoMap.wrapT;
        if (fromModel)
          lastMap.aoMap = await this.defineLoadTexture.loadTexture(
            material.modelId,
            material.aoMapindex
          );
        if (lastMap.aoMap.indexOf(";base64") > -1) {
          //base64
          data.aoMap = lastMap.aoMap;
          data.aoMapName = "aoMap" + materialTreeNode.materialId;
        } else {
          data.aoMap = this.getMapFileName(material.aoMap.image.currentSrc);
          let names = lastMap.aoMap.split("/");
          data.aoMapName = names[names.length - 1];
        }

        data.aoMapSetting = this.getMapSetting(material.aoMap);
        data.aoMapIntensity = material.aoMapIntensity;
      }
      data.emissive = "#" + material.emissive.getHexString(); //自发光颜色
      data.emissiveMap = null; //自发光贴图
      data.emissiveMapSetting = null; //自发光贴图设置
      data.emissiveMapIntensity = 1; //自发光贴图强度
      if (material.emissiveMap != null) {
        lastMap.emissiveMap = material.emissiveMap.image.currentSrc;
        lastMap.emissiveMapRepeat = material.emissiveMap.repeat;
        lastMap.emissiveMapOffset = material.emissiveMap.offset;
        lastMap.emissiveMapSetting.magFilter = material.emissiveMap.magFilter;
        lastMap.emissiveMapSetting.minFilter = material.emissiveMap.minFilter;
        lastMap.emissiveMapSetting.wrapS = material.emissiveMap.wrapS;
        lastMap.emissiveMapSetting.wrapT = material.emissiveMap.wrapT;
        if (fromModel)
          lastMap.emissiveMap = await this.defineLoadTexture.loadTexture(
            material.modelId,
            material.emissiveMapindex
          );
        if (lastMap.emissiveMap.indexOf(";base64") > -1) {
          //base64
          data.emissiveMap = lastMap.emissiveMap;
          data.aemissiveMapName = "emissiveMap" + materialTreeNode.materialId;
        } else {
          data.emissiveMap = this.getMapFileName(lastMap.emissiveMap);
          let names = lastMap.emissiveMap.split("/");
          data.emissiveMapName = names[names.length - 1];
        }

        data.emissiveMapSetting = this.getMapSetting(material.emissiveMap);
        data.emissiveMapIntensity = material.emissiveMapIntensity;
      }
      data.wireframe = material.wireframe; //是否是线框模式
      data.side = material.side;
      data.visible = material.visible; //是否可见
      data.flatShading = material.flatShading; //是否使用平面着色
      materialTreeNode.data = data;

      //console.log(material.name,data)
    },
    getMapFileName(src) {
      return src;
      //原本
      //获取贴图url  原始：http://localhost:3000/auth/files/models/Ellen/Ellen_Body_Albedo.jpg
      //结果：/auth/files/models/Ellen/Ellen_Body_Albedo.jpg
      let str = src;
      let pos;
      if (src.indexOf("//") > -1) {
        pos = src.indexOf("//");
        str = src.substr(pos + 2); // 'localhost:3000/auth/files/models/Ellen/Ellen_Body_Albedo.jpg'
      }
      pos = str.indexOf("/");
      str = str.substr(pos + 1); // 'auth/files/models/Ellen/Ellen_Body_Albedo.jpg'
      pos = str.indexOf("/");
      return str.substr(pos); // '/files/models/Ellen/Ellen_Body_Albedo.jpg'

      //现在
      //获取贴图url  原始：http://localhost:3000/auth/files/models/Ellen/Ellen_Body_Albedo.jpg
      //结果：/auth/files/models/Ellen/Ellen_Body_Albedo.jpg
      // let str = src;
      // let pos;
      // if(src.indexOf('//')>-1){
      //     pos = src.indexOf('//');
      //     str =  src.substr(pos + 2); // 'localhost:3000/auth/files/models/Ellen/Ellen_Body_Albedo.jpg'
      // }
      // pos = str.indexOf('/');
      // str = str.substr(pos+1);  // 'auth/files/models/Ellen/Ellen_Body_Albedo.jpg'
      // pos = str.indexOf('/');
      // return str.substr(pos); // '/files/models/Ellen/Ellen_Body_Albedo.jpg'
    },
    //读取贴图的设置参数
    getMapSetting(myMap) {
      let mapSet;

      mapSet = {
        MagFilter: 1,
        MinFilter: 5,
        WrapS: 1,
        WrapT: 1,
        offset: new THREE.Vector2(0, 0),
        repeat: new THREE.Vector2(1, 1),
      };

      if (myMap != null && myMap.magFilter == 1003) {
        mapSet.MagFilter = "Nearest";
      }
      if (myMap != null && myMap.magFilter == 1006) {
        mapSet.MagFilter = "Linear";
      }

      if (myMap != null && myMap.minFilter == 1003) {
        mapSet.MinFilter = "Nearest";
      }
      if (myMap != null && myMap.minFilter == 1004) {
        mapSet.MinFilter = "NearestMipmapNearest";
      }
      if (myMap != null && myMap.minFilter == 1005) {
        mapSet.MinFilter = "NearestMipmapLinear";
      }
      if (myMap != null && myMap.minFilter == 1006) {
        mapSet.MinFilter = "Linear";
      }
      if (myMap != null && myMap.minFilter == 1007) {
        mapSet.MinFilter = "LinearMipmapNearest";
      }
      if (myMap != null && myMap.minFilter == 1008) {
        mapSet.MinFilter = "LinearMipmapLinear";
      }

      if (myMap != null && myMap.wrapS == 1000) {
        mapSet.WrapS = "Repeat";
      }
      if (myMap != null && myMap.wrapS == 1001) {
        mapSet.WrapS = "ClampToEdge";
      }
      if (myMap != null && myMap.wrapS == 1002) {
        mapSet.WrapS = "MirroredRepeat";
      }

      if (myMap != null && myMap.wrapT == 1000) {
        mapSet.WrapT = "Repeat";
      }
      if (myMap != null && myMap.wrapT == 1001) {
        mapSet.WrapT = "ClampToEdge";
      }
      if (myMap != null && myMap.wrapT == 1002) {
        mapSet.WrapT = "MirroredRepeat";
      }
      if (myMap != null) {
        mapSet.offset = myMap.offset;
        mapSet.repeat = myMap.repeat;
      }
      return mapSet;
    },
    setSomeMapMinFilter(
      someMap,
      someMapSetting,
      magFilter,
      lastMapSetting = null
    ) {
      if (someMap != null && someMap != undefined) {
        switch (magFilter) {
          case "Nearest":
            someMap.minFilter = 1003;
            if (someMapSetting != null) someMapSetting.minFilter = "Nearest";
            if (lastMapSetting != null) lastMapSetting.minFilter = 1003;
            break;
          case "NearestMipmapNearest":
            someMap.minFilter = 1004;
            if (someMapSetting != null)
              someMapSetting.minFilter = "NearestMipmapNearest";
            if (lastMapSetting != null) lastMapSetting.minFilter = 1004;
            break;
          case "NearestMipmapLinear":
            someMap.minFilter = 1005;
            if (someMapSetting != null)
              someMapSetting.minFilter = "NearestMipmapLinear";
            if (lastMapSetting != null) lastMapSetting.minFilter = 1005;
            break;
          case "Linear":
            someMap.minFilter = 1006;
            if (someMapSetting != null) someMapSetting.minFilter = "Linear";
            if (lastMapSetting != null) lastMapSetting.minFilter = 1006;
            break;
          case "LinearMipmapNearest":
            someMap.minFilter = 1007;
            if (someMapSetting != null)
              someMapSetting.minFilter = "LinearMipmapNearest";
            if (lastMapSetting != null) lastMapSetting.minFilter = 1007;
            break;
          case "LinearMipmapLinear":
            someMap.minFilter = 1008;
            if (someMapSetting != null)
              someMapSetting.minFilter = "LinearMipmapLinear";
            if (lastMapSetting != null) lastMapSetting.minFilter = 1008;
            break;
        }
        someMap.needsUpdate = true;
      }
    },
    setSomeMapWraS(someMap, someMapSetting, WrapS, lastMapSetting = null) {
      if (someMap != null && someMap != undefined) {
        switch (WrapS) {
          case "Repeat":
            someMap.wrapS = 1000;
            if (someMapSetting != null) someMapSetting.WrapS = "Repeat";
            if (lastMapSetting != null) lastMapSetting.wrapS = 1000;
            break;
          case "ClampToEdge":
            someMap.wrapS = 1001;
            if (someMapSetting != null) someMapSetting.WrapS = "ClampToEdge";
            if (lastMapSetting != null) lastMapSetting.wrapS = 1001;
            break;
          case "MirroredRepeat":
            someMap.wrapS = 1002;
            if (someMapSetting != null) someMapSetting.WrapS = "MirroredRepeat";
            if (lastMapSetting != null) lastMapSetting.wrapS = 1002;
            break;
        }
        someMap.needsUpdate = true;
      }
    },
    getSnapshotUrl(material) {
      this.materialSphere.updateMaterial(material); //更新右上角材质球
      return this.materialSphere.getScreenshot(); //生成截图（base64）
    },
    setSomeMapWraT(someMap, someMapSetting, WrapT, lastMapSetting = null) {
      if (someMap != null && someMap != undefined) {
        switch (WrapT) {
          case "Repeat":
            someMap.wrapT = 1000;
            if (someMapSetting != null) someMapSetting.WrapT = "Repeat";
            if (lastMapSetting != null) lastMapSetting.wrapT = 1000;
            break;
          case "ClampToEdge":
            someMap.wrapT = 1001;
            if (someMapSetting != null) someMapSetting.WrapT = "ClampToEdge";
            if (lastMapSetting != null) lastMapSetting.wrapT = 1001;
            break;
          case "MirroredRepeat":
            someMap.wrapT = 1002;
            if (someMapSetting != null) someMapSetting.WrapT = "MirroredRepeat";
            if (lastMapSetting != null) lastMapSetting.wrapT = 1002;
            break;
        }
        someMap.needsUpdate = true;
      }
    },
    setSomeMapMagFilter(
      someMap,
      someMapSetting,
      magFilter,
      lastMapSetting = null
    ) {
      if (someMap != null && someMap != undefined) {
        //console.log("模型的贴图",someMap,NearestFilter,LinearFilter);
        switch (magFilter) {
          case "Nearest":
            someMap.magFilter = THREE.NearestFilter;
            if (someMapSetting != null) someMapSetting.magFilter = "Nearest";
            if (lastMapSetting != null) lastMapSetting.magFilter = 1003;
            break;
          case "Linear":
            someMap.magFilter = THREE.LinearFilter;
            if (someMapSetting != null) someMapSetting.magFilter = "Linear";
            if (lastMapSetting != null) lastMapSetting.magFilter = 1006;
            break;
        }
        someMap.needsUpdate = true;
      }
    },
    setSomeMap(someMap, mapUrl, cb) {
      var textureLoad = new THREE.TextureLoader();
      return new Promise((resolve, reject) => {
        textureLoad.load(mapUrl, function (texture) {
          //console.log("增加magFilter", !texture.magFilter, texture.magFilter)
          if (!texture.magFilter) {
            //console.log("增加magFilter")
            texture.magFilter = 1003;
          }
          if (texture.minFilter === null || texture.minFilter === undefined) {
            texture.minFilter = 1003;
          }
          if (texture.wrapT === null || texture.wrapT === undefined) {
            texture.wrapT = 1000;
          }
          if (texture.wrapS === null || texture.wrapS === undefined) {
            texture.wrapS = 1000;
          }
          if (texture.repeat === null || texture.repeat === undefined) {
            texture.repeat = new THREE.Vector2(1, 1);
          }
          if (texture.offset === null || texture.offset === undefined) {
            texture.offset = new THREE.Vector2(0, 0);
          }
          return resolve(texture);
        });
      });

      //ImageUtils.loadTexture(mapUrl, UVMapping, (texture)=> {
      //     someMap = texture;
      // });
    },
    // 创建相机
    createCamera() {
      const element = document.getElementById("container");
      const width = element.clientWidth; // 窗口宽度
      const height = element.clientHeight; // 窗口高度
      const k = width / height; // 窗口宽高比
      this.camera = new THREE.PerspectiveCamera(45, k, 0.1, 100000000);
      // this.camera = new THREE.PerspectiveCamera( 25, k, 1, 1000 );
      this.scene.add(this.camera);
    },
    // 创建渲染器
    createRender() {
      const element = document.getElementById("container");
      this.renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });
      this.renderer.setSize(element.clientWidth, element.clientHeight); // 设置渲染区域尺寸
      this.renderer.shadowMap.enabled = true; // 显示阴影
      this.renderer.shadowMap.type = THREE.PCFSoftShadowMap;
      this.renderer.toneMapping = THREE.ACESFilmicToneMapping;
      this.renderer.toneMappingExposure = 0.5;
      this.renderer.outputEncoding = THREE.sRGBEncoding;
      this.renderer.setClearColor(0x3f3f3f, 1); // 设置背景颜色
      //this.renderer.setClearColor(0xf5f5f5, 1); // 设置背景颜色
      element.appendChild(this.renderer.domElement);
    },

    render() {
      let that = this;
      // if (this.mesh) {
      //   this.mesh.rotation.z += 0.006;
      // }
      this.renderer.render(this.scene, this.camera);
      requestAnimationFrame(this.render);

      // TWEEN.update();
      var time = that.clock.getDelta();
      if (this.mixer) {
        this.mixer.update(time);
      }
    },
    // 创建控件对象
    
    createControls() {
      window.onscroll=function(){
        console.log(123)
        }
      // this.controls = new OrbitControls(this.camera, this.renderer.domElement);
      // this.controls.dampingFactor = 0.05;
      // let num =
      //   this.camera.position.x +
      //   this.camera.position.y +
      //   this.camera.position.z;
      // this.controls.minDistance = num * 0.5;
      // this.controls.maxDistance = num * 4;

      // this.controls.update();
    },
    getmsg() {
      let headers = {};
      if (cookie.get("token") == undefined) {
        headers = { "Content-type": "application/x-www-form-urlencoded" };
      } else {
        headers = {
          "Content-type": "application/x-www-form-urlencoded",
          Authorization: "Bearer " + cookie.get("token"),
        };
      }
      axios
        .get("/wiiboox-biz/tUserReplay/get/" + this.params.id, {
          headers: headers,
        })
        .then((res) => {});
    },
    getPositionByString(str) {
      let position = new Vector3().fromArray(str.split("_"));
      position.x = parseFloat(position.x);
      position.y = parseFloat(position.y);
      position.z = parseFloat(position.z);
      return position;
    },
    getAll() {
      let headers = {};
      if (cookie.get("token") == undefined) {
        headers = { "Content-type": "application/x-www-form-urlencoded" };
      } else {
        headers = {
          "Content-type": "application/x-www-form-urlencoded",
          Authorization: "Bearer " + cookie.get("token"),
        };
      }
      axios
        .get("/wiiboox-biz/tUserReplay/getReplayPage?objId=" + this.params.id, {
          headers: headers,
        })
        .then((res) => {
          this.msgList = res.data.records;
          this.page_current = res.data.current;
          this.size = res.data.size;
          this.total = res.data.total;
        });
    },
    postMsg() {
      let data = {
        action: 1,
        comment: this.textarea,
        objId: this.params.id,
        type: 1,
      };
      let headers = {};
      if (cookie.get("token") == undefined) {
        headers = { "Content-type": "application/json" };
      } else {
        headers = {
          "Content-type": "application/json",
          Authorization: "Bearer " + cookie.get("token"),
        };
      }
      if (this.textarea == "") {
        this.$message("请输入评论");
      } else {
        axios
          .post("/wiiboox-biz/tUserReplay/replay", data, {
            headers: headers,
          })
          .then((res) => {
            this.getAll();
            this.textarea = "";
          });
      }
    },
    //获取用户信息
    getUser() {
      let headers = {};
      if (cookie.get("token") == undefined) {
        headers = { "Content-type": "application/x-www-form-urlencoded" };
      } else {
        headers = {
          "Content-type": "application/x-www-form-urlencoded",
          Authorization: "Bearer " + cookie.get("token"),
        };
      }
      axios
        .get("/wiiboox-admin/sysUser/info", {
          headers: headers,
        })
        .then((res) => {
          // this.username = res.data.username;
          // this.url = res.data.icon;
          this.visitUserId = res.data.id;
          this.getguanzhu();
          if (res.data.icon == null) {
            this.url = require("../../assets/personal.png");
          }
        })
        .catch((err) => {});
    },
    getOther() {},
  },

  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>

<style scoped>
.header {
  color: #fff;
  font-size: 18px;
  border-bottom: 1px solid #2c2c2c;
  padding: 15px 30px;
}
.img {
  width: 60%;
  height: 420px;
  float: left;
  position: relative;
  overflow: hidden;
}
.img img {
  width: 100%;
}
.model {
  padding: 20px;
  height: 545px;
}
.text {
  float: left;
  width: 30%;
  padding-left: 50px;
  color: #999999;
}
.text h2 {
  color: #fff;
  font-size: 17px;
}
.text ul {
  margin-top: 20px;
  font-size: 14px;
  line-height: 30px;
}
.btn {
  margin: 30px auto;
  display: flex;
}
.blue {
  background: #1a8aff;
  color: #fff;
  padding: 2px 15px;
  border: none;
  border-radius: 5px;
}
.gray {
  background: #333;
  border: 1px solid #4a4a4a;
  color: #fff;
  padding: 2px 15px;
  margin-left: 50px;
  border-radius: 5px;
  cursor: pointer;
}
.reply {
  display: flex;
  justify-content: right;
}
.ul_list {
  display: flex;
  width: 100%;
  justify-content: space-between;
  font-size: 14px;
  color: #999999;
  padding-top: 51px;
}
.ul_list img {
  width: 14px;
  position: relative;
  top: 3px;
  left: 8px;
}
.model_footer {
  margin-top: 3%;
  width: 100%;
  float: left;
  color: #999;
  font-size: 14px;
  border-bottom: 1px solid #000000;
  padding-bottom: 30px;
}
.user_name {
  display: flex;
  color: #fff;
  padding-left: 15px;
  padding-top: 5px;
  text-align: left;
}
.portrait {
  width: 65px;
  height: 65px;
  border-radius: 50%;
  float: left;
}
.follow {
  display: flex;
  margin-top: 5px;
  text-align: left;
  padding-left: 15px;
}
.icon1 {
  margin-left: 80px;
  padding: 2px 10px;
}
.icon2 {
  margin-left: 50px;
  padding: 2px 10px;
}
.icon3 {
  border: 1px solid #999999;
  padding: 2px 10px;
  border-radius: 5px;
  cursor: pointer;
}
.icon3 img {
  position: relative;
  right: 3px;
  top: 2px;
}
.icon1 img {
  width: 17px;
  padding-right: 3px;
}
.icon2 img {
  width: 14px;
  padding-right: 3px;
}
.describe_text {
  margin-top: 10px;
  font-size: 14px;
  color: #999999;
  height: 100px;
  /* background: #262626; */
  border: 1px solid #444444;
  border-radius: 5px;
  padding: 10px;
}
.describe .header {
  border: 0;
}
::v-deep .el-textarea__inner {
  background: #262626;
  border: 0;
  margin-top: 10px;
}
.comment button {
  margin-top: 20px;
  float: right;
  padding: 3px 20px !important;
  margin-bottom: 50px;
}
.msg {
  width: 100%;
  padding: 15px 0;
  border-bottom: 1px solid #262626;
}
.msg_bottom {
  margin-bottom: 50px;
}
.msg .portrait {
  width: 30px;
  height: 30px;
}
.msg .user_name {
  font-size: 12px;
  padding-left: 10px;
}
.msg img {
  position: relative;
  top: 8px;
}
.time {
  color: #606060;
  font-size: 12px;
  display: flex;
  margin-top: 1px;
  text-align: left;
  padding-left: 10px;
}
.msg p {
  font-size: 14px;
  color: #999999;
  padding-left: 40px;
  margin-top: 5px;
}
#container {
  /* position: absolute; */
  /* width: 100%;
  height: 100%; */
  width: 100vw;
  height: 100vh;

  /* width: 696px;
  height: 420px; */
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  overflow: hidden;
  background: #000;
}
#loading {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
#info {
  text-align: center;
  color: #1a8aff;
  font-size: 16px;
  margin-bottom: 5px;
}
#infoNum {
  float: right;
  margin-top: -22px;
  color: #1a8aff;
  font-size: 12px;
}

#parent {
  width: 277px;
  height: 3px;
  border-radius: 20px;
  background: #888888;
}

#child {
  width: 0%;
  height: 3px;
  background-color: #1a8aff;
  border-radius: 20px;
}
.preview {
  width: 36px !important;
  display: block;
  position: absolute;
  right: 10px;
  bottom: 10px;
  cursor: pointer;
  transition: all 0.3s;
  -webkit-touch-callout: none;
}
.icon2 {
  cursor: pointer;
}

.preview:hover {
  transform: scale(1.1);
}
.msgBox {
  display: flex;
  justify-content: right;
}
.msgBox span {
  margin-left: 10px;
  color: #666666;
  font-size: 13px;
  cursor: pointer;
}
.reply button {
  margin-top: 13px;
}
/***分页样式 */
.paginationClass {
  margin-top: 50px;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0);
  height: 40px;
  width: 100%;
  text-align: center;
  margin-top: 50px;

  /* padding-left: 15%; */
}
::v-deep .el-pager li {
  margin-left: 15px;
  background: #2b2b2b;
  color: #999999;
}
::v-deep .el-pagination .btn-next {
  margin-left: 15px;
}
::v-deep .el-pagination button:disabled {
  background: #2b2b2b !important;
  color: #999999;
}
::v-deep .el-pagination .btn-next {
  background: #2b2b2b !important;
  color: #999999;
}
::v-deep .el-pager li.btn-quicknext,
.el-pager li.btn-quickprev {
  background: #2b2b2b !important;
  color: #999999;
}
::v-deep .el-pagination .btn-prev {
  background: #2b2b2b !important;
  color: #999999;
}
::v-deep .el-pagination__editor.el-input .el-input__inner {
  background: #2b2b2b !important;
  color: #999999;
  border: 0;
}
::v-deep.el-pagination .el-select .el-input .el-input__inner {
  background: #2b2b2b !important;
  color: #999999;
  border: 0;
}
::v-deep .el-input__icon {
  line-height: 30px;
}
::v-deep .el-input__inner {
  color: #fff;
}
::v-deep .msg .el-textarea__inner {
  width: 97%;
  margin-left: 40px;
}
.erweima {
  position: absolute;
  top: 50%;
  left: 50%;
  display: block !important;
}
#qrCode {
  width: 50%;
  margin: 0 auto;
}
.el-btn-box {
  width: 50%;
  margin: 0 auto;
  display: flex;
  justify-content: space-around;
}
a {
  text-decoration: none;
  color: grey;
}
.iframe {
  cursor: pointer;
}
.show2 {
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.25s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
  opacity: 0;
}
#x7-iframe {
  width: 100%;
  height: 600px;
  /* margin-left: -100px; */
}
.container {
  height: 100%;
  width: 100%;
  background-color: #fafafa;
  position: fixed;
}
.show4 input {
  margin-top: 10px;
  height: 35px;
  border: none;
  padding-left: 10px;
}
.show4 button {
  background: #1a8aff;
  border: 0;
  color: #fff;
  height: 35px;
  width: 70px;
}
@media only screen and (max-width: 767px) {
  .img {
    width: 100%;
  }
  .model {
    padding: 0;
  }
  .describe {
    float: left;
    width: 100%;
  }
  .msg {
    float: left !important;
  }
  .text {
    width: 100%;
    margin-top: 30px;
  }
  .ul_list {
    width: 80%;
  }
  /* .comment{
    float: left;
  } */
  .model_footer {
    padding-left: 30px;
  }
  .portrait {
    width: 40px;
    height: 40px;
  }
  .icon1 {
    margin-left: 30px;
  }
}
</style>