<!--  -->
<template>
  <div class="uoload_file">
    <!-- <button @click="aa()"></button> -->
    <el-row>
      <div v-if="up_1" class="up_1">
        <div class="header_small">
          <span>上传新模型</span>
        </div>
        <!-- <text>我是父组件count:{{fcount}}</text> -->
        <uupy
          @userData="childData"
          :fileType="acc"
          v-if="up_1"
          v-loading="loading1"
          element-loading-text="加载中"
          element-loading-spinner="el-icon-loading"
          element-loading-background="rgba(0, 0, 0, 0.8)"
        ></uupy>
        <div class="el-upload__tip">
          <p>上传须知：</p>
          1、支持模型格式：{{ fileType.join("/") }}等模型格式。<br />
          2、支持ZIP压缩包上传。<br />
          3、纹理格式支持：JPG、PNG、JIF、BMP、TGA。
        </div>
        <el-upload
          style="display: none"
          :ref="`Uploader-${uploadId}`"
          multiple
          :accept="acc.toString()"
          :name="uploadId"
          :action="uploadImgUrl"
          :auto-upload="false"
          :on-remove="handleRemove"
          :on-success="handleUploadSuccess"
          :before-upload="handleBeforeUpload"
          :on-change="handleChange1"
          :file-list="fileList"
          :show-file-list="false"
          drag
        >
          <img src="../../assets/upload/tubiao.png" alt="" class="tubiao" />
          <div class="el-upload__text">
            <em
              ><img
                src="../../assets/upload/top.png"
                alt=""
                class="top"
              />拖动或浏览</em
            >
          </div>
          <div class="el-upload__tip" slot="tip" v-if="showTip">
            <p>上传须知：</p>
            1、支持模型格式{{ fileType.join("/") }}等模型格式。<br />
            2、支持ZIP压缩包上传。<br />
            3、纹理格式支持：JPG、PNG、JIF、BMP, TGA；
          </div>
        </el-upload>
        <div class="btn">
          <!-- <button @click="next()" class="next" style="display: none">下一步</button>
        <button @click="btn()" style="display: none">上传</button> -->
        </div>
      </div>
      <div v-if="up_2" class="up_2">
        <div class="header_small">
          <span>文件确认</span>
        </div>
        <div class="up_bg">
          <el-upload
            class="upload-demo"
            :accept="acc.toString()"
            :name="uploadId"
            multiple
            :action="uploadImgUrl"
            :auto-upload="false"
            :on-remove="handleRemove"
            :on-success="handleUploadSuccess"
            :before-upload="handleBeforeUpload"
            :on-change="handleChange"
            :file-list="fileList"
            list-type="picture"
          >
            <button class="up_file">重选</button>
          </el-upload>
        </div>
        <div class="el-upload__tip" slot="tip" v-if="showTip">
          <p>上传须知：</p>
          1、支持模型格式{{ fileType.join("/") }}等模型格式。<br />
          2、支持ZIP压缩包上传。<br />
          3、纹理格式支持：JPG、PNG、JIF、BMP, TGA；
        </div>
        <div class="btn">
          <button @click="next(2)" class="next">取消</button>
          <button @click="next(1)" class="up_file">下一步</button>
        </div>
      </div>
      <div
        v-if="up_3"
        class="up_3"
        v-loading.fullscreen.lock="loading"
        element-loading-text="上传模型中"
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.5)"
        fullscreen
      >
        <div class="header_small">
          <span>模型信息编辑</span>
        </div>
        <div class="demo-input-suffix">
          <div class="ipt1 ipt">
            <span>模型名称：</span>
            <el-input placeholder="请输入名称" v-model="input1"> </el-input>
          </div>
          <div class="ipt2 ipt">
            <span>模型类型：</span>
            <!-- <el-input placeholder="请选择类型" v-model="checkList.toString()">
            </el-input> -->
            <el-checkbox-group v-model="checkList">
              <el-checkbox
                v-for="item in checkbox"
                :key="item.id"
                :label="item.id"
                >{{ item.type }}</el-checkbox
              >
            </el-checkbox-group>
          </div>
          <div class="ipt3 ipt">
            <span>模型描述：</span>
            <el-input
              type="textarea"
              :rows="6"
              placeholder="请输入内容"
              v-model="textarea"
            >
            </el-input>
          </div>
        </div>
        <div class="btn">
          <button @click="btn()" class="next">完成</button>
        </div>
      </div>
    </el-row>
  </div>
</template>

<script>
import cookie from "../../../public/js/cookie";
import axios from "../../../untils/request";
import uupy from "../template/uupy.vue";
import qs from "qs";
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';

export default {
  name: "upload_file",
  components: { uupy },
  private: {
    fcount: 20,
  },
  emitEvt(evt) {
    this.fcount = evt.detail.count;
  },
  props: {
    name: {
      //上传字段的name
      type: [String],
      required: false,
      default: "files",
    },
    value: {
      type: String,
      default: "",
    },
    // 大小限制(MB)
    fileSize: {
      type: Number,
      default: 600,
    },
    // 是否显示提示
    isShowTip: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    //这里存放数据
    return {
      num: "",
      size: 0,
      uuid: "",
      path: "",
      loading: true,
      loading1: true,
      input1: "",
      checkList: [],
      checkbox: [],
      textarea: "",
      uploadId: Math.random().toString(36).substr(2).toLocaleUpperCase(),
      loading: false,
      up_1: true,
      up_2: false,
      up_3: false,
      up_4: false,
      showFileList: false,
      fileList: [],
      fileInfoList: [],
      fm: new FormData(),
      uploadFiles: [], //待上传的文件列表
      fileTotal: 0, //上传文件总数
      token: "",
      uploadImgUrl:
        // "http://47.114.99.82:8201/" + "wiiboox-biz/tModelFile/upload", // 上传的图片服务器地址
        "https://meta.wiiboox.net/" + "wiiboox-biz/tModelFile/upload",
      headers: {
        // "content-type": "application/x-www-form-urlencoded",
        Authorization: "Bearer " + cookie.get("token"),
      },
      fileType: [],
      alltype: [],
      acc: [],
    };
  },

  //计算属性 类似于data概念
  computed: {
    showTip() {
      return this.isShowTip && (this.fileType || this.fileSize);
    },
  },

  //监控data中的数据变化

  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.token = cookie.get("token");
    this.getCheckList();
    // this.acc =this.alltype.toString()
  },

  //生命周期 - 挂载完成（可以访问DOM元素）

  //方法集合
  methods: {
    //生成uuid
    childData(parmas) {
      this.uuid = parmas.uuid;
      for (let key in parmas.store.state.files) {
        this.fileInfoList.push({
          md5: this.getValue(parmas.store.state.files[key].uploadURL),
          name: parmas.store.state.files[key].name,
          size: parmas.store.state.files[key].size,
          finish: true,
        });
        this.size += parmas.store.state.files[key].size;
      }
      this.next(1);
    },
    getCheckList() {
      axios
        .get("wiiboox-biz/tModelScene/getAll", {
          headers: {
            "content-type": "application/x-www-form-urlencoded",
            Authorization: "Bearer " + cookie.get("token"),
          },
        })
        .then((res) => {
          this.checkbox = res.data;
        });
      axios
        .get("/wiiboox-biz/tModelformat/getAll", {
          headers: {
            "content-type": "application/x-www-form-urlencoded",
            Authorization: "Bearer " + cookie.get("token"),
          },
        })
        .then((res) => {
          this.fileType = [];
          res.data.forEach((item) => {
            this.alltype.push(item.code);
            this.acc.push("." + item.code);
          });
          this.alltype.push("ZIP", "RAR");
          this.acc.push(".ZIP", ".RAR", ".jpg", ".png", ".bin", ".mtl");
          res.data.splice(0, 7).forEach((item) => {
            this.fileType.push(item.code);
          });
          this.loading1 = false;
        });
    },
    getValue(url) {
      //获取最后一个/的位置
      var site = url.lastIndexOf("/");
      //截取最后一个/后的值
      return url.substring(site + 1, url.length);
    },

    uploadFiled() {
      var data = {
        comment: this.textarea,
        fileInfoList: this.fileInfoList,
        name: this.input1,
        uuid: this.uuid,
        projectSceneIds: this.checkList.toString(),
        num: this.fileInfoList.length,
        size: this.size,
      };
      this.loading = true;
      axios
        .post("/wiiboox-biz/tModelProject/upload", data, {
          headers: {
            "content-type": "application/json",
            Authorization: "Bearer " + cookie.get("token"),
          },
          timeout: 120000,
        })
        .then((res) => {
          if (res.code == 200) {
            this.loading = false;
            let url = require("../../assets/car.gif");
            this.$confirm(
              '<img src="' +
                url +
                '" alt="" class="tubiao" />' +
                "<p>模型上传成功，正在后台处理中。 <br>您现在可以正常浏览其他功能。<p>",
              {
                dangerouslyUseHTMLString: true,
                center: true,
                confirmButtonText: "继续上传模型",
                cancelButtonText: "查看我的上传",
              }
            )
              .then(() => {
                this.$router.push({ name: "upload_file" });
              })
              .catch(() => {
                this.$router.push({ name: "upload" });
              });
          } else {
            this.$message(res.message);
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    aa() {
      let url = require("../../assets/car.gif");
      this.$confirm(
        '<img src="' +
          url +
          '" alt="" class="tubiao" />' +
          "<p>模型上传成功，正在后台处理中。 <br>您现在可以正常浏览其他功能。<p>",
        {
          dangerouslyUseHTMLString: true,
          center: true,
          confirmButtonText: "继续上传模型",
          cancelButtonText: "查看我的上传",
        }
      )
        .then(() => {
          // this.$router.push({ name: "upload_file" });
          this.$router.replace({ path: "/refresh" });
        })
        .catch(() => {
          this.$router.push({ name: "upload" });
        });
    },
    removeImage() {
      this.$emit("input", "");
    },
    btn() {
      this.fileList.forEach((item) => {
        this.fm.append(this.name, item.raw);
      });
      if (this.input1 == "") {
        this.$message("请输入文件名称");
        return;
      } else if (this.textarea == "") {
        this.$message("请输入备注信息");
        return;
      } else if (this.checkList == "") {
        this.$message("请选择类型");
        return;
      } else {
        this.fm.append("name", this.input1);
        this.fm.append("comment", this.textarea);
        this.fm.append("projectSceneIds", this.checkList);
      }
      this.uploadFiled(this.fm);
    },
    getCaption(obj) {
      var index = obj.lastIndexOf("."); //获取-后边的字符串
      obj = obj.substring(index + 1, obj.length);
      return obj;
    },
    isExistArr(arr1, arr2, new_arr) {
      // arr1原数组   arr2 要除去的数组   new_arr新的空数组
      arr1.forEach(function (item, idx) {
        if (arr2.indexOf(item) == -1) {
          new_arr.push(item);
        }
      });
      return new_arr;
    },
    next(i) {
      if (i == 1) {
        this.up_3 = true;
        this.up_1 = false;
        return;
        if (this.fileList.length > 0) {
        }
        let newarr = [];
        let tipsarr = [];
        let ziparr = [];
        let arry = [];
        this.fileList.forEach((item) => {
          newarr.push(this.getCaption(item.name).toUpperCase());
        });
        newarr.forEach((item) => {
          if (item == "ZIP" || item == "RAR") {
            ziparr.push(item);
          }
        });
        console.log(ziparr);
        console.log(newarr);
        this.alltype.forEach(function (item, idx) {
          if (newarr.indexOf(item) != -1) {
            tipsarr.push(item);
          }
        });
        console.log(tipsarr.length);
        if (newarr.length < 1) {
          this.$message("请上传文件");
        } else if (tipsarr.length > 1 && tipsarr.length != 0) {
          alert("只能上传一个模型");
          this.$message("只能上传一个模型");
        } else if (tipsarr.length == 0) {
          this.$message("请上传一个模型");
        } else if (ziparr.length > 1) {
          this.$message("只能上传一个压缩包");
        } else {
          this.up_1 = false;
          this.up_2 = false;
          this.up_3 = true;
        }
      }
    },
    /**
     * 移除
     */
    handleRemove(file, fileList) {
      this.fileList = fileList;
    },
    handleUploadSuccess(res) {
      this.$emit("input", res.data.url);
      this.loading.close();
    },
    handleBeforeUpload(file) {
    },
    onprogress(event, file, fileList) {
    },
    handleChange(file, fileList) {
      //获取添加文件进来的状态
      this.fileList = fileList;
      file.status == "ready" && this.uploadFiles.push(file.raw);
      //获取原始文件的个数
      this.fileTotal = document.getElementsByName(
        this.uploadId
      )[0].files.length;
    },
    handleChange1(file, fileList) {
      //获取添加文件进来的状态

      this.fileList = fileList;
      file.status == "ready" && this.uploadFiles.push(file.raw);
      //获取原始文件的个数
      this.fileTotal = document.getElementsByName(
        this.uploadId
      )[0].files.length;
      this.up_1 = false;
      this.up_2 = true;
    },
  },

  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>

<style scoped>
.up_1 .header_small {
  color: #ffffff;
  font-size: 14px;
  padding: 15px 30px;
}
.up_2 .header_small {
  color: #ffffff;
  font-size: 14px;
  text-align: center;
  padding: 15px 0;
}
.up_3 .header_small {
  color: #ffffff;
  font-size: 14px;
  text-align: center;
  padding: 15px 0;
}
.up_3 {
  /* width: 100%;
  height: 60vh; */
}
.drag-area {
  height: 200px;
  width: 300px;
  border: dashed 1px gray;
  margin-bottom: 10px;
  color: #777;
}
.uploader-tips {
  text-align: center;
  height: 200px;
  line-height: 200px;
}
.file-name {
  text-align: center;
  height: 200px;
  line-height: 200px;
}
.image-uploader /deep/.el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  background: #fff;
}
.image-uploader /deep/.el-upload img {
  display: inline-block;
  width: 100%;
}
.el-row {
  background: #333333;
  border: 1px solid #4a4a4a;
  border-radius: 5px;
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
}
>>> .el-upload-dragger {
  background-color: #262626 !important;
  border: 0;
  width: 480px;
  height: 180px;
}
>>> .el-upload {
  padding: 0 10px 14px 10px;
}
.el-upload__tip {
  margin-left: 50px;
  color: #999999;
  margin-bottom: 20px;
}
.el-upload-dragger .el-upload__text em {
  display: block;
  margin-top: 20px;
  text-decoration: underline;
}
.tubiao {
  width: 88%;
  margin: 0 auto;
  padding-top: 50px;
}
.top {
  width: 15px;
  position: relative;
  top: 3px;
  right: 3px;
}
.el-upload__tip p {
  font-size: 14px;
  color: #ffffff;
  padding-bottom: 10px;
}
button {
  width: 90px;
  height: 23px;
  margin: 0 auto;
  border: 0;
  border-radius: 3px;
}
.btn {
  width: 50%;
  margin: 0 auto;
  display: flex;
  position: relative;
  bottom: 5px;
}
.next {
  background: #1a8aff;
  color: #fff;
  cursor: pointer;
}
.up_file {
  background: #333;
  border: 1px solid #4a4a4a;
  color: #fff;
  cursor: pointer !important;
}
.up_bg {
  width: 480px;
  height: 220px;
  background: #262626;
  margin: 0 10px 14px 10px;
  overflow: auto;
  border-radius: 5px;
}
>>> .el-upload-list--picture .el-upload-list__item {
  padding: 0;
  padding-left: 15px;
  background: #262626;
  margin: 0;
  height: 47px;
  border: 0;
  border-radius: 0;
  border-bottom: 1px solid #8a8a8a;
}

>>> .el-upload-list {
  height: 180px;
  overflow: auto;
}
>>> .el-upload-list--picture .el-upload-list__item-thumbnail {
  display: none;
}
>>> .el-upload-list--picture .el-upload-list__item-name i {
  display: none;
}
>>> .el-upload-list__item .el-icon-close {
  display: block;
  color: #fff;
  line-height: 47px;
  padding-right: 15px;
}
>>> .el-upload-list--picture .el-upload-list__item-name {
  margin: 0;
  line-height: 47px;
}
.up_2 >>> .el-upload {
  position: absolute;
  bottom: 35%;
  left: 50%;
  transform: translate(-50%, 10px);
}
>>> .el-upload-list__item .el-icon-close {
  top: 2px;
}
.el-button {
  padding: 0;
}
.el-input {
  width: 71%;
  height: 24px;
}
.demo-input-suffix span {
  float: left;
  color: #999999;
  padding-left: 50px;
  font-size: 14px;
  height: 40px;
  line-height: 40px;
}
.ipt {
  margin-top: 40px;
  margin-right: 50px;
}
.ipt span {
  float: left;
  color: #999999;
  padding-left: 50px;
  font-size: 14px;
  height: 40px;
  line-height: 40px;
}
.ipt3 {
  margin-bottom: 20px;
}
.el-checkbox-group {
  margin-top: 20px;
  padding-left: 140px;
  width: 67%;
}
.el-textarea {
  width: 71%;
}
>>> .el-checkbox__input.is-checked + .el-checkbox__label {
  color: #ffffff;
}
>>> .el-checkbox__input.is-checked .el-checkbox__inner,
.el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background-color: #333333;
  border-color: #999999;
}
.el-checkbox {
  margin-right: 15px;
}
>>> .el-checkbox__inner {
  background-color: #333333;
  color: #fff;
}
.el-input >>> .el-input__inner {
  background: #2c2c2c;
  border: none;
}
.el-textarea >>> .el-textarea__inner {
  background: #2c2c2c;
  border: none;
}
.ipt span {
  color: #999999;
}
.el-input ::v-deep .el-input__inner {
  color: #fff !important;
}
.el-textarea ::v-deep .el-textarea__inner {
  color: #fff !important;
}
@media only screen and (max-width: 767px) {
  .el-row{
    width: 95%;
    top: 50%;
  }
  .el-upload__tip{
   margin-left: 5px;
  }
}
</style>