<template>
  <div id="app">
    <div v-if="$route.meta.type == 1" class="over">
      <headnav v-if="isRouterAlive"></headnav>
      <div class="content1">
        <leftnav></leftnav>
        <router-view class="view"> </router-view>
      </div>
      <!-- <div class=""></div> -->
    </div>
    <div v-if="$route.meta.type == 2" class="over">
      <headnav></headnav>
      <div class="content1">
        <router-view class="view"></router-view>
      </div>
      <!-- <div class=""></div> -->
    </div>
    <router-view v-if="$route.meta.type == 0"></router-view>
  </div>
</template>
<script>
import leftnav from "./views/left-nav.vue";
import headnav from "./views/head.vue";
import cookie from "../public/js/cookie";
import axios from "../untils/request.js";
import qs from "qs";

//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';

export default {
  name: "app",
  provide() {
    return {
      reload: this.reload,
    };
  },
  //import引入的组件需要注入到对象中才能使用
  components: { leftnav, headnav },

  //父组件传入子组件数据
  props: {},

  //格式化数据
  filters: {},

  data() {
    //这里存放数据
    return {
      token: "",
      type: "",
      isRouterAlive: true,
    };
  },

  //计算属性 类似于data概念
  // computed: {
  //   uppy: () =>
  //     new Uppy()
  //       .use(Tus, { endpoint: "http://47.114.99.82:8888/group1/big/upload/",
  //        })

  //       .on("complete", (result) => {
  //         console.log("successful files:", result.successful);
  //         console.log("failed files:", result.failed);
  //       })
  //       .on("file-added", (file) => {
  //         console.log(file)
  //         new Uppy().setFileMeta(file)
  //         // meata的内容保存在header中的Upload-Metadata中，已逗号分隔，key以空格分隔，值用base64加密
  //         //console.info("file:"+JSON.stringify(file));
  //         console.info("file名称:" + JSON.stringify(file));
  //         // console.log(uppy.setMeta({
  //         //   callback_url:'http://47.114.99.82:8888/callback',path:'am'
  //         // }))
  //         new Uppy().setMeta({
  //           callback_url:'http://47.114.99.82:8888/callback',path:'add'
  //         });
  //       }), //这里是传递上传的认证参数,callback_url参数中 id为文件的ID,info 文转的基本信息json
  //   // .setMeta({ callback_url:'http://47.114.99.82:8888/callback',path:'am' })
  // },

  //监控data中的数据变化
  watch: {},

  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.token = cookie.get("token");
    console.log(this.token);
  },

  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    // this.getNav()
  },

  //方法集合
  methods: {
    handleClick() {
      alert("button click");
    },
    reload() {
      this.isRouterAlive = false;
      this.$nextTick(() => {
        this.isRouterAlive = true;
      });
    },
  },

  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style>
/* .view ::-webkit-scrollbar {
  display: none;
}
.view ::-moz-scrollbar {
  display: none;
} */

* {
  padding: 0;
  margin: 0;
  font-family: "微软雅黑";
}

body {
  min-height: 100vh;
}

ul,
ol {
  list-style-type: none;
}

input {
  outline: none;
}
html,
body,
#app {
  height: 100%;
  background: #2e2e2e;
  width: 100%;
}
.content1{
  width: 1440px; margin: 50px auto; display: flex
}
.view {
  margin: 0px auto 50px;
  background: #333333;
  height: auto;
  /* position: absolute;
  right: 10%;
  top: 10%; */
  /* margin-left: 30px; */
  width: 1200px;
  min-height: 80vh;
  min-width: 50%;
  margin-bottom: 50px;
  position: relative;
}
/deep/ .el-select-dropdown {
  background-color: #2c2c2c;
  /* border: none; */
  border-color: #999999;
}
.el-select-dropdown__item {
  font-size: 13px;
  color: #999999;
  font-weight: 500;
}
.headnav {
  width: 100%;
  background: #000;
}
.header {
  color: #fff;
  font-size: 18px;
  border-bottom: 1px solid #2c2c2c;
  padding: 15px 30px;
}
button {
  cursor: pointer;
}
.over{
  overflow: auto;
}
img{
  -webkit-touch-callout: none
}
@media only screen and (max-width: 767px) {
  .view{
    width: 100%;
    margin-left: 0;
    
  }
  .content1{
    width: 100%; 
    margin: 50px auto; 
    display: flex
  }
  .over{
    overflow: hidden;
  }
  ::v-deep.el-table--scrollable-x .el-table__body-wrapper{
    overflow: auto !important;
  }
}
</style>
