<!--  -->
<template>
    <div class="user">
      <div class="header">
      <span>发票管理</span>
    </div>
      <!-- <el-button type="primary" @click="add()">新增</el-button> -->
      <div class="table">
        <el-table
          ref="multipleTable"
          :data="tableData"
          row-key="id"
          tooltip-effect="dark"
          stripe
          @selection-change="handleSelectionChange"
          class="customer-table"
        >
          <el-table-column type="selection" width="55"> </el-table-column>
          <!-- <el-table-column width="70" label="序号" prop="parentIndex">
              <template slot-scope="scope">
                <span v-if="scope.row.isIndex">{{ scope.row.parentIndex }}</span>
              </template>
            </el-table-column> -->
            <el-table-column prop="invoiceAmount" label="支付金额" width="80">
          </el-table-column>
          <el-table-column prop="type" label="抬头类型" width="80">
            <template slot-scope="scope">
              <span v-if="scope.row.type == 0">个人</span>
              <span v-if="scope.row.type == 1">企业</span>
              <span v-if="scope.row.type == 2">事业单位</span>
            </template>
          </el-table-column>
          <el-table-column prop="name" label="抬头名称" width="150">
          </el-table-column>
          <el-table-column prop="code" label="税号" width="150">
            <!-- <template slot-scope="scope">
                <span v-if="scope.row.status == 1">微信</span>
                <span v-if="scope.row.status == 2">支付宝</span>
              </template> -->
          </el-table-column>
          <el-table-column prop="address" label="地址" width="170">
          </el-table-column>
          <el-table-column prop="bankAccount" label="开户银行账号" width="120">
          </el-table-column>
          <el-table-column prop="bankName" label="开户银行名称" width="130">
          </el-table-column>

          <el-table-column prop="phone" label="电话" width="120">
          </el-table-column>
          <el-table-column prop="code" label="开票状态" width="150">
            <template slot-scope="scope">
                <span v-if="scope.row.status == 0">未开票</span>
                <span v-if="scope.row.status == 1">已开票</span>
              </template>
          </el-table-column>
          <el-table-column label="操作" width="130">
            <template slot-scope="scope">
              <el-button
                size="mini"
                @click="handleDelete(scope.$index, scope.row)"
                >删除</el-button
              >
              <!-- <el-button
                size="mini"
                @click="handleEdit(scope.$index, scope.row)"
                >编辑</el-button
              > -->
            </template>
          </el-table-column>
        </el-table>
        <!-- 分页 -->
      </div>
      <div class="paginationClass">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="page_current"
          :page-sizes="[10, 20, 30, 40]"
          :page-size="100"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
      <el-dialog title="新增抬头" :visible.sync="dialogTableVisible">
          <span><span style="color:red;">* </span>发票类型:</span
        > <br> 
        <el-radio v-model="radio" label="0">个人</el-radio>
           <el-radio v-model="radio" label="1">企业</el-radio>
           <el-radio v-model="radio" label="2">事业单位</el-radio><br>
           <span><span style="color:red;">* </span>抬头名称</span
        ><el-input v-model="name" placeholder="请输入内容"></el-input>
        <span><span style="color:red;">* </span>税号</span
        ><el-input v-model="code" placeholder="请输入内容"></el-input>
        <span>地址</span
        ><el-input v-model="address" placeholder="请输入内容"></el-input>
        <span>电话</span
        ><el-input v-model="phone" placeholder="请输入内容"></el-input>
        <span>开户银行账号</span
        ><el-input v-model="bankAccount" placeholder="请输入内容"></el-input>
        <span>开户银行名称</span
        ><el-input v-model="bankName" placeholder="请输入内容"></el-input>
        <button @click="userBtn()" class="btn">确定</button>
      </el-dialog>
    </div>
  </template>
    
    <script>
  //这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
  //例如：import 《组件名称》 from '《组件路径》';
  import cookie from "../../../../public/js/cookie";
  import axios from "../../../../untils/request";
  import qs from "qs";
  export default {
    name: "",
  
    //import引入的组件需要注入到对象中才能使用
    components: {},
  
    //父组件传入子组件数据
    props: {},
  
    //格式化数据
    filters: {},
  
    data() {
      //这里存放数据
      return {
        //表格数据
        tableData: [],
        userList: [],
        checkList: [],
        dialogTableVisible: false,
        userId: "",
        total: 1,
        page_current: 1,
        page_size: 10,
        address: "",
        bankAccount: "",
        bankName: "",
        name:"",
        code: "",
        orderIds: "",
        phone: "",
        title: "",
        type: "",
        radio:"1",
      };
    },
  
    //计算属性 类似于data概念
    computed: {},
  
    //监控data中的数据变化
    watch: {},
  
    //生命周期 - 创建完成（可以访问当前this实例）
    created() {
      this.getList();
    },
  
    //生命周期 - 挂载完成（可以访问DOM元素）
    mounted() {},
  
    //方法集合
    methods: {
      handleEdit(i,row){
          this.dialogTableVisible = true;
          this.address = row.address
          this.bankAccount = row.bankAccount
          this.bankName = row.bankName
          this.name = row.name
          this.radio = row.radio
          this.code = row.code
          this.phone = row.phone
      },
      add() {
          this.address =''
          this.bankAccount = ''
          this.bankName = ''
          this.name = ''
          this.radio = "1"
          this.code = ''
          this.phone = ''
          this.dialogTableVisible = true;
  
      },
      handleDelete(i, row) {
        this.$confirm("此操作将永久删除该文件, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            axios
              .delete("/wiiboox-biz/tUserInvoice/delByIds?ids=" + row.id, {
                headers: {
                  "Content-type": "application/x-www-form-urlencoded",
                  Authorization: "Bearer " + cookie.get("token"),
                },
              })
              .then((res) => {
                this.$message({
                  type: "success",
                  message: "删除成功!",
                });
               this.getList()
              });
          })
          .catch(() => {
          });
      },
      btn() {
          let data = {
          address: this.address,
          bankAccount: this.bankAccount,
          bankName: this.bankName,
          name:this.name,
          type:this.radio,
          code: this.code,
          orderIds: this.orderIds,
          phone: this.phone,
          type: 0,
        };
        axios
          .post("/wiiboox-biz/tUserInvoiceTitle/insertOrUpdate", data, {
            headers: {
              "content-type": "application/json",
              Authorization: "Bearer " + cookie.get("token"),
            },
          })
          .then((res) => {
            this.$message(res.message);
          });
      },
      getList() {
        axios
          .get("/wiiboox-biz/tUserInvoice/page", {
            headers: {
              "content-type": "application/x-www-form-urlencoded",
              Authorization: "Bearer " + cookie.get("token"),
            },
          })
          .then((res) => {
            this.tableData = res.data.records;
            this.page_current = res.data.current;
            this.total = res.data.total;
            this.page_size = res.data.size;
          });
      },
      handRole(i, id) {
        this.checkList = [];
        console.log(this.checkList);
        this.dialogTableVisible = true;
        console.log(id);
        this.userId = id.id;
        axios
          .get("/wiiboox-admin/sysUser/sysRole/" + id.id, {
            headers: {
              "content-type": "application/x-www-form-urlencoded",
              Authorization: "Bearer " + cookie.get("token"),
            },
          })
          .then((res) => {
            console.log(res.data);
            res.data.forEach((item) => {
              this.checkList.push(item.id);
            });
            this.getuserlist();
          });
      },
      getuserlist() {
        this.userList = [];
        axios
          .get("/wiiboox-admin/sysRole/page/", {
            headers: {
              "content-type": "application/x-www-form-urlencoded",
              Authorization: "Bearer " + cookie.get("token"),
            },
          })
          .then((res) => {
            console.log(res.data.records);
            //   this.userList = res.data.records;
            res.data.records.forEach((item) => {
              this.userList.push({
                name: item.name,
                id: item.id,
              });
            });
          });
      },
      userBtn() {
          let data = {
          address: this.address,
          bankAccount: this.bankAccount,
          bankName: this.bankName,
          name:this.name,
          type:this.radio,
          code: this.code,
          phone: this.phone,
        };
        axios
          .post("/wiiboox-biz/tUserInvoiceTitle/insertOrUpdate", qs.stringify(data), {
            headers: {
              "content-type": "application/x-www-form-urlencoded",
              Authorization: "Bearer " + cookie.get("token"),
            },
          })
          .then((res) => {
            if(res.code == 200) {
              this.dialogTableVisible = false
            } else {
              this.$message(res.message);
            }
          });
      },
      handleSelectionChange(val) {
        console.log(val);
        let ids = [];
        val.forEach((item) => {
          ids.push(item.id);
        });
        let data = {
          ids: ids,
        };
        axios({
          method: "delete",
          url: "/wiiboox-admin/sysUser/delByIds/",
          headers: {
            "content-type": "application/x-www-form-urlencoded",
            Authorization: "Bearer " + cookie.get("token"),
          },
          data: qs.stringify(data),
        })
          .then((res) => {
            console.log("成功");
            console.log(res);
          })
          .then((err) => {
            console.log("err");
          });
      },
      handleSizeChange(val) {
        console.log(val);
        axios
          .get(
            "/wiiboox-biz/tUserInvoice/page?size=" +
              val +
              "&current=" +
              this.page_current,
            {
              headers: {
                "content-type": "application/x-www-form-urlencoded",
                Authorization: "Bearer " + cookie.get("token"),
              },
            }
          )
          .then((res) => {
            console.log(res.data.records);
            this.tableData = res.data.records;
          });
      },
      handleCurrentChange(val) {
        console.log(val);
        axios
          .get(
            "/wiiboox-biz/tUserInvoice/page?current=" +
              val +
              "&size=" +
              this.page_size,
            {
              headers: {
                "content-type": "application/x-www-form-urlencoded",
                Authorization: "Bearer " + cookie.get("token"),
              },
            }
          )
          .then((res) => {
            console.log(res.data.records);
            this.tableData = res.data.records;
          });
      },
    },
    beforeCreate() {}, //生命周期 - 创建之前
    beforeMount() {}, //生命周期 - 挂载之前
    beforeUpdate() {}, //生命周期 - 更新之前
    updated() {}, //生命周期 - 更新之后
    beforeDestroy() {}, //生命周期 - 销毁之前
    destroyed() {}, //生命周期 - 销毁完成
    activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
  };
  </script>
    
    <style scoped>
  .el-dialog {
    width: 30%;
  }
  .deletes {
    background: #f56c6c;
    padding: 5px 30px;
    color: #fff;
    border: 0;
    margin-bottom: 5px;
  }
  .btn {
    margin: 0 auto;
  }
  .el-table button {
  width: auto;
  padding: 3px 20px;
  border-radius: 25px;
  background: #333333;
  border: 1px solid #999999;
  color: #999999;
}
.el-table {
  background: #333333 !important;
  padding-left: 15px;
}
.el-table >>> tr {
  background: none !important;
  color: #999999 !important;
  border: none;
}
.el-table >>> th.el-table__cell {
  background: none !important;
  color: #ffffff !important;
}
>>> .el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background-color: #333333 !important;
  border-color: #999999 !important;
}
>>> .el-table--striped
  .el-table__body
  tr.el-table__row--striped
  td.el-table__cell {
  background: #2b2b2b !important;
  /* color: #ffffff !important; */
}
/* // 去掉表格单元格边框 */
.customer-table th {
  border: none;
}
.customer-table td,
.customer-table th.is-leaf {
  border: none;
}
/* // 表格最外边框 */
.el-table--border,
.el-table--group {
  border: none;
}
/* // 头部边框 */
.customer-table thead tr th.is-leaf {
  border: 1px solid #ebeef5;
  border-right: none;
}
.customer-table thead tr th:nth-last-of-type(2) {
  border-right: 1px solid #ebeef5;
}
/* // 表格最外层边框-底部边框 */
.el-table--border::after,
.el-table--group::after {
  width: 0;
}
.customer-table::before {
  width: 0;
}
.customer-table .el-table__fixed-right::before,
.el-table__fixed::before {
  width: 0;
}
/* // 表格有滚动时表格头边框 */
.el-table--border th.gutter:last-of-type {
  border: 1px solid #ebeef5;
  border-left: none;
}
>>> .el-table td.el-table__cell,
.el-table th.el-table__cell.is-leaf {
  border: 0 !important;
}
.el-table td.el-table__cell,
::v-deep .el-table th.el-table__cell.is-leaf {
  border: 0 !important;
}
::v-deep .el-table--enable-row-hover .el-table__body tr:hover > td {
  background-color: #383838 !important;
}
::v-deep .el-checkbox__inner:hover {
  border-color: #fff !important;
}
::v-deep .el-checkbox__input.is-focus .el-checkbox__inner {
  border-color: #fff !important;
}
.myCell .el-checkbox__input {
  display: none !important;
}
.paginationClass {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0);
  height: 40px;
  /* width: 100%; */
  text-align: center;
  /* padding-left: 15%; */
}
::v-deep .el-pager li {
  margin-left: 15px;
  background: #2b2b2b;
  color: #999999;
}
::v-deep .el-pagination .btn-next {
  margin-left: 15px;
}
::v-deep .el-pagination button:disabled {
  background: #2b2b2b !important;
  color: #999999;
}
::v-deep .el-pagination .btn-next {
  background: #2b2b2b !important;
  color: #999999;
}
::v-deep .el-pager li.btn-quicknext,
.el-pager li.btn-quickprev {
  background: #2b2b2b !important;
  color: #999999;
}
::v-deep .el-pagination .btn-prev {
  background: #2b2b2b !important;
  color: #999999;
}
::v-deep .el-pagination__editor.el-input .el-input__inner {
  background: #2b2b2b !important;
  color: #999999;
  border: 0;
}
::v-deep.el-pagination .el-select .el-input .el-input__inner {
  background: #2b2b2b !important;
  color: #999999;
  border: 0;
}
::v-deep .el-table--scrollable-x .el-table__body-wrapper {
  overflow: hidden;
}
::v-deep .el-table__empty-block {
  width: 100% !important;
}
.el-table {
  margin-bottom: 50px;
}
@media only screen and (max-width: 767px) {
  ::v-deep.el-table--scrollable-x .el-table__body-wrapper{
    overflow: auto !important;
  }
  .paginationClass{
    width: 100%;
  }
  ::v-deep .el-pager li{
    margin-left: 0;
  }
  ::v-deep .el-pagination .btn-next {
    margin-left: 0;
  }
  ::v-deep .el-pagination__jump{
    margin-left: 5px;
  }
}
  </style>