<!-- 通知 -->
<template>
  <div class="notice">
    <div class="header">
      <span>通知</span>
    </div>
    <div class="content">
      <el-tabs type="border-card">
        <el-tab-pane>
          <div slot="label" @click="native(0)">全部通知</div>
          <div class="list" v-for="(item, i) in list" :key="i" @click="item.status == 0 ? read(item, i) : ''">
            <div>
              <span class="sp1">{{ item.typeName }}</span
              ><span class="sp2">{{ item.createTime }}</span>
            </div>
            <p>{{ item.content }}</p>

            <img :src="item.icon !=null?item.icon:'../assets/personal.png'" alt="" :onerror="defal" />
            <div class="btn">
              <span @click="read(item, i)" class="read" v-if="item.status == 0"
                >未读</span
              >
              <span  class="gray" v-if="item.status == 1"
                >已读</span
              >
              <span @click="remove(item, i)" class="delete">删除</span>
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="关注">
          <div slot="label" @click="native(1)">关注</div>
          <div class="list" v-for="(item, i) in list" :key="i" @click="item.status == 0 ? read(item, i) : ''">
            <div>
              <span class="sp1">{{ item.typeName }}</span
              ><span class="sp2">{{ item.createTime }}</span>
            </div>
            <p>{{ item.content }}</p>
            <img :src="item.icon !=null?item.icon:'../assets/personal.png'" alt="" :onerror="defal" />
            <div class="btn">
              <span @click="read(item, i)" class="read" v-if="item.status == 0"
                >未读</span
              >
              <span  class="gray" v-if="item.status == 1"
                >已读</span
              >
              <span @click="remove(item, i)" class="delete">删除</span>
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane>
          <div slot="label" @click="native(2)">点赞</div>
          <div class="list" v-for="(item, i) in list" :key="i" @click="item.status == 0 ? read(item, i) : ''">
            <div>
              <span class="sp1">{{ item.typeName }}</span
              ><span class="sp2">{{ item.createTime }}</span>
            </div>
            <p>{{ item.content }}</p>
            <img :src="item.icon !=null?item.icon:'../assets/personal.png'" alt="" :onerror="defal" />
            <div class="btn">
              <span @click="read(item, i)" class="read" v-if="item.status == 0"
                >未读</span
              >
              <span  class="gray" v-if="item.status == 1"
                >已读</span
              >
              <span @click="remove(item, i)" class="delete">删除</span>
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane>
          <div slot="label" @click="native(3)">轻量化</div>
          <div class="list" v-for="(item, i) in list" :key="i" @click="item.status == 0 ? read(item, i) : ''">
            <div>
              <span class="sp1">{{ item.typeName }}</span
              ><span class="sp2">{{ item.createTime }}</span>
            </div>
            <p>{{ item.content }}</p>
            <img :src="item.icon !=null?item.icon:'../assets/personal.png'" alt="" :onerror="defal" />
            <div class="btn">
              <span @click="read(item, i)" class="read" v-if="item.status == 0"
                >未读</span
              >
              <span  class="gray" v-if="item.status == 1"
                >已读</span
              >
              <span @click="remove(item, i)" class="delete">删除</span>
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane>
          <div slot="label" @click="native(4)">审核通知</div>
          <div class="list" v-for="(item, i) in list" :key="i" @click="item.status == 0 ? read(item, i) : ''">
            <div>
              <span class="sp1">{{ item.typeName }}</span
              ><span class="sp2">{{ item.createTime }}</span>
            </div>
            <p>{{ item.content }}</p>
            <img :src="item.icon !=null?item.icon:'../assets/personal.png'" alt="" :onerror="defal" />
            <div class="btn">
              <span @click="read(item, i)" class="read" v-if="item.status == 0"
                >未读</span
              >
              <span  class="gray" v-if="item.status == 1"
                >已读</span
              >
              <span @click="remove(item, i)" class="delete">删除</span>
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane>
          <div slot="label" @click="native(5)">下线通知</div>
          <div class="list" v-for="(item, i) in list" :key="i" @click="item.status == 0 ? read(item, i) : ''">
            <div>
              <span class="sp1">{{ item.typeName }}</span
              ><span class="sp2">{{ item.createTime }}</span>
            </div>
            <p>{{ item.content }}</p>
             <img :src="item.icon !=null?item.icon:'../assets/personal.png'" alt="" :onerror="defal" />
            <div class="btn">
              <span @click="read(item, i)" class="read" v-if="item.status == 0"
                >未读</span
              >
              <span  class="gray" v-if="item.status == 1"
                >已读</span
              >
              <span @click="remove(item, i)" class="delete">删除</span>
            </div>
          </div>
        </el-tab-pane>
      </el-tabs>
      <div class="paginationClass">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="page_current"
          :page-sizes="[10, 20, 30, 40]"
          :page-size="100"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import axios from "../../untils/request.js";
import qs from "qs";
import cookie from "../../public/js/cookie.js";
export default {
  name: "",
  inject: ["reload"],
  //import引入的组件需要注入到对象中才能使用
  components: {},

  //父组件传入子组件数据
  props: {},

  //格式化数据
  filters: {},

  data() {
    //这里存放数据
    return {
      list: [],
      num: 0,
      page_current: 1,
      size: 10,
      total: 0,
      type: "",
      read_text: "未读",
      defal: 'this.src="' + require("../assets/notfound.jpg") + '"',
    };
  },

  //计算属性 类似于data概念
  computed: {},

  //监控data中的数据变化
  watch: {},

  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.getAll();
  },

  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},

  //方法集合
  methods: {
    handleSizeChange(val) {
      this.size = val;
      this.getAll(this.type);
    },
    handleCurrentChange(val) {
      this.page_current = val;
      this.getAll(this.type);
    },
    native(i) {
      this.page_current = 1;
      this.type = i;
      this.getAll(i);
    },
    read(item) {
      if (this.type == undefined || this.type == "") {
        this.type = 0;
      }
      axios
        .get("/wiiboox-biz/tMessageNotice/updateStatus?ids=" + item.id, {
          headers: {
            "Content-type": "application/x-www-form-urlencoded",
            Authorization: "Bearer " + cookie.get("token"),
          },
        })
        .then((res) => {
          this.getAll(this.type);
          this.reload();
          //   this.list = res.data.records;
          //   this.page_current = res.data.current;
          //   this.size = res.data.size;
          //   this.total = res.data.total;
        });
    },
    remove(item) {
      this.$confirm("此操作将永久删除该文件, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          axios
            .delete("/wiiboox-biz/tMessageNotice/delByIds?ids=" + item.id, {
              headers: {
                "Content-type": "application/x-www-form-urlencoded",
                Authorization: "Bearer " + cookie.get("token"),
              },
            })
            .then((res) => {
              this.$message({
                type: "success",
                message: "删除成功!",
              });
              this.getAll(this.type);
              this.reload()
            });
        })
        .catch(() => {
        });
    },
    getAll(num) {
      if (num == undefined) {
        num = 0;
      }
      axios
        .get(
          "/wiiboox-biz/tMessageNotice/page?type=" +
            num +
            "&size=" +
            this.size +
            "&current=" +
            this.page_current,
          {
            headers: {
              "Content-type": "application/x-www-form-urlencoded",
              Authorization: "Bearer " + cookie.get("token"),
            },
          }
        )
        .then((res) => {
          this.list = res.data.records;
          this.page_current = res.data.current;
          this.size = res.data.size;
          this.total = res.data.total;
          this.list.forEach((item) => {
            if (item.type == 1) {
              item.typeName = "关注";
            } else if (item.type == 2) {
              item.typeName = "点赞";
            } else if (item.type == 3) {
              item.typeName = "轻量化";
            } else if (item.type == 4) {
              item.typeName = "审核通知";
            } else if (item.type == 5) {
              item.typeName = "下线通知";
            }
          });
          //   this.msgList = res.data.records;
          //   this.page_current =res.data.current
          //   this.size = res.data.size
          //   this.total =res.data.total
        });
    },
  },

  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>

<style scoped>
::v-deep .el-tabs--border-card > .el-tabs__header {
  background: #2a2a2a;
}
.el-tabs--border-card {
  border: 0;
  box-shadow: none;
}
::v-deep.el-tabs--border-card > .el-tabs__header .el-tabs__item.is-active {
  color: #ffffff;
  border: 0;
  background: #2a2a2a;
}
::v-deep.el-tabs--border-card > .el-tabs__header .el-tabs__item {
  border: 0;
  padding: 0 30px;
}
::v-deep
  .el-tabs--border-card
  > .el-tabs__header
  .el-tabs__item:not(.is-disabled):hover {
  color: #ffffff;
}
::v-deep .el-tabs__nav-wrap {
  margin-bottom: 0;
}
::v-deep .el-tabs__item.is-active {
  color: #ffffff;
}
.content {
  margin-top: 30px;
}
::v-deep.el-tabs--border-card > .el-tabs__header {
  border: 0;
}
.el-tabs--border-card {
  background: #333333;
}
.list {
  background: #2a2a2a;
  position: relative;
  padding: 30px;
  margin-top: 20px;
}
.list img {
  width: 57px;
  height: 57px;
  position: absolute;
  right: 50px;
  top: 40%;
  transform: translate(0, -50%);
}
.sp1 {
  color: #fff;
}
.sp2 {
  color: #666666;
  margin-left: 12px;
  font-size: 14px;
}
.list p {
  color: #666666;
  padding-top: 7px;
}
.read {
  color: #fff;
  cursor: pointer;
  background: #1a8aff;
  padding: 1px 8px;
  border-radius: 2px;
}
.delete {
  color: #fff;
  cursor: pointer;
  background: red;
  padding: 1px 8px;
  border-radius: 2px;
}
.btn {
  font-size: 12px;
  position: absolute;
  right: 32px;
  bottom: 9px;
  /* transform: translate(0, -50%); */
}
.gray {
  color: #fff;
  cursor: pointer;
  background: #666666;
  padding: 1px 8px;
  border-radius: 2px;
}
.btn span {
  margin-left: 15px;
}
/***分页样式 */
.paginationClass {
  margin-top: 50px;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0);
  height: 40px;
  width: 100%;
  text-align: center;
  margin-top: 50px;

  /* padding-left: 15%; */
}
::v-deep .el-pager li {
  margin-left: 15px;
  background: #2b2b2b;
  color: #999999;
}
::v-deep .el-pagination .btn-next {
  margin-left: 15px;
}
::v-deep .el-pagination button:disabled {
  background: #2b2b2b !important;
  color: #999999;
}
::v-deep .el-pagination .btn-next {
  background: #2b2b2b !important;
  color: #999999;
}
::v-deep .el-pager li.btn-quicknext,
.el-pager li.btn-quickprev {
  background: #2b2b2b !important;
  color: #999999;
}
::v-deep .el-pagination .btn-prev {
  background: #2b2b2b !important;
  color: #999999;
}
::v-deep .el-pagination__editor.el-input .el-input__inner {
  background: #2b2b2b !important;
  color: #999999;
  border: 0;
}
::v-deep.el-pagination .el-select .el-input .el-input__inner {
  background: #2b2b2b !important;
  color: #999999;
  border: 0;
}
::v-deep .el-input__icon {
  line-height: 30px;
}
.el-tab-pane {
  margin-bottom: 50px;
}
</style>