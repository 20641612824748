import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import login from '../views/login.vue'
import forgot from '../views/forgot.vue'
import index from '../views/index.vue'
import upload from '../views/biz/modelFile/upload/index.vue'
import reply from '../views/reply.vue'
import notice from '../views/notice.vue'
import results from '../views/results.vue'
import upload_file from '../views/template/upload_file.vue'
import refresh from '../views/template/refresh.vue'
import lightweight from '../views/template/lightweight.vue'
import myModel from '../views/model/myModel.vue'
import error from '../views/error.vue'
import list from '../views/list.vue'
import modelDetail from '../views/model/modelDetail.vue'
import personal from '../views/personal/personal.vue'
import changePassword from '../views/personal/change_password.vue'
import bindingMember from '../views/personal/binding_member.vue'
import bindingSuccess from '../views/personal/binding_success.vue'
import subscribe from '../views/subscribe/subscribe.vue'
import packageSelection from '../views/subscribe/package_selection.vue'
import payment from '../views/subscribe/payment.vue'
import corporate_transfer from '../views/subscribe/corporate_transfer.vue'
import contact from '../views/about/contact.vue'
import role from '../views/admin/role/index.vue'
import user from '../views/admin/user/index.vue'
import axios from '../../untils/request'
import cookie from '../../public/js/cookie'
import iframe from '../views/template/iframe.vue'
import order from '../views/admin/order/index.vue'
import invoiceTitle from '../views/admin/invoiceTitle/index.vue'
import invoice from '../views/admin/invoice/index.vue'
Vue.use(VueRouter)

const routes = [
  // {
  //   path: '/',
  //   name: 'error',
  //   component: error,
  //   meta: {
  //     keepAlive: true,
  //     type: 0,
  //   }
  // },
    {
    path: '/list',
    name: 'list',
    component: list,
    meta: {
      keepAlive: true,
      type: 2,
      user:1,
    }
  },
  {
    path: '/refresh',
    name: 'refresh',
    component: refresh,
    meta: {
      type: 1,
      user:0,
    }
  },
  {
    path: '/login',
    name: 'login',
    component: login,
    meta: {
      keepAlive: true,
      type: 0,
      user:0,
    }
  },
  {
    path: '/forgot',
    name: 'forgot',
    component: forgot,
    meta: {
      keepAlive: true,
      type: 0,
      user:0,
    }
  },
  {
    path: '/',
    name: 'index',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/index.vue'),
    meta: {
      keepAlive: true,
      type: 1,
      user:0,
    }
  },
  {
    path: '/register',
    name: 'register',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/register.vue'),
    meta: {
      keepAlive: true,
      type: 0,
      user:0,
    }
  },
  {
    path: '/index',
    name: 'index',

    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/index.vue'),
    meta: {
      keepAlive: true,
      type: 1,
      user:0,
    }
  },
  {
    path: '/biz/modelFile/index',
    name: 'upload',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: upload,
    meta: {
      keepAlive: true,
      user:0,
      type: 1
    }
  },
  {
    path: '/biz/modelFile/upload/index',
    name: 'upload_file',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: upload_file,
    meta: {
      keepAlive: true,
      user:0,
      type: 1
    }
  },
  {
    path: '/lightweight',
    name: 'lightweight',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: lightweight,
    meta: {
      keepAlive: true,
      user:0,
      type: 1
    }
  },
  {
    path: '/biz/modelFile/publish/index',
    name: 'myModel',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: myModel,
    meta: {
      keepAlive: true,
      user:0,
      type: 1
    }
  },
  {
    path: '/model/modelDetail',
    name: 'modelDetail',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: modelDetail,
    meta: {
      keepAlive: true,
      type: 2,
      user:1,
    }
  },
  {
    path: '/biz/modelFile/lightWeight/index',
    name: 'results',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: results,
    meta: {
      keepAlive: true,
      user:0,
      type: 1
    }
  },
  {
    path: '/biz/messageNotice/notice/index',
    name: 'notice',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: notice,
    meta: {
      keepAlive: true,
      user:0,
      type: 1
    }
  }
  ,
  {
    path: '/biz/messageNotice/comment/index',
    name: 'reply',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: reply,
    meta: {
      keepAlive: true,
      user:0,
      type: 1
    }
  },
  {
    path: '/biz/user/index',
    name: 'personal',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: personal,
    meta: {
      keepAlive: true,
      user:0,
      type: 1
    }
  },
  {
    path: '/biz/user/password/index',
    name: 'changePassword',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: changePassword,
    meta: {
      keepAlive: true,
      user:0,
      type: 1
    }
  },
  {
    path: '/biz/user/card/index',
    name: 'bindingMember',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: bindingMember,
    meta: {
      keepAlive: true,
      user:0,
      type: 1
    }
  },
  {
    path: '/personal/binding-success',
    name: 'bindingSuccess',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: bindingSuccess,
    meta: {
      user:0,
      keepAlive: true
    }
  },
  {
    path: '/subscribe',
    name: 'subscribe',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: subscribe,
    meta: {
      keepAlive: false,
      user:0,
      type: 2
    }
  }, {
    path: '/subscribe/packageSelection',
    name: 'packageSelection',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: packageSelection,
    meta: {
      keepAlive: false,
      user:0,
      type: 2
    }
  }
  , {
    path: '/subscribe/payment',
    name: 'payment',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: payment,
    meta: {
      keepAlive: false,
      user:0,
      type: 2
    }
  }, {
    path: '/subscribe/corporate_transfer',
    name: 'corporate_transfer',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: corporate_transfer,
    meta: {
      user:0,
      keepAlive: true
    }
  }, {
    path: '/about/contact',
    name: 'contact',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: contact,
    meta: {
      user:0,
      keepAlive: true
    }
  }
  , {
    path: '/admin/role/index',
    name: 'role',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: role,
    meta: {
      keepAlive: true,
      user:0,
      type: 1
    }
  }, {
    path: '/biz/invoiceTitle/index',
    name: 'invoiceTitle',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: invoiceTitle,
    meta: {
      keepAlive: true,
      user:0,
      type: 1
    }
  }, {
    path: '/biz/invoice/index',
    name: 'invoice',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: invoice,
    meta: {
      keepAlive: true,
      user:0,
      type: 1
    }
  },{
    path: '/biz/order/index',
    name: 'order',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: order,
    meta: {
      keepAlive: true,
      user:0,
      type: 1
    }
  },{
    path: '/admin/user/index',
    name: 'user',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: user,
    meta: {
      keepAlive: true,
      user:0,
      type: 1
    }
  }
  , {
    path: '/iframe',
    name: 'iframe',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: iframe,
    meta: {
      keepAlive: true,
      user:0,
      type: 0
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
router.beforeEach(function (to, from, next) {
  // this.websocket.close();
  if (to.path == '/login') {
    //保存当前路由
    cookie.set("preRoute", router.currentRoute.path)
    cookie.set("preRoute_id", router.currentRoute.query.id)
    cookie.set("preRoute_accuracyLevel", router.currentRoute.query.accuracyLevel)
  } 
  else if(to.path == '/home'){
    return next('/')
    // window.location.href('https://www.wiiboox.net/wiibooxmeta.php')
    // window.location.href = 'https://www.wiiboox.net/wiibooxmeta.php'
  }
  if (!cookie.get("token")) {
    if (to.meta.type != 0 && to.meta.user != 1) {
      return next('/login')
    } 
  }
  next()
})
export default router
