<!--  -->
<template>
  <div class="myModel">
    <div class="header">
      <span>我的模型</span>
    </div>
    <div>
      <!-- 表格 -->
      <div>
        <div class="small_header">
          <span>全部模型</span>
        </div>
        <div class="select_box">
          <span>模型类型</span>
          <el-select v-model="typeVal" placeholder="请选择" @change="getLists">
            <el-option label="全部" value=""> </el-option>
            <el-option
              v-for="item in fileType"
              :key="item.id"
              :label="item.type"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>
        <div class="select_box">
          <span>模型格式</span>
          <el-select v-model="fileVal" placeholder="请选择" @change="getLists">
            <el-option
              v-for="item in file"
              :key="item.id"
              :label="item.code"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>
        <div class="select_box">
          <span>是否私密</span>
          <el-select v-model="status" placeholder="请选择" @change="getLists">
            <el-option label="全部" value=""> </el-option>
            <el-option label="是" value="1"> </el-option>
            <el-option label="否" value="0"> </el-option>
          </el-select>
        </div>
        <div class="select_box">
          <span>轻量化处理</span>
          <el-select
            v-model="compressStatus"
            placeholder="请选择"
            @change="getLists"
          >
            <el-option label="全部" value=""> </el-option>
            <el-option label="已轻量化" value="0"> </el-option>
            <el-option label="未轻量化" value="1"> </el-option>
          </el-select>
        </div>
      </div>
      <div class="table">
        <div
          class="list"
          v-for="(item, index) in list"
          :key="index"
          @click="detail(item)"
        >
          <!-- <img src="../../assets/test.png" alt="" /> -->
          <img :src="item.thumbnailUrl!=null?item.thumbnailUrl:'../assets/personal.png'" alt="" :onerror="defal" />
          <p>
            <span class="sp1">{{ item.name }}</span
            ><span class="sp2" v-if="item.privacyStatus == 1">私密</span>
            <span class="sp2" v-if="item.privacyStatus == 0">公开</span>
          </p>
        </div>
      </div>
      <div class="paginationClass">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="page_current"
          :page-sizes="[10, 20, 30, 40]"
          :page-size="100"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import axios from "../../../untils/request.js";
import qs from "qs";
import cookie from "../../../public/js/cookie.js";
export default {
  name: "",

  //import引入的组件需要注入到对象中才能使用
  components: {},

  //父组件传入子组件数据
  props: {},

  //格式化数据
  filters: {},

  data() {
    //这里存放数据
    return {
      params: "",
      list: [],
      size: 0,
      total: 0,
      page_current: 0,
      value: "",
      defal: 'this.src="' + require("../../assets/notfound.jpg") + '"',
      file: [],
      fileType: [],
      typeVal: "",
      fileVal: "",
      status: "",
      compressStatus: "",
    };
  },

  //计算属性 类似于data概念
  computed: {},

  //监控data中的数据变化
  watch: {},

  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.getList();
    this.getType();
  },

  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},

  //方法集合
  methods: {
    getType() {
      axios
        .get("wiiboox-biz/tModelScene/getAll", {
          headers: {
            "content-type": "application/x-www-form-urlencoded",
            Authorization: "Bearer " + cookie.get("token"),
          },
        })
        .then((res) => {
          this.fileType = res.data;
        });
      axios
        .get("/wiiboox-biz/tModelformat/getAll", {
          headers: {
            "content-type": "application/x-www-form-urlencoded",
            Authorization: "Bearer " + cookie.get("token"),
          },
        })
        .then((res) => {
          res.data.forEach((item) => {
            this.file.push({ id: item.id, code: item.code });
          });
          this.file.unshift({ id: "", code: "全部" });
        });
    },
    getList() {
      let data = {};
      axios
        .get("/wiiboox-biz/tModelProject/page?size=" + 12 + '&publishStatus=' + 1, {
          headers: {
            "Content-type": "application/x-www-form-urlencoded",
            Authorization: "Bearer " + cookie.get("token"),
          },
        })
        .then((res) => {
          this.list = res.data.records;
          this.total = res.data.total;
          this.page_current = res.data.current;
        });
    },
    getLists() {
      axios
        .get(
          "/wiiboox-biz/tModelProject/page?modelFormatId=" +
            this.fileVal +
            "&publishStatus=" +
            1 +
            "&modelSceneId=" +
            this.typeVal +
            "&compressStatus=" +
            this.compressStatus +
            "&privacyStatus=" +
            this.status +
            "&size=" +
            12,
          {
            headers: {
              "Content-type": "application/x-www-form-urlencoded",
              Authorization: "Bearer " + cookie.get("token"),
            },
          }
        )
        .then((res) => {
          this.list = res.data.records;
          this.total = res.data.total;
          this.page_current = res.data.current;
        });
    },
    handleSizeChange(val) {
      this.size = val;
      axios
        .get(
          "/wiiboox-biz/tModelProject/page?size=" +
            this.size +
            "&current=" +
            this.page_current +
            "&publishStatus=" +
            1,
          {
            headers: {
              "Content-type": "application/x-www-form-urlencoded",
              Authorization: "Bearer " + cookie.get("token"),
            },
          }
        )
        .then((res) => {
          this.list = res.data.records;
        });
    },
    handleCurrentChange(val) {
      this.page_current = val;
      axios
        .get(
          "/wiiboox-biz/tModelProject/page?current=" + val + "&size=" + this.size + '&publishStatus=' + 1,
          {
            headers: {
              "Content-type": "application/x-www-form-urlencoded",
              Authorization: "Bearer " + cookie.get("token"),
            },
          }
        )
        .then((res) => {
          this.list = res.data.records;
        });
    },
    detail(item) {
      if (item.publishStatus == 1) {
        this.$router.push({
          path: "/model/modelDetail",
          query: { id: item.id },
        });
      } else {
        this.$message("请发布后再查看");
      }
    },
  },

  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>

<style scoped>
.header {
  color: #fff;
  font-size: 18px;
  padding: 15px 30px;
}
.small_header {
  color: #fff;
  font-size: 14px;
  padding: 4px 30px;
  float: left;
}
.list {
  width: 269px;
  background: #2e2e2e;
  margin: 10px;
}
.list img {
  width: 100%;
}
.list p {
  width: 100%;
  /* background: #2e2e2e; */
}
.table {
  width: 100%;
  display: flex;

  flex-wrap: wrap;
  margin-bottom: 50px;
}
.table .list {
  width: 23%;
}
.sp1 {
  float: left;
  padding-left: 24px;
  color: #999999;
  font-size: 14px;
  padding: 8px 20px;
}
.sp2 {
  float: right;
  padding-right: 24px;
  color: #005ec1;
  font-size: 14px;
  padding: 8px 20px;
}
/* 下拉样式 */
.el-select >>> .el-popper .popper__arrow {
  display: none !important;
}
/deep/ .el-select-dropdown {
  background-color: #2c2c2c;
  /* border: none; */
  border-color: #999999;
}
.el-select-dropdown__item {
  font-size: 13px;
  color: #999999;
  font-weight: 500;
}
/deep/.el-select-dropdown__list {
  padding: 0;
}
>>> .el-input__inner {
  background: #2c2c2c;
  height: 30px;
  border: 0;
  width: 98px;
}
/deep/.el-popper[x-placement^="bottom"] {
  margin-top: 4px;
}
.select_box {
  width: 179px;
  display: block;
  float: left;
}
.select_box span {
  font-size: 14px;
  color: #999999;
  padding-right: 10px;
}
/***分页样式 */
.paginationClass {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0);
  height: 40px;
  width: 100%;
  text-align: center;
  margin-top: 50px;

  /* padding-left: 15%; */
}
::v-deep .el-pager li {
  margin-left: 15px;
  background: #2b2b2b;
  color: #999999;
}
::v-deep .el-pagination .btn-next {
  margin-left: 15px;
}
::v-deep .el-pagination button:disabled {
  background: #2b2b2b !important;
  color: #999999;
}
::v-deep .el-pagination .btn-next {
  background: #2b2b2b !important;
  color: #999999;
}
::v-deep .el-pager li.btn-quicknext,
.el-pager li.btn-quickprev {
  background: #2b2b2b !important;
  color: #999999;
}
::v-deep .el-pagination .btn-prev {
  background: #2b2b2b !important;
  color: #999999;
}
::v-deep .el-pagination__editor.el-input .el-input__inner {
  background: #2b2b2b !important;
  color: #999999;
  border: 0;
}
::v-deep.el-pagination .el-select .el-input .el-input__inner {
  background: #2b2b2b !important;
  color: #999999;
  border: 0;
}
::v-deep .el-input__icon {
  line-height: 30px;
}
::v-deep .el-input__inner {
  color: #fff;
}
.list img {
  width: 100%;
  height: 266px;
}
</style>