<!-- 套餐选择 -->
<template>
  <div class="selection">
    <div class="header">
      <span>套餐选择</span>
    </div>
    <div class="choose_box">
      <div v-for="(item, i) in list" :key="i" class="img_box" @click="choose1_c(item)">
        <div class="text_box">
          <h1>专业版{{item.type == '月' ? '一个月':'一年'}}</h1>
          <p class="price"><span>￥</span>{{item.price}}</p>
          <p class="time">到期时间：{{item.expireTime[0]}}</p>
        </div>
        <img
          src="../../assets/subscribe/major.png"
          alt=""
          class="choose1"
        />
      </div>
        <img
          src="../../assets/subscribe/choose.png"
          alt=""
          class="choose2"
          v-if="choose1"
        />
        <!--  -->
         <img
          src="../../assets/subscribe/choose.png"
          alt=""
          class="choose3"
         v-if="choose2"
        />
         <!--  -->
    </div>
    <img src="../../assets/subscribe/btn.png" alt="" class="btn" @click="btn" />
    <div class="text">
      <p>服务内容</p>
      <ul>
        <li>作品上传免费，数量不限;</li>
        <li>模型轻量化数量不限;</li>
        <li>可视化作品呈现100个;</li>
        <li>赠送下载积分每月1000分;</li>
        <li>支持私密模型模式;</li>
        <li>支持3D资产管理(支持分类);</li>
        <li>支持开合动画;</li>
        <li>支持三维云渲染;</li>
        <li>支持作品标注;</li>
        <li>支持生成分享链接;</li>
        <li>支持链接密码保护;</li>
        <li>支持嵌入网站;</li>
        <li>支持作品下载;</li>
        <li>支持模型交易;</li>
        <li>技术支持。</li>
      </ul>
    </div>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';

import { getMaxListeners } from "process";
import cookie from "../../../public/js/cookie";
import axios from "../../../untils/request";
export default {
  name: "",

  //import引入的组件需要注入到对象中才能使用
  components: {},

  //父组件传入子组件数据
  props: {},

  //格式化数据
  filters: {},

  data() {
    //这里存放数据
    return {
      choose1: false,
      choose2: false,
      id: "",
      list: "",
      productId: "",
      mealsId: "",
      listId:''
    };
  },

  //计算属性 类似于data概念
  computed: {},

  //监控data中的数据变化
  watch: {},

  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.getparams()
    this.getList();
  },

  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},

  //方法集合
  methods: {
    getparams(){
      if(this.$route.params.id != undefined){
        this.listId = this.$route.params.id
         cookie.set('params', JSON.stringify(this.$route.params))
      } else {
        cookie.get('params')
        let arr = JSON.parse(cookie.get('params'))
        this.listId = arr.id
      }
    },
    btn() {
      cookie.set('mealsId',this.mealsId)
      cookie.set('payAmount',this.price)
      let data = {
        productType: "1",
        productId: cookie.get('productId'),
        mealsId: this.mealsId,
        payAmount:this.price
      };
      if(this.price == undefined) {
        this.$message('请先选择套餐')
      } else {
      axios
        .post("/wiiboox-biz/tUserPayOrder/create", data, {
          headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + cookie.get("token"),
          },
        })
        .then((res) => {
          let data = res.data;
          data.price = this.price;
          this.$router.push({ name: "payment", params: data });
          cookie.set('id',res.data.id)
          cookie.set('orderNo',res.data.orderNo)
        });
      }

    },
    choose2_c() {
      if (!this.choose2) {
        this.choose2 = true;
        this.choose1 = false;
      }
    },
    choose1_c(item) {
      if(item.type == '月'){
        this.choose1 = true
        this.choose2 = false
      } else{
        this.choose2 = true
        this.choose1 = false
      }
      //  if(!this.choose1){
      //   this.choose1 = true
      //   this.choose2 = false
      // }
      this.productId = item.versionId;
      this.mealsId = item.id;
      this.price = item.price;

    },
    getList() {
      axios
        .get(
          "/wiiboox-biz/tMemberVersion/getMeals?id=" + this.listId,
          {
            headers: {
              "Content-type": "application/x-www-form-urlencoded",
              Authorization: "Bearer " + cookie.get("token"),
            },
          }
        )
        .then((res) => {
          this.list = res.data;
          this.list[0].type = '月'
          this.list[1].type = '年'
          this.list.forEach(item=>{
            item.expireTime = /\d{4}-\d{1,2}-\d{1,2}/g.exec(item.expireTime)
          })
        });
    },
  },

  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>

<style scoped>
.header {
  text-align: center;
}
.choose_box {
  width: 100%;
  display: flex;
  text-align: center;
}
.choose_box .img_box {
  width: 50%;
  position: relative;
}
.choose_box .choose1 {
  width: 590px;
  display: block;
  margin: 0 auto;
}
.choose_box .choose2 {
  width: 68px;
  position: absolute;
  left: 460px;
  top: 362px;
}
.choose_box .choose3 {
  width: 68px;
  position: absolute;
  right:72px;
  top: 362px;
}
.btn {
  display: block;
  margin: 0 auto;
  cursor: pointer;
}
.text p {
  display: block;
  width: 434px;
  margin: 0 auto;
  color: #fff;
  text-align: center;
  border-bottom: 1px solid #999999;
  padding-bottom: 20px;
  margin-top: 50px;
}
.text ul {
  margin-top: 20px;
  color: #999999;
  text-align: center;
  font-size: 12px;
  line-height: 24px;
  margin-bottom: 50px;
}
.text_box {
  position: absolute;
  left: 236px;
  top: 131px;
}
.text_box h1 {
  font-size: 16px;
  font-family: 苹方;
  font-weight: 600;
}
.text_box .time {
  font-size: 12px;
  margin-top: 22px;
  font-family: 苹方;
  font-weight: 600;
}
.text_box .price {
  font-size: 35px;
  font-family: 宋体;
  font-weight: 600;
  background-image: -webkit-linear-gradient(bottom, #c3602f, #efa765, #efa764);

  -webkit-background-clip: text;

  -webkit-text-fill-color: transparent;
}
.text_box .price span{
  font-size: 22px;
  
}
</style>