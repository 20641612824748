
<template>
  <div>
    <div v-for="(item, i) in parentData.child" :key="i">
      <div>
        <div>
          <!-- <img src="../../assets/test.png" alt="" class="portrait" />
          <div class="user_name">{{ item.createBy }}</div>
          <div class="time">{{ item.createTime }}</div> -->
          <div id="createBy">
            <p style="display: block; color: #fff; font-size: 14px">
              {{ parentData.createBy }}回复{{ item.createBy }}
            </p>
            <p style="margin-top: 10px">{{ item.commentContent }}</p>
          </div>
          <!-- <p>{{ item.commentContent }}</p> -->
          <div v-if="show == item">
            <el-input
              type="textarea"
              rows="2"
              placeholder="我来说两句"
              v-model="textareaList[item]"
            >
            </el-input>
            <div class="reply">
              <button class="gray" @click="postReply(item)">发送</button>
            </div>
          </div>
        </div>
      </div>
      <parent v-if="item" :parentData="item">{{ item.commentContent }}</parent>
    </div>
    <!-- <div v-for="(item, i) in parentData.child" :key="i">
      <div >
        <div class="msg">
          <img src="../../assets/test.png" alt="" class="portrait" />
          <div class="user_name">{{ item.createBy }}</div>
          <div class="time">{{ item.createTime }}</div>
            <div id="createBy">
                <p style="display:block">回复{{parentData.createBy}}</p>
                <p>{{parentData.commentContent}}</p>
          </div>
          <p>{{ item.commentContent }}</p>
          <p class="msgBox">
            <span @click="msgBox(item)">回复</span><span>举报</span>
          </p>
          <div v-if="show == item">
            <el-input
              type="textarea"
              rows="2"
              placeholder="我来说两句"
              v-model="textareaList[item]"
            >
            </el-input>
            <div class="reply">
              <button class="gray" @click="postReply(item)">发送</button>
            </div>
          </div>
        </div>

      </div>
      <parent v-if="item" :parentData="item">{{item.commentContent}}</parent>
    </div> -->
    <!-- <parent v-else :parentData="parentData" v-for="item in parentData ">{{parentData.commentContent}}{{parentData}}</parent> -->
  </div>
</template>
 
<script>
import axios from "../../../untils/request.js";
import qs from "qs";
import cookie from "../../../public/js/cookie.js";
export default {
  name: "parent",
  props: ["parentData", "num"],
  data() {
    return {
      show: "",
      textareaList: [],
      showlist: true,
    };
  },
  created() {
    this.getType();
  },
  methods: {
    getType() {
      let arr =[]
      arr.push(this.num)
      if (this.num == 1) {
        this.$nextTick(() => {
          this.num = 1
        });
      }
    },
    msgBox(item) {
      this.show = item;
    },
    postReply(item) {
      let data = {
        action: 2,
        comment: this.textareaList[item],
        objId: item.objId,
        parentId: item.id,
        type: 2,
      };
      axios
        .post("/wiiboox-biz/tUserReplay/replay", data, {
          headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + cookie.get("token"),
          },
        })
        .then((res) => {
          this.$emit("fatherMethod");
        });
    },
  },
};
</script>
 
<style scoped>
.gray {
  background: #333;
  border: 1px solid #4a4a4a;
  color: #fff;
  padding: 2px 15px;
  margin-left: 50px;
  border-radius: 5px;
  cursor: pointer;
}
.reply {
  display: flex;
  justify-content: right;
}
.msg {
  width: 100%;
  padding: 15px 0;
  border-bottom: 1px solid #262626;
}
.msg .portrait {
  width: 30px;
  height: 30px;
}
.portrait {
  width: 65px;
  height: 65px;
  border-radius: 50%;
  float: left;
}
.msg .user_name {
  font-size: 12px;
  padding-left: 10px;
}
.user_name {
  display: flex;
  color: #fff;
  padding-left: 15px;
  padding-top: 5px;
  text-align: left;
}
.msg img {
  position: relative;
  top: 8px;
}
.time {
  color: #606060;
  font-size: 12px;
  display: flex;
  margin-top: 1px;
  text-align: left;
  padding-left: 10px;
}
.msg p {
  font-size: 14px;
  color: #999999;
  padding-left: 40px;
  margin-top: 5px;
}
.msgBox {
  display: flex;
  justify-content: right;
  font-style: normal;
  margin-right: 10px;
}
.msgBox span {
  margin-left: 10px;
  color: #666666;
  font-size: 13px;
  cursor: pointer;
}
.reply button {
  margin-top: 13px;
}
#createBy {
  color: #999999;
  margin-top: 10px;
  padding: 20px 50px;
  border: 1px solid #4a4a4a;
  border-radius: 5px;
}
#createBy p {
  font-size: 12px;
  padding-left: 10px;
}
</style>