<!-- 轻量化结果 -->
<template>
  <div class="upload">
    <div class="header">
      <span>模型轻量化</span>
      <button style="float: right" @click="go">轻量化</button>
    </div>
    <div class="table">
      <el-table
        ref="multipleTable"
        :data="tableData"
        row-key="id"
        tooltip-effect="dark"
        stripe
        class="customer-table"
        @selection-change="handleSelectionChange"
        :cell-class-name="cellClass"
      >
        <!-- <el-table
    :data="tableData"
    style="width: 100%;margin-bottom: 20px;"
    row-key="id"
    default-expand-all
    :tree-props="{children: 'children', hasChildren: 'hasChildren'}"> -->
        <el-table-column type="selection" width="55" :selectable="selectable">
        </el-table-column>
        <!-- <el-table-column label="日期" width="120">
        <template slot-scope="scope">{{ scope.row.date }}</template>
      </el-table-column> -->
        <!-- <el-table-column type="index" label="序号" width="100">
        </el-table-column> -->

        <!-- <el-table-column width="70" label="序号" prop="parentIndex">
          <template slot-scope="scope">
            <span v-if="scope.row.isIndex">{{ scope.row.parentIndex }}</span>
          </template>
        </el-table-column> -->
        <el-table-column prop="name" label="模型名称" width="155">
        </el-table-column>
        <el-table-column prop="sourceFormat" label="模型格式" width="80">
        </el-table-column>
        <el-table-column prop="format" label="处理后格式" width="90">
        </el-table-column>
        <el-table-column prop="sourceSize" label="模型原大小" width="90">
        </el-table-column>
        <el-table-column prop="size" label="处理后的大小" width="120">
        </el-table-column>
        <el-table-column prop="compressTime" label="轻量化时间" width="120">
        </el-table-column>
        <el-table-column prop="accuracyLevel" label="轻量化等级" width="90">
          <template slot-scope="scope">
            <span v-if="scope.row.accuracyLevel == 10">低精度</span>
            <span v-if="scope.row.accuracyLevel == 6">中精度</span>
            <span v-if="scope.row.accuracyLevel == 2">高精度</span>
            <span v-if="scope.row.accuracyLevel == 1">无损</span>
          </template>
        </el-table-column>
        <el-table-column label="状态" width="60">
          <template slot-scope="scope">
            <span v-if="scope.row.status == 1">成功</span>
            <span v-if="scope.row.status == 0">失败</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="410">
          <template slot-scope="scope">
            <div v-show="scope.row.isIndex == true">
              <el-button
                size="mini"
                @click="handledownload(scope.$index, scope.row)"
                >下载</el-button
              >
              <!-- <el-button
                size="mini"
                type="danger"
                @click="handleEdit(scope.$index, scope.row)"
                >预览</el-button
              > -->
              <el-button
                size="mini"
                @click="handle3DEdit(scope.$index, scope.row)"
                >3D编辑</el-button
              >
              <el-button
                size="mini"
                type="danger"
                @click="remove(scope.$index, scope.row)"
                >删除</el-button
              >
            </div>
            <div v-show="scope.row.isIndex != true">
              <el-button
                size="mini"
                type="danger"
                @click="handleCompress(scope.$index, scope.row)"
                >轻量化</el-button
              >
              <el-button
                size="mini"
                type="danger"
                @click="handleDelete(scope.$index, scope.row)"
                >模型编辑</el-button
              >
            </div>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
    </div>
    <div class="paginationClass">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="page_current"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="100"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
    <!-- <div style="margin-top: 20px">
      <el-button @click="toggleSelection([tableData[1], tableData[2]])"
        >切换第二、第三行的选中状态</el-button
      >
      <el-button @click="toggleSelection()">取消选择</el-button>
    </div> -->
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import cookie from "../../public/js/cookie";
import axios from "../../untils/request";
// import { uploadFiles } from "http://47.114.99.82:8201/wiiboox-biz/tModelFile/upload";
export default {
  name: "upload",

  //import引入的组件需要注入到对象中才能使用
  components: {},

  //父组件传入子组件数据
  props: {
    name: {
      //上传字段的name
      type: [String],
      required: false,
      default: "files",
    },
    value: {
      type: String,
      default: "",
    },
    // 大小限制(MB)
    fileSize: {
      type: Number,
      default: 600,
    },
    // 是否显示提示
    isShowTip: {
      type: Boolean,
      default: true,
    },
  },

  //格式化数据
  filters: {},

  data() {
    //这里存放数据
    return {
      //
      // tableData: [],
      tableData: [],
      multipleSelection: [],
      page_size: 0,
      total: 0,
      page_current: 1,
      //
      ids: [],
      input1: "",
      checkList: [],
      checkbox: [],
      textarea: "",
      uploadId: Math.random().toString(36).substr(2).toLocaleUpperCase(),
      loading: false,
      up_1: false,
      up_2: false,
      up_3: false,
      up_4: false,
      showFileList: false,
      fileList: [],
      fm: new FormData(),
      uploadFiles: [], //待上传的文件列表
      fileTotal: 0, //上传文件总数
      token: "",
      uploadImgUrl:
        // "http://47.114.99.82:8201/" + "wiiboox-biz/tModelFile/upload", // 上传的图片服务器地址
          "https://meta.wiiboox.net/" + "wiiboox-biz/tModelFile/upload", // 上传的图片服务器地址
      headers: {
        // "content-type": "application/x-www-form-urlencoded",
        Authorization: "Bearer " + cookie.get("token"),
      },
      fileType: ["gltf", "obj", "fbx", "zip", "mtl", "png", "jpg"],
      alltype: [],
    };
  },

  //计算属性 类似于data概念
  computed: {
    showTip() {
      return this.isShowTip && (this.fileType || this.fileSize);
    },
  },

  //监控data中的数据变化
  watch: {},

  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.token = cookie.get("token");
    this.getCheckList();
    this.getList();
  },

  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},

  //方法集合
  methods: {
        remove(index,row) {
      this.$confirm("此操作将永久删除该文件, 是否继续?", "提示", {
        customClass: "message-logout",
        confirmButtonText: "确定",
        cancelButtonText: "取消",

        type: "warning",
      })
        .then(() => {
          axios
            .delete("/wiiboox-biz/tModelFileCompress/delByIds?ids=" + row.id, {
              headers: {
                "Content-type": "application/x-www-form-urlencoded",
                Authorization: "Bearer " + cookie.get("token"),
              },
            })
            .then((res) => {
              this.$message({
                type: "success",
                message: "删除成功!",
              });
              this.getList();
            });
        })
        .catch(() => {});
    },
    //下载
    handledownload(index, row){
        var a = document.createElement("a"); //创建一个<a></a>标签
        a.href = row.url; // 给a标签的href属性值加上地址，注意，这里是绝对路径，不用加 点.
        a.download = row.name+'.'+row.format; //设置下载文件文件名，这里加上.xlsx指定文件类型，pdf文件就指定.fpd即可
        a.style.display = "none"; // 障眼法藏起来a标签
        document.body.appendChild(a); // 将a标签追加到文档对象中
        a.click(); // 模拟点击了a标签，会触发a标签的href的读取，浏览器就会自动下载了
        a.remove(); // 一次性的，用完就删除a标签
    },
        //模型编辑
    handle3DEdit(index, row) {
      // this.$router.push({path:this.url3D,query: {id:this.urlId}})
      window.open(
        "https://meta.wiiboox.net:3030/edit/texture" +
          "?id=" +
          row.modelProjectId +
          "" +
          "&accuracyLevel=" +
          row.accuracyLevel
      );
    },
        //模型编辑
    handleEdit(index, row) {
      // this.$router.push({path:this.url3D,query: {id:this.urlId}})
      // window.open("http://meta.wiiboox.net:3030/edit/texture" + "?model=" + row.modelProjectId + "" + "&accuracyLevel=" + row.accuracyLevel);
                this.$router.push({name: "modelDetail",query: { id: row.modelProjectId, accuracyLevel: row.accuracyLevel },
    })
    },
    
    //轻量化
    go(){
      this.$router.push({ name: "lightweight",params:{type:1}});
    },
    handleCompress(index, row) {
      this.$router.push({ name: "lightweight", params: { id: row.projectId } });
    },
    cellClass({ row, columnIndex }) {
      if (row.isIndex == 1) {
        // 根据某个属性，添加样式，隐藏复选框
        return "myCell";
      }
    },
    selectable(row, index) {
      if (row.isIndex == 1) {
        return true;
      } else {
        return false;
      }
    },
    toggleSelection(rows) {
      if (rows) {
        rows.forEach((row) => {
          this.$refs.multipleTable.toggleRowSelection(row);
        });
      } else {
        this.$refs.multipleTable.clearSelection();
      }
    },
    handleSelectionChange(val) {
      this.ids = [];
      this.multipleSelection = val;
      this.multipleSelection.forEach((item) => {
        this.ids.push(item.id);
      });
    },
    handleCurrentChange(val) {
      this.page_current = val;
      axios
        .get(
          "/wiiboox-biz/tModelFileCompress/page?current=" +
            this.page_current +
            "&size=" +
            this.page_size,
          {
            headers: {
              "content-type": "application/x-www-form-urlencoded",
              Authorization: "Bearer " + cookie.get("token"),
            },
          }
        )
        .then((res) => {
          this.page_size = res.data.size;
          this.total = res.data.total;
          this.page_current = res.data.current;
          this.tableData = res.data.records;
          // this.tableData.forEach((item, index) => {
          //   item.parentIndex = index + 1;
          //   item.isIndex = true;
          // });
        });
    },
    handleSizeChange(val) {
      this.page_size = val;
      axios
        .get("/wiiboox-biz/tModelFileCompress/page?size=" + this.page_size, {
          headers: {
            "content-type": "application/x-www-form-urlencoded",
            Authorization: "Bearer " + cookie.get("token"),
          },
        })
        .then((res) => {
          this.page_size = res.data.size;
          this.total = res.data.total;
          this.page_current = res.data.current;
          this.tableData = res.data.records;
          this.tableData.forEach((item, index) => {
            item.parentIndex = index + 1;
            item.isIndex = true;
          });
        });
    },
    //
    test(val) {
      this.checkList = val;
    },
    //获取列表页数据
    getList() {
      let data = {
        // size: this.page_size,
        // page_current: this.page_current,
      };
      axios
        .get("/wiiboox-biz/tModelFileCompress/page", {
          headers: {
            "content-type": "application/x-www-form-urlencoded",
            Authorization: "Bearer " + cookie.get("token"),
          },
        })
        .then((res) => {
          this.page_size = res.data.size;
          this.total = res.data.total;
          this.page_current = res.data.current;
          this.tableData = res.data.records;
          this.tableData.forEach((item, index) => {
            item.parentIndex = index + 1;
            item.isIndex = true;
          });
        });
    },
    getCheckList() {
      axios
        .get("wiiboox-biz/tModelScene/getAll", {
          headers: {
            "content-type": "application/x-www-form-urlencoded",
            Authorization: "Bearer " + cookie.get("token"),
          },
        })
        .then((res) => {
          this.checkbox = res.data;
        });
      axios
        .get("/wiiboox-biz/tModelformat/getAll", {
          headers: {
            "content-type": "application/x-www-form-urlencoded",
            Authorization: "Bearer " + cookie.get("token"),
          },
        })
        .then((res) => {
          this.fileType = [];
          res.data.forEach((item) => {
            this.alltype.push(item.code);
          });
          res.data.splice(0, 7).forEach((item) => {
            this.fileType.push(item.code);
          });
        });
    },
    uploadFiled(param) {
      axios
        .post("wiiboox-biz/tModelProject/upload", param, {
          headers: {
            "content-type": "multipart/form-data",
            Authorization: "Bearer " + cookie.get("token"),
          },
        })
        .then((res) => {
        });
    },
    removeImage() {
      this.$emit("input", "");
    },
    btn() {
      this.fileList.forEach((item) => {
        this.fm.append(this.name, item.raw);
      });
      this.fm.append("name", this.input1);
      this.fm.append("comment", this.textarea);
      this.fm.append("projectSceneIds", this.checkList);
      this.uploadFiled(this.fm);
    },
    getCaption(obj) {
      var index = obj.lastIndexOf("."); //获取-后边的字符串
      obj = obj.substring(index + 1, obj.length);
      return obj;
    },
    isExistArr(arr1, arr2, new_arr) {
      // arr1原数组   arr2 要除去的数组   new_arr新的空数组
      arr1.forEach(function (item, idx) {
        if (arr2.indexOf(item) == -1) {
          new_arr.push(item);
        }
      });
      return new_arr;
    },
    next(i) {
      if (i == 1) {
        if (this.fileList.length > 0) {
        }
        let newarr = [];
        let tipsarr = [];
        let ziparr = [];
        let arry = [];
        this.fileList.forEach((item) => {
          newarr.push(this.getCaption(item.name).toUpperCase());
        });
        newarr.forEach((item) => {
          if (item == "ZIP") {
            ziparr.push(item);
          }
        });
        this.alltype.forEach(function (item, idx) {
          if (newarr.indexOf(item) != -1) {
            tipsarr.push(item);
          }
        });
        if (newarr.length < 1) {
          this.$message("请上传文件");
        } else if (tipsarr.length > 1 && tipsarr.length != 0) {
          alert("只能上传一个模型");
          this.$message("只能上传一种格式模型文件");
        } else if (tipsarr.length == 0) {
          this.$message("请上传一个模型");
        } else if (ziparr.length > 1) {
          this.$message("只能上传一个压缩包");
        } else {
          this.up_1 = false;
          this.up_2 = false;
          this.up_3 = true;
        }
      }
    },
    /**
     * 移除
     */
    handleRemove(file, fileList) {
      this.fileList = fileList;
    },
    handleUploadSuccess(res) {
      this.$emit("input", res.data.url);
      this.loading.close();
    },
    handleBeforeUpload(file) {
    },
    onprogress(event, file, fileList) {
    },
    handleChange(file, fileList) {
      //获取添加文件进来的状态
      this.fileList = fileList;
      file.status == "ready" && this.uploadFiles.push(file.raw);
      //获取原始文件的个数
      this.fileTotal = document.getElementsByName(
        this.uploadId
      )[0].files.length;
    },
    handleChange1(file, fileList) {
      //获取添加文件进来的状态
      this.fileList = fileList;
      file.status == "ready" && this.uploadFiles.push(file.raw);
      //获取原始文件的个数
      this.fileTotal = document.getElementsByName(
        this.uploadId
      )[0].files.length;
      this.up_1 = false;
      this.up_2 = true;
    },
  },

  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>

<style scoped>
.header {
  color: #fff;
  font-size: 18px;
  border-bottom: 1px solid #2c2c2c;
  padding: 15px 30px;
}
.up_1 .header_small {
  color: #ffffff;
  font-size: 14px;
  padding: 15px 30px;
}
.up_2 .header_small {
  color: #ffffff;
  font-size: 14px;
  text-align: center;
  padding: 15px 0;
}
.up_3 .header_small {
  color: #ffffff;
  font-size: 14px;
  text-align: center;
  padding: 15px 0;
}
.up_3 {
  /* width: 100%;
  height: 60vh; */
}
.drag-area {
  height: 200px;
  width: 300px;
  border: dashed 1px gray;
  margin-bottom: 10px;
  color: #777;
}
.uploader-tips {
  text-align: center;
  height: 200px;
  line-height: 200px;
}
.file-name {
  text-align: center;
  height: 200px;
  line-height: 200px;
}
.image-uploader /deep/.el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  background: #fff;
}
.image-uploader /deep/.el-upload img {
  display: inline-block;
  width: 100%;
}
.el-row {
  background: #333333;
  border: 1px solid #4a4a4a;
  border-radius: 5px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
>>> .el-upload-dragger {
  background-color: #262626 !important;
  border: 0;
  width: 480px;
  height: 180px;
}
>>> .el-upload {
  padding: 0 10px 14px 10px;
}
.el-upload__tip {
  margin-left: 50px;
  color: #999999;
  margin-bottom: 20px;
}
.el-upload-dragger .el-upload__text em {
  display: block;
  margin-top: 20px;
  text-decoration: underline;
}
.tubiao {
  width: 88%;
  margin: 0 auto;
  padding-top: 50px;
}
.top {
  width: 15px;
  position: relative;
  top: 3px;
  right: 3px;
}
.el-upload__tip p {
  font-size: 14px;
  color: #ffffff;
  padding-bottom: 10px;
}
button {
  width: 90px;
  height: 23px;
  margin: 0 auto;
  border: 0;
  border-radius: 3px;
}
.btn {
  width: 50%;
  margin: 0 auto;
  display: flex;
  position: relative;
  top: 50px;
}
.next {
  background: #1a8aff;
  color: #fff;
}
.up_file {
  background: #333;
  border: 1px solid #4a4a4a;
  color: #fff;
}
.up_bg {
  width: 480px;
  height: 220px;
  background: #262626;
  margin: 0 10px 14px 10px;
  overflow: auto;
  border-radius: 5px;
}
>>> .el-upload-list--picture .el-upload-list__item {
  padding: 0;
  padding-left: 15px;
  background: #262626;
  margin: 0;
  height: 47px;
  border: 0;
  border-radius: 0;
  border-bottom: 1px solid #8a8a8a;
}

>>> .el-upload-list {
  height: 180px;
  overflow: auto;
}
>>> .el-upload-list--picture .el-upload-list__item-thumbnail {
  display: none;
}
>>> .el-upload-list--picture .el-upload-list__item-name i {
  display: none;
}
>>> .el-upload-list__item .el-icon-close {
  display: block;
  color: #fff;
  line-height: 47px;
  padding-right: 15px;
}
>>> .el-upload-list--picture .el-upload-list__item-name {
  margin: 0;
  line-height: 47px;
}
.up_2 >>> .el-upload {
  position: absolute;
  bottom: 35%;
  left: 50%;
  transform: translate(-50%, 10px);
}
>>> .el-upload-list__item .el-icon-close {
  top: 2px;
}
.el-button {
  padding: 0;
}
.el-input {
  width: 71%;
  height: 24px;
}
.demo-input-suffix span {
  float: left;
  color: #999999;
  padding-left: 50px;
  font-size: 14px;
  height: 40px;
  line-height: 40px;
}
.ipt {
  margin-top: 40px;
  margin-right: 50px;
}
.ipt span {
  float: left;
  color: #999999;
  padding-left: 50px;
  font-size: 14px;
  height: 40px;
  line-height: 40px;
}
.ipt3 {
  margin-bottom: 20px;
}
.el-checkbox-group {
  margin-top: 20px;
  padding-left: 140px;
  width: 67%;
}
.el-textarea {
  width: 71%;
}
>>> .el-checkbox__input.is-checked + .el-checkbox__label {
  color: #999999;
}
>>> .el-checkbox__input.is-checked .el-checkbox__inner,
.el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background-color: #333333;
  border-color: #999999;
}
.el-checkbox {
  margin-right: 15px;
}
>>> .el-checkbox__inner {
  background-color: #333333;
  color: #999999;
}
.el-input >>> .el-input__inner {
  background: #2c2c2c;
  border: none;
}
.el-textarea >>> .el-textarea__inner {
  background: #2c2c2c;
  border: none;
}
.el-table button {
  width: auto;
  padding: 3px 20px;
  border-radius: 25px;
  background: #333333;
  border: 1px solid #999999;
  color: #999999;
}
.el-table {
  background: #333333 !important;
  padding-left: 15px;
}
.el-table >>> tr {
  background: none !important;
  color: #999999 !important;
  border: none;
}
.el-table >>> th.el-table__cell {
  background: none !important;
  color: #ffffff !important;
}
>>> .el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background-color: #333333 !important;
  border-color: #999999 !important;
}
>>> .el-table--striped
  .el-table__body
  tr.el-table__row--striped
  td.el-table__cell {
  background: #2b2b2b !important;
  /* color: #ffffff !important; */
}
/* // 去掉表格单元格边框 */
.customer-table th {
  border: none;
}
.customer-table td,
.customer-table th.is-leaf {
  border: none;
}
/* // 表格最外边框 */
.el-table--border,
.el-table--group {
  border: none;
}
/* // 头部边框 */
.customer-table thead tr th.is-leaf {
  border: 1px solid #ebeef5;
  border-right: none;
}
.customer-table thead tr th:nth-last-of-type(2) {
  border-right: 1px solid #ebeef5;
}
/* // 表格最外层边框-底部边框 */
.el-table--border::after,
.el-table--group::after {
  width: 0;
}
.customer-table::before {
  width: 0;
}
.customer-table .el-table__fixed-right::before,
.el-table__fixed::before {
  width: 0;
}
/* // 表格有滚动时表格头边框 */
.el-table--border th.gutter:last-of-type {
  border: 1px solid #ebeef5;
  border-left: none;
}
>>> .el-table td.el-table__cell,
.el-table th.el-table__cell.is-leaf {
  border: 0 !important;
}
.el-table td.el-table__cell,
::v-deep .el-table th.el-table__cell.is-leaf {
  border: 0 !important;
}
::v-deep .el-table--enable-row-hover .el-table__body tr:hover > td {
  background-color: #383838 !important;
}
::v-deep .el-checkbox__inner:hover {
  border-color: #fff !important;
}
::v-deep .el-checkbox__input.is-focus .el-checkbox__inner {
  border-color: #fff !important;
}
.myCell .el-checkbox__input {
  display: none !important;
}
.paginationClass {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0);
  height: 40px;
  /* width: 100%; */
  text-align: center;
  /* padding-left: 15%; */
}
::v-deep .el-pager li {
  margin-left: 15px;
  background: #2b2b2b;
  color: #999999;
}
::v-deep .el-pagination .btn-next {
  margin-left: 15px;
}
::v-deep .el-pagination button:disabled {
  background: #2b2b2b !important;
  color: #999999;
}
::v-deep .el-pagination .btn-next {
  background: #2b2b2b !important;
  color: #999999;
}
::v-deep .el-pager li.btn-quicknext,
.el-pager li.btn-quickprev {
  background: #2b2b2b !important;
  color: #999999;
}
::v-deep .el-pagination .btn-prev {
  background: #2b2b2b !important;
  color: #999999;
}
::v-deep .el-pagination__editor.el-input .el-input__inner {
  background: #2b2b2b !important;
  color: #999999;
  border: 0;
}
::v-deep.el-pagination .el-select .el-input .el-input__inner {
  background: #2b2b2b !important;
  color: #999999;
  border: 0;
}
::v-deep .el-table--scrollable-x .el-table__body-wrapper {
  overflow: hidden;
}
::v-deep .el-table__empty-block {
  width: 100% !important;
}
.el-table {
  margin-bottom: 50px;
}
@media only screen and (max-width: 767px) {
  ::v-deep.el-table--scrollable-x .el-table__body-wrapper{
    overflow: auto !important;
  }
  .paginationClass{
    width: 100%;
  }
  ::v-deep .el-pager li{
    margin-left: 0;
  }
  ::v-deep .el-pagination .btn-next {
    margin-left: 0;
  }
  ::v-deep .el-pagination__jump{
    margin-left: 5px;
  }
}
</style>