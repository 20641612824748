<!-- 对公转账 -->
<template>
  <div class="payment">
    <div class="header">
      <span>付款页面</span>
    </div>
    <div class="content">
      <p>对公转账</p>
      <div class="text">
        <p>开户名称：南京威布三维科技有限公司</p>
        <p>开户银行：南京银行大行宫支行 01210120030020031</p>
        <p>转入账号：01210120030020031</p>
        <p>转入金额： ¥ 299</p>
        <p>联系客服: 4008-676-070</p>
        <p>注：打款成功后，请联系客服提供付款截图</p>
      </div>
      <div class="btn"><button>确定</button></div>
    </div>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';

export default {
  name: "",

  //import引入的组件需要注入到对象中才能使用
  components: {},

  //父组件传入子组件数据
  props: {},

  //格式化数据
  filters: {},

  data() {
    //这里存放数据
    return {
      radio: 3,
    };
  },

  //计算属性 类似于data概念
  computed: {},

  //监控data中的数据变化
  watch: {},

  //生命周期 - 创建完成（可以访问当前this实例）
  created() {},

  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},

  //方法集合
  methods: {},

  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>

<style scoped>
.el-radio-group {
  display: flex;
  flex-wrap: wrap;
}
.el-radio {
  width: 100%;
  /* margin-top: 20px; */
  padding: 10px 20px;
}
.content {
  width: 636px;
  position: relative;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.radio {
  padding: 20px 0;
  display: flex;
}
.radio span {
  /* float: left;
  */
  font-size: 14px;
  color: #999999;
}
.money {
  text-align: right;
  font-size: 14px;
  color: #999999;
  padding-bottom: 10px;
}

.content p {
  color: #fff;
  margin-bottom: 10px;
}
.text {
  border-bottom: 1px solid #999999;
  border-top: 1px solid #999999;
  padding: 20px 0;
}
.text p {
  color: #999999;
  font-size: 12px;
}
button {
  width: 80px;
  height: 24px;
  background: #1a8aff;
  border: 0;
  color: #fff;
  border-radius: 5px;
}
.btn {
  margin-top: 30px;
  width: 100%;
  display: flex;
  justify-content: center;
}
</style>