<!--  -->
<template>
  <div class="upload">
    <div class="header">
      <span>我的资产</span>
    </div>
    <el-dialog title="模型信息编辑" :visible.sync="centerDialogVisible" center>
      <div class="demo-input-suffix">
        <div class="ipt1 ipt">
          <span>模型名称：</span>
          <el-input placeholder="请输入名称" v-model="input1"> </el-input>
        </div>
        <div class="ipt2 ipt">
          <span>模型类型：</span>
          <!-- <el-input placeholder="请选择类型" v-model="checkList.toString()">
            </el-input> -->
          <el-checkbox-group v-model="checkList">
            <el-checkbox
              v-for="item in checkbox"
              :key="item.id"
              :label="item.id"
              >{{ item.type }}</el-checkbox
            >
          </el-checkbox-group>
        </div>
        <div class="ipt3 ipt">
          <span>模型描述：</span>
          <el-input
            type="textarea"
            :rows="6"
            placeholder="请输入内容"
            v-model="textarea"
          >
          </el-input>
        </div>
      </div>
      <div class="btn">
        <button @click="btn()" class="next" v-preventReClick>完成</button>
      </div>
    </el-dialog>
    <el-dialog title="设置私密" :visible.sync="privacyDialogVisible" center>
      <div class="demo-input-suffix" style="margin-bottom: 70px">
        <span
          style="
            width: 100%;
            margin-bottom: 20px;
            font-size: 17px;
            color: #cccc;
          "
          >确定将{{ input1 }}设置为私密？</span
        >
        <div class="" style="margin-top: 20px">
          <span></span>
          <el-checkbox v-model="checked" @change="my()">仅自己可见</el-checkbox
          ><br />
          <div class="radio2">
            <div v-show="passshow">
              <span>分享密码：</span
              ><input
                type="text"
                placeholder="请输入密码"
                v-model="password"
              /><br />

              <span style="margin-top: 12px">有效期限：</span>
              <el-select v-model="value1" :popper-append-to-body="false">
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
          </div>
        </div>
      </div>
      <div class="btn">
        <button @click="btn1()" class="next" v-preventReClick>完成</button>
      </div>
    </el-dialog>
    <!-- :action="alltype.toString()" -->
    <div class="table">
      <el-table
        ref="multipleTable"
        :data="tableData"
        row-key="id"
        tooltip-effect="dark"
        stripe
        class="customer-table"
        @selection-change="handleSelectionChange"
        :cell-class-name="cellClass"
      >
        <el-table-column type="selection" width="55" :selectable="selectable">
        </el-table-column>
        <el-table-column prop="name" label="模型名称" width="180">
        </el-table-column>
        <!-- <el-table-column prop="uploadStatus" label="上传状态" width="120">
          <template slot-scope="scope">
            <span v-if="scope.row.uploadStatus == 0">未上传</span>
            <span v-if="scope.row.uploadStatus == 1">已上传</span>
          </template>
        </el-table-column> -->
        <!-- <el-table-column prop="process" label="上传进度" width="80">
        </el-table-column> -->
        <el-table-column prop="uploadStatus" label="处理进度" width="120">
          <template slot-scope="scope">
            <span v-if="scope.row.handleStatus == 0" style="color: #1a8aff"
              >{{ scope.row.process
              }}<b v-if="scope.row.process != null">%</b></span
            >
            <span v-if="scope.row.handleStatus == 1" style="color: #007308"
              >处理完成</span
            >
            <span v-if="scope.row.handleStatus == -1" style="color: #be6700"
              >处理失败</span
            >
          </template>
        </el-table-column>

        <el-table-column prop="formatList" label="模型格式" width="80">
        </el-table-column>
        <el-table-column label="模型大小" width="80">
          <template slot-scope="scope">
            <span>{{ scope.row.size }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="uploadTime" label="上传时间" width="140">
        </el-table-column>
        <el-table-column prop="" label="是否私密" width="80">
          <template slot-scope="scope">
            <span v-if="scope.row.privacyStatus == '0'">否</span>
            <span
              v-if="scope.row.privacyStatus == '1'"
              style="color: #1a8aff; cursor: pointer"
              @click="privacy(scope.row, 0)"
              >是</span
            >
          </template>
        </el-table-column>
        <el-table-column prop="publishStatus" label="发布状态" width="80">
          <template slot-scope="scope">
            <span v-if="scope.row.publishStatus == '0'">未发布</span>
            <span v-if="scope.row.publishStatus == '1'" style="color: #1a8aff"
              >已发布</span
            >
          </template>
        </el-table-column>
        <el-table-column label="操作" width="410">
          <template slot-scope="scope">
            <el-button
              v-if="scope.row.publishStatus == '0'"
              :disabled="scope.row.handleStatus != '1'"
              size="mini"
              @click="release(scope.$index, scope.row)"
              >发布</el-button
            >
            <el-button
              v-show="scope.row.publishStatus == '1'"
              size="mini"
              @click="release(scope.$index, scope.row)"
              >取消发布</el-button
            >
            <el-button
              :disabled="scope.row.handleStatus != '1'"
              size="mini"
              @click="watch(scope.row)"
              >预览</el-button
            >
            <!-- <el-dropdown>
              <span
                class="el-dropdown-link"
                :disabled="scope.row.handleStatus != '1'"
              >
                预览<i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu
                :slot="scope.row.handleStatus != '1' ? '' : 'dropdown'"
              >
                <el-dropdown-item
                  v-for="(w, i) in scope.row.accuracyList"
                  :key="i"
                  @click.native="watch(scope.row, w)"
                >
                  <template>
                    <span v-if="w == '0'">无损</span>
                    <span v-if="w == '2'">高精度</span>
                    <span v-if="w == '6'">中精度</span>
                    <span v-if="w == '10'">低精度</span>
                  </template>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown> -->
            <el-dropdown>
              <span
                class="el-dropdown-link"
                :disabled="
                  scope.row.handleStatus != '1' &&
                  scope.row.handleStatus != '-1'
                "
              >
                更多<i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu
                :slot="
                  scope.row.handleStatus != '1' &&
                  scope.row.handleStatus != '-1'
                    ? ''
                    : 'dropdown'
                "
              >
                <el-dropdown-item
                  v-if="scope.row.privacyStatus == 0"
                  :disabled="scope.row.handleStatus != '1'"
                  @click.native="privacy(scope.row, 1)"
                  >设为私密</el-dropdown-item
                >
                <el-dropdown-item
                  v-if="scope.row.privacyStatus == 1"
                  :disabled="scope.row.handleStatus != '1'"
                  @click.native="privacy(scope.row, 3)"
                  >取消私密</el-dropdown-item
                >
                <el-dropdown-item
                  :disabled="scope.row.handleStatus != '1'"
                  @click.native="handleCompress(scope.$index, scope.row)"
                  >轻量化</el-dropdown-item
                >
                <el-dropdown-item
                  :disabled="scope.row.handleStatus != '1'"
                  @click.native="handleEdit(scope.$index, scope.row)"
                  >3D编辑</el-dropdown-item
                >
                <el-dropdown-item
                  :disabled="scope.row.handleStatus != '1'"
                  @click.native="handleModel(scope.$index, scope.row)"
                  >信息编辑</el-dropdown-item
                >
                <el-dropdown-item @click.native="remove(scope.row)"
                  >删除</el-dropdown-item
                >
              </el-dropdown-menu>
            </el-dropdown>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
    </div>
    <div class="paginationClass">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="page_current"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="100"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
    <!-- <div style="margin-top: 20px">
      <el-button @click="toggleSelection([tableData[1], tableData[2]])"
        >切换第二、第三行的选中状态</el-button
      >
      <el-button @click="toggleSelection()">取消选择</el-button>
    </div> -->
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import cookie from "../../../../../public/js/cookie";
import axios from "../../../../../untils/request";
import ReconnectingWebSocket from "reconnecting-websocket";
import qs from "qs";
// import { uploadFiles } from "http://47.114.99.82:8201/wiiboox-biz/tModelFile/upload";
export default {
  name: "upload",

  //import引入的组件需要注入到对象中才能使用
  components: {},
  inject: ["reload"],
  //父组件传入子组件数据
  props: {
    name: {
      //上传字段的name
      type: [String],
      required: false,
      default: "files",
    },
    value: {
      type: String,
      default: "",
    },
    // 大小限制(MB)
    fileSize: {
      type: Number,
      default: 600,
    },
    // 是否显示提示
    isShowTip: {
      type: Boolean,
      default: true,
    },
  },

  //格式化数据
  filters: {},

  data() {
    //这里存放数据
    return {
      //
      // tableData: [],
      centerDialogVisible: false,
      url3D: "",
      urlId: "",
      child: "",
      nav: "",
      tableData: [],
      wstable: [],
      multipleSelection: [],
      page_size: 0,
      total: 0,
      page_current: 1,
      privacyDialogVisible: false,
      radio: "",
      //
      ids: [],
      input1: "",
      passshow: true,
      checked: false,
      checkList: [],
      checkbox: [],
      options: [
        {
          value: "7",
          label: "7天",
        },
        {
          value: "30",
          label: "30天",
        },
        {
          value: "-1",
          label: "永久",
        },
      ],
      value1: "7",
      textarea: "",
      modelId: "",
      uploadId: Math.random().toString(36).substr(2).toLocaleUpperCase(),
      loading: false,
      up_1: false,
      up_2: false,
      up_3: false,
      up_4: false,
      showFileList: false,
      fileList: [],
      fm: new FormData(),
      uploadFiles: [], //待上传的文件列表
      fileTotal: 0, //上传文件总数
      token: "",
      uploadImgUrl:
        // "http://47.114.99.82:8201/" + "wiiboox-biz/tModelFile/upload", // 上传的图片服务器地址
        "https://meta.wiiboox.net/" + "wiiboox-biz/tModelFile/upload",
      headers: {
        // "content-type": "application/x-www-form-urlencoded",
        Authorization: "Bearer " + cookie.get("token"),
      },
      fileType: ["gltf", "obj", "fbx", "zip", "mtl", "png", "jpg"],
      alltype: [],
      wsList: [],
      that: "",
      rowid: "",
      password: "",
    };
  },

  //计算属性 类似于data概念
  computed: {
    showTip() {
      return this.isShowTip && (this.fileType || this.fileSize);
    },
  },

  //监控data中的数据变化
  watch: {},

  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.token = cookie.get("token");
    this.getwx();
    this.getCheckList();
    this.getList();
    this.child = this.$route.params;
    this.that = this;
    this.value1 = "7"
    window.addEventListener("beforeunload", (e) => this.websocketOnclose(e));
    // this.getId();
  },

  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},

  //方法集合
  methods: {
    createNum() {
      var a = [
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "a",
        "b",
        "c",
        "d",
        "e",
        "f",
        "g",
        "h",
        "i",
        "j",
        "k",
        "l",
        "m",
        "n",
        "o",
        "p",
        "q",
        "r",
        "s",
        "t",
        "u",
        "v",
        "w",
        "x",
        "y",
        "z",
      ];
      //给一个空字符串

      var res = "";

      //循环4次，得到4个字符

      for (var i = 0; i < 4; i++) {
        //将得到的结果给字符串，调用随机函数，0最小数，62表示数字加字母的总数
        var randPosition = Math.floor(Math.random() * (a.length - 1)); //每次生成一个随机数的位置
        res += a[randPosition];
      }
      this.password = res;
    },

    btn1() {
      if (this.value1 == "" && this.checked != true) {
        this.$message("请选择期限");
      } else {
        let data = {
          id: this.rowid,
          owner: this.checked ? true : false,
          password: this.checked ? "" : this.password,
          period: this.checked ? null : Number(this.value1),
          privacyStatus: 1,
        };
        axios
          .post("/wiiboox-biz/tModelPrivacy/privacy", data, {
            headers: {
              "Content-type": "application/json",
              Authorization: "Bearer " + cookie.get("token"),
            },
          })
          .then((res) => {
            this.$message({
              type: "success",
              message: res.message,
            });
            this.getList();
            this.privacyDialogVisible = false;
          });
      }
    },
    my() {
      if (this.checked) {
        this.passshow = false;
      } else {
        this.passshow = true;
      }
    },
    //转换数组
    fmObjToArr(data) {
      let arr = [];
      for (let key in data) {
        arr.push({
          value: JSON.parse(data[key]).process, // label 字段
          id: key, // value字段
          type: JSON.parse(data[key]).handleStatus,
          formats: JSON.parse(data[key]).formats,
        });
      }
      return arr;
    },
    //订阅

    getwx() {
      const httpURL =
        "meta.wiiboox.net:8201/wiiboox-biz/websocket?user=" +
        cookie.get("userId") + '&scene=upload_progress';
      let websocket = new ReconnectingWebSocket(`wss://${httpURL}`);
      websocket.onopen = this.websocketOnopen;
      websocket.onmessage = this.websocketOnmessage;
      this.$router.afterEach(function () {
        websocket.close();
      });
    },
    websocketOnopen() {
      // this.websocket.close();
    },
    websocketOnmessage(event) {
      let arr = this.fmObjToArr(JSON.parse(event.data));
      this.wsList = arr;
      let arry = [];
      const data = JSON.parse(event.data);
      this.tableData.forEach((item, index) => {
        this.wsList.forEach((item1, index1) => {
          if (item.id == item1.id) {
            arry.push(index);
            item.process = item1.value;
            item.handleStatus = item1.type;
            this.$set(item, item.process, item1.value);
            this.$set(item, item.handleStatus, item1.type);
            this.$set(item, item.formatList, item1.formats);
          }
        });
      });
      // console.log("接收到消息的回调方法", data);
    },
    watch(row, w) {
      if (w != 0) {
        // this.$router.push({
        //   name: "modelDetail",
        //   query: { id: row.id, accuracyLevel: w },
        // });
        let routeData = this.$router.resolve({
          name: "modelDetail",
          query: { id: row.id, accuracyLevel: w },
        });
        window.open(routeData.href, '_blank');
      } else {
        // this.$router.push({
        //   name: "modelDetail",
        //   query: { id: row.id },
        // });
        let routeData = this.$router.resolve({
          name: "modelDetail",
          query: { id: row.id},
        });
        window.open(routeData.href, '_blank');
      }
    },
    remove(row) {
      this.$confirm("此操作将永久删除该文件, 是否继续?", "提示", {
        customClass: "message-logout",
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          axios
            .delete("/wiiboox-biz/tModelProject/delByIds?ids=" + row.id, {
              headers: {
                "Content-type": "application/x-www-form-urlencoded",
                Authorization: "Bearer " + cookie.get("token"),
              },
            })
            .then((res) => {
              this.$message({
                type: "success",
                message: "删除成功!",
              });
              this.getList();
            });
        })
        .catch(() => {});
    },
    privacy(row, num) {
      if (num == 0) {
        this.privacyDialogVisible = true;
        axios
          .get("/wiiboox-biz/tModelPrivacy/getPrivacyInfo?id=" + row.id, {
            headers: {
              "Content-type": "application/x-www-form-urlencoded",
              Authorization: "Bearer " + cookie.get("token"),
            },
          })
          .then((res) => {
            this.checked = res.data.owner;
            // if (this.checked == true) {
            //   this.passshow = false;
            //   this.password = "";
            //   this.value1 = "";
            // } else {
              this.password = res.data.password;
              this.value1 = res.data.period.toString();
            // }
          });
      } else if (num == 1) {
        this.privacyDialogVisible = true;
        this.createNum();
      } else {
        let data = {
          id: row.id,
          owner: this.checked ? true : false,
          password: this.password,
          period: Number(this.value1),
          privacyStatus: 0,
        };
        axios
          .post("/wiiboox-biz/tModelPrivacy/privacy", data, {
            headers: {
              "Content-type": "application/json",
              Authorization: "Bearer " + cookie.get("token"),
            },
          })
          .then((res) => {
            this.$message({
              type: "success",
              message: res.message,
            });
            this.getList();
            this.privacyDialogVisible = false;
          });
      }

      this.input1 = row.name;
      this.checked = false;
      this.passshow = true;
      this.value1 = "7"
      this.rowid = row.id;
      let status = "";
      if (row.privacyStatus == 1) {
        status = 0;
      } else {
        status = 1;
      }
      return;
      axios
        .get(
          "/wiiboox-biz/tModelProject/privacy?id=" +
            row.id +
            "&privacyStatus=" +
            status,
          {
            headers: {
              "content-type": "application/x-www-form-urlencoded",
              Authorization: "Bearer " + cookie.get("token"),
            },
          }
        )
        .then((res) => {
          this.getList();
        });
    },

    //展开

    getOpenDetail(row, column, event) {
      if (event.currentTarget.querySelector(".el-table__expand-icon")) {
        event.currentTarget.querySelector(".el-table__expand-icon").click();
      }
    },
    //获取url
    getId() {
      if (this.child != {}) {
        this.child.children.forEach((item) => {
          if (item.label == "3d编辑") {
            this.url3D = item.path;
            this.urlId = item.value;
            cookie.set("url", item.path);
          }
        });
      }
    },
    //模型编辑
    handleEdit(index, row) {
      console.log(1)
      // this.$router.push({path:this.url3D,query: {id:this.urlId}})
      // window.open(
      //   "http://meta.wiiboox.net:3030/edit/texture" +
      //     "?id=" +
      //     row.id +
      //     "" +
      //     "&accuracyLevel=" +
      //     0
      // );
      window.open(
        "http://meta.wiiboox.net:3030/edit/texture" + "?id=" + row.id
      );
    },
    handleModel(index, row) {
      this.centerDialogVisible = true;
      this.modelId = row.id;
      this.input1 = row.name;
      this.textarea = row.comment;
      this.checkList = row.projectSceneIds;
    },
    btn() {
      let data = {
        comment: this.textarea,
        id: this.modelId,
        name: this.input1,
        projectSceneIds: this.checkList,
      };
      axios({
        method: "put",
        url: "/wiiboox-biz/tModelProject/edit",
        headers: {
          "content-type": "application/json",
          Authorization: "Bearer " + cookie.get("token"),
        },
        data: data,
      })
        .then((res) => {
          this.$message({
            showClose: true,
            message: res.message,
            type: "success",
          });
          this.getList();
          this.centerDialogVisible = false;
        })
        .then((err) => {
          // this.$message({
          //   showClose: true,
          //   message: err,
          //   type: "error",
          // });
        });
    },
    release(i, row) {
      let data = {
        id: row.id,
        operate: row.publishStatus,
      };
      let operate = "";
      if (row.publishStatus == 1) {
        operate = 0;
      } else {
        operate = 1;
      }
      axios
        .get(
          "/wiiboox-biz/tModelProject/publish?id=" +
            row.id +
            "&operate=" +
            operate,
          {
            headers: {
              "content-type": "application/x-www-form-urlencoded",
              Authorization: "Bearer " + cookie.get("token"),
            },
          }
        )
        .then((res) => {
          this.$message({
            showClose: true,
            message: res.message,
            type: "success",
          });
          this.getList();
          this.reload();
        });
    },
    //轻量化
    handleCompress(index, row) {
      row.status = 1;
      this.$router.push({ name: "lightweight", params: { row: row } });
    },
    cellClass({ row, columnIndex }) {
      if (row.isIndex == 1) {
        // 根据某个属性，添加样式，隐藏复选框
        return "myCell";
      }
    },
    selectable(row, index) {
      return true;
      // if (row.isIndex == 1) {
      //   return true;
      // } else {
      //   return false;
      // }
    },
    toggleSelection(rows) {
      if (rows) {
        rows.forEach((row) => {
          this.$refs.multipleTable.toggleRowSelection(row);
        });
      } else {
        this.$refs.multipleTable.clearSelection();
      }
    },
    handleSelectionChange(val) {
      this.ids = [];
      this.multipleSelection = val;
      this.multipleSelection.forEach((item) => {
        this.ids.push(item.id);
      });
    },
    handleCurrentChange(val) {
      this.page_current = val;
      axios
        .get(
          "/wiiboox-biz/tModelProject/page?current=" +
            this.page_current +
            "&size=" +
            this.page_size,
          {
            headers: {
              "content-type": "application/x-www-form-urlencoded",
              Authorization: "Bearer " + cookie.get("token"),
            },
          }
        )
        .then((res) => {
          this.page_size = res.data.size;
          this.total = res.data.total;
          this.page_current = res.data.current;
          this.tableData = res.data.records;
        });
    },
    handleSizeChange(val) {
      this.page_size = val;
      axios
        .get("/wiiboox-biz/tModelProject/page?size=" + this.page_size, {
          headers: {
            "content-type": "application/x-www-form-urlencoded",
            Authorization: "Bearer " + cookie.get("token"),
          },
        })
        .then((res) => {
          this.page_size = res.data.size;
          this.total = res.data.total;
          this.page_current = res.data.current;
          this.tableData = res.data.records;
        });
    },
    //
    test(val) {
      this.checkList = val;
    },
    //获取列表页数据
    getList() {
      axios
        .get("/wiiboox-biz/tModelProject/page", {
          headers: {
            "content-type": "application/x-www-form-urlencoded",
            Authorization: "Bearer " + cookie.get("token"),
          },
        })
        .then((res) => {
          this.tableData = res.data.records;
          this.page_size = res.data.size;
          this.total = res.data.total;
          this.page_current = res.data.current;
        });
    },
    getCheckList() {
      axios
        .get("wiiboox-biz/tModelScene/getAll", {
          headers: {
            "content-type": "application/x-www-form-urlencoded",
            Authorization: "Bearer " + cookie.get("token"),
          },
        })
        .then((res) => {
          this.checkbox = res.data;
        });
      axios
        .get("/wiiboox-biz/tModelformat/getAll", {
          headers: {
            "content-type": "application/x-www-form-urlencoded",
            Authorization: "Bearer " + cookie.get("token"),
          },
        })
        .then((res) => {
          this.fileType = [];
          res.data.forEach((item) => {
            this.alltype.push(item.code);
          });
          this.alltype.push("ZIP", "RAR");
          res.data.splice(0, 7).forEach((item) => {
            this.fileType.push(item.code);
          });
        });
    },
    uploadFiled(param) {
      axios
        .post("wiiboox-biz/tModelProject/upload", param, {
          headers: {
            "content-type": "multipart/form-data",
            Authorization: "Bearer " + cookie.get("token"),
          },
        })
        .then((res) => {});
    },
    removeImage() {
      this.$emit("input", "");
    },
    getCaption(obj) {
      var index = obj.lastIndexOf("."); //获取-后边的字符串
      obj = obj.substring(index + 1, obj.length);
      return obj;
    },
    isExistArr(arr1, arr2, new_arr) {
      // arr1原数组   arr2 要除去的数组   new_arr新的空数组
      arr1.forEach(function (item, idx) {
        if (arr2.indexOf(item) == -1) {
          new_arr.push(item);
        }
      });
      return new_arr;
    },
    next(i) {
      if (i == 1) {
        if (this.fileList.length > 0) {
        }
        let newarr = [];
        let tipsarr = [];
        let ziparr = [];
        let arry = [];
        this.fileList.forEach((item) => {
          newarr.push(this.getCaption(item.name).toUpperCase());
        });
        newarr.forEach((item) => {
          if (item == "ZIP") {
            ziparr.push(item);
          }
        });
        this.alltype.forEach(function (item, idx) {
          if (newarr.indexOf(item) != -1) {
            tipsarr.push(item);
          }
        });
        if (newarr.length < 1) {
          this.$message("请上传文件");
        } else if (tipsarr.length > 1 && tipsarr.length != 0) {
          this.$message("只能上传一种格式模型文件");
        } else if (tipsarr.length == 0) {
          this.$message("请上传一个模型");
        } else if (ziparr.length > 1) {
          this.$message("只能上传一个压缩包");
        } else {
          this.up_1 = false;
          this.up_2 = false;
          this.up_3 = true;
        }
      }
    },
    /**
     * 移除
     */
    handleRemove(file, fileList) {
      this.fileList = fileList;
    },
    handleUploadSuccess(res) {
      this.$emit("input", res.data.url);
      this.loading.close();
    },
    handleBeforeUpload(file) {},
    onprogress(event, file, fileList) {},
    handleChange(file, fileList) {
      //获取添加文件进来的状态
      this.fileList = fileList;
      file.status == "ready" && this.uploadFiles.push(file.raw);
      //获取原始文件的个数
      this.fileTotal = document.getElementsByName(
        this.uploadId
      )[0].files.length;
    },
    handleChange1(file, fileList) {
      //获取添加文件进来的状态

      this.fileList = fileList;
      file.status == "ready" && this.uploadFiles.push(file.raw);
      //获取原始文件的个数
      this.fileTotal = document.getElementsByName(
        this.uploadId
      )[0].files.length;
      this.up_1 = false;
      this.up_2 = true;
    },
  },

  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  // beforeDestroy() {
  //   window.removeEventListener("beforeunload", (e) => this.websocketOnclose(e));
  // }, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>

<style scoped>
.header {
  color: #fff;
  font-size: 18px;
  border-bottom: 1px solid #2c2c2c;
  padding: 15px 30px;
}
.up_1 .header_small {
  color: #ffffff;
  font-size: 14px;
  padding: 15px 30px;
}
.up_2 .header_small {
  color: #ffffff;
  font-size: 14px;
  text-align: center;
  padding: 15px 0;
}
.up_3 .header_small {
  color: #ffffff;
  font-size: 14px;
  text-align: center;
  padding: 15px 0;
}
.drag-area {
  height: 200px;
  width: 300px;
  border: dashed 1px gray;
  margin-bottom: 10px;
  color: #777;
}
.uploader-tips {
  text-align: center;
  height: 200px;
  line-height: 200px;
}
.file-name {
  text-align: center;
  height: 200px;
  line-height: 200px;
}
.image-uploader /deep/.el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  background: #fff;
}
.image-uploader /deep/.el-upload img {
  display: inline-block;
  width: 100%;
}
.el-row {
  background: #333333;
  border: 1px solid #4a4a4a;
  border-radius: 5px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
>>> .el-upload-dragger {
  background-color: #262626 !important;
  border: 0;
  width: 480px;
  height: 180px;
}
>>> .el-upload {
  padding: 0 10px 14px 10px;
}
.el-upload__tip {
  margin-left: 50px;
  color: #999999;
  margin-bottom: 20px;
}
.el-upload-dragger .el-upload__text em {
  display: block;
  margin-top: 20px;
  text-decoration: underline;
}
.tubiao {
  width: 88%;
  margin: 0 auto;
  padding-top: 50px;
}
.top {
  width: 15px;
  position: relative;
  top: 3px;
  right: 3px;
}
.el-upload__tip p {
  font-size: 14px;
  color: #ffffff;
  padding-bottom: 10px;
}
button {
  width: 90px;
  height: 23px;
  margin: 0 auto;
  border: 0;
  border-radius: 3px;
}
.btn {
  width: 50%;
  margin: 0 auto;
  display: flex;
  position: relative;
  top: 50px;
  cursor: pointer;
}
.next {
  background: #1a8aff;
  color: #fff;
  cursor: pointer !important;
}
.up_file {
  background: #333;
  border: 1px solid #4a4a4a;
  color: #fff;
  cursor: pointer !important;
}
.up_bg {
  width: 480px;
  height: 220px;
  background: #262626;
  margin: 0 10px 14px 10px;
  overflow: auto;
  border-radius: 5px;
}
>>> .el-upload-list--picture .el-upload-list__item {
  padding: 0;
  padding-left: 15px;
  background: #262626;
  margin: 0;
  height: 47px;
  border: 0;
  border-radius: 0;
  border-bottom: 1px solid #8a8a8a;
}

>>> .el-upload-list {
  height: 180px;
  overflow: auto;
}
>>> .el-upload-list--picture .el-upload-list__item-thumbnail {
  display: none;
}
>>> .el-upload-list--picture .el-upload-list__item-name i {
  display: none;
}
>>> .el-upload-list__item .el-icon-close {
  display: block;
  color: #fff;
  line-height: 47px;
  padding-right: 15px;
}
>>> .el-upload-list--picture .el-upload-list__item-name {
  margin: 0;
  line-height: 47px;
}
.up_2 >>> .el-upload {
  position: absolute;
  bottom: 35%;
  left: 50%;
  transform: translate(-50%, 10px);
}
>>> .el-upload-list__item .el-icon-close {
  top: 2px;
}
.el-button {
  padding: 0;
}
.el-input {
  width: 71%;
  height: 24px;
}
.demo-input-suffix span {
  float: left;
  color: #999999;
  padding-left: 50px;
  font-size: 14px;
  height: 40px;
  line-height: 40px;
}
.ipt {
  margin-top: 40px;
  margin-right: 50px;
}
.ipt span {
  float: left;
  color: #999999;
  padding-left: 50px;
  font-size: 14px;
  height: 40px;
  line-height: 40px;
}
.ipt3 {
  margin-bottom: 20px;
}
.el-checkbox-group {
  margin-top: 20px;
  padding-left: 140px;
  width: 67%;
}
.el-textarea {
  width: 71%;
}
>>> .el-checkbox__input.is-checked + .el-checkbox__label {
  color: #fff;
}
>>> .el-checkbox__input.is-checked .el-checkbox__inner,
.el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background-color: #333333;
  border-color: #999999;
}
.el-checkbox {
  margin-right: 15px;
}
>>> .el-checkbox__inner {
  background-color: #333333;
  color: #999999;
}
.el-checkbox {
  color: #999999;
}
.el-input >>> .el-input__inner {
  background: #2c2c2c;
  border: none;
}
.el-textarea >>> .el-textarea__inner {
  background: #2c2c2c;
  border: none;
}
.el-table button {
  width: auto;
  padding: 3px 20px;
  border-radius: 25px;
  background: #333333;
  border: 1px solid #999999;
  color: #999999;
}
.el-table {
  background: #333333 !important;
  padding-left: 15px;
  margin-bottom: 50px;
}
.el-table >>> tr {
  background: none !important;
  color: #999999 !important;
  border: none;
}
.el-table >>> th.el-table__cell {
  background: none !important;
  color: #ffffff !important;
}
>>> .el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background-color: #333333 !important;
  border-color: #999999 !important;
}
>>> .el-table--striped
  .el-table__body
  tr.el-table__row--striped
  td.el-table__cell {
  background: #2b2b2b !important;
  /* color: #ffffff !important; */
}
/* // 去掉表格单元格边框 */
.customer-table th {
  border: none;
}
.customer-table td,
.customer-table th.is-leaf {
  border: none;
}
/* // 表格最外边框 */
.el-table--border,
.el-table--group {
  border: none;
}
/* // 头部边框 */
.customer-table thead tr th.is-leaf {
  border: 1px solid #ebeef5;
  border-right: none;
}
.customer-table thead tr th:nth-last-of-type(2) {
  border-right: 1px solid #ebeef5;
}
/* // 表格最外层边框-底部边框 */
.el-table--border::after,
.el-table--group::after {
  width: 0;
}
.customer-table::before {
  width: 0;
}
.customer-table .el-table__fixed-right::before,
.el-table__fixed::before {
  width: 0;
}
/* // 表格有滚动时表格头边框 */
.el-table--border th.gutter:last-of-type {
  border: 1px solid #ebeef5;
  border-left: none;
}
>>> .el-table td.el-table__cell,
.el-table th.el-table__cell.is-leaf {
  border: 0 !important;
}
.el-table td.el-table__cell,
::v-deep .el-table th.el-table__cell.is-leaf {
  border: 0 !important;
}
::v-deep .el-table--enable-row-hover .el-table__body tr:hover > td {
  background-color: #383838 !important;
}
::v-deep .el-checkbox__inner:hover {
  border-color: #fff !important;
}
::v-deep .el-checkbox__input.is-focus .el-checkbox__inner {
  border-color: #fff !important;
}
.myCell .el-checkbox__input {
  display: none !important;
}
.paginationClass {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0);
  height: 40px;
  /* width: 100%; */
  text-align: center;
  /* padding-left: 15%; */
}
::v-deep .el-pager li {
  margin-left: 15px;
  background: #2b2b2b;
  color: #999999;
}
::v-deep .el-pagination .btn-next {
  margin-left: 15px;
}
::v-deep .el-pagination button:disabled {
  background: #2b2b2b !important;
  color: #999999;
}
::v-deep .el-pagination .btn-next {
  background: #2b2b2b !important;
  color: #999999;
}
::v-deep .el-table__empty-block {
  width: 100% !important;
}
::v-deep .el-pager li.btn-quicknext,
.el-pager li.btn-quickprev {
  background: #2b2b2b !important;
  color: #999999;
}
::v-deep .el-pagination .btn-prev {
  background: #2b2b2b !important;
  color: #999999;
}
::v-deep .el-pagination__editor.el-input .el-input__inner {
  background: #2b2b2b !important;
  color: #ffffff;
  border: 0;
}
::v-deep.el-pagination .el-select .el-input .el-input__inner {
  background: #2b2b2b !important;
  color: #ffffff !important;
  border: 0;
}
::v-deep .el-table--scrollable-x .el-table__body-wrapper {
  overflow: hidden;
}
::v-deep .el-checkbox__input.is-disabled .el-checkbox__inner {
  display: none;
}
.el-dialog__header {
}
::v-deep .el-dialog__title {
  color: #fff;
}
::v-deep .el-dialog {
  background: #333333;
  width: 689px;
}
.btn {
  top: 0;
}
.el-dropdown-link {
  cursor: pointer;
  font-size: 12px;
  color: #999999;
  width: auto;
  /* padding: 3px 20px; */
  padding: 3px 15px;
  border-radius: 25px;
  background: #333333;
  border: 1px solid #999999;
  margin-left: 10px;
}
.el-dropdown-link:hover {
  background: #1a8aff;
  color: #fff;
  border-color: #1a8aff;
}
/* .el-dropdown-menu {
  background-color: #1d1d1d;
  border: 0;
  border-radius: 0 !important;
  margin: 0 !important;
}
.el-popper {
  background-color: #1d1d1d !important;
  border: 0 !important;
}
.el-dropdown-menu .popper__arrow {
  display: none !important;
}
.el-dropdown-menu__item:focus,
.el-dropdown-menu__item:not(.is-disabled):hover {
  background: #333333 !important;
  color: #cccccc !important;
} 
*/
.el-popper {
  background-color: #1d1d1d !important;
  border: 1px solid #1d1d1d;
  /* border: 0 !important; */
}
.el-dropdown-menu .popper__arrow {
  display: none !important;
}
.el-dropdown-menu__item:focus,
.el-dropdown-menu__item:not(.is-disabled):hover {
  background: #1a8aff !important;
  color: #ffffff !important;
}
::v-deep.el-popper[x-placement^="bottom"] .popper__arrow::after {
  border-bottom-color: #1d1d1d;
}
::v-deep.el-popper[x-placement^="bottom"] .popper__arrow {
  border-bottom-color: #1d1d1d;
}
.message-logout {
  background-color: #1b1b1b !important;
}
::v-deep .el-dropdown-menu__item.is-disabled {
  color: #9999;
}
::v-deep .el-dropdown-menu__item {
  color: #999999;
}
.el-button:focus,
.el-button:hover {
  color: #fff;
  background: #1a8aff;
  border-color: #1a8aff;
}
.ipt span {
  color: #999999;
}
.el-input ::v-deep .el-input__inner {
  color: #fff !important;
}
.el-textarea ::v-deep .el-textarea__inner {
  color: #fff !important;
}
.radio2 {
  margin-top: 20px;
  margin-left: 50px;
}
.radio2 div {
  /* margin-left: 25px;
  margin-top: -20px; */
}
.radio2 input {
  background: #2c2c2c;
  color: #ffffff;
  border: none;
  height: 30px;
  line-height: 30px;
  -webkit-appearance: none;
  width: 190px;
  padding-left: 10px;
}
.radio2 div span {
  clear: both;
  padding-left: 0;
  line-height: 30px;
  height: 30px;
}
.el-select {
  top: 20px;
  margin-left: 0 !important;
}
.el-select ::v-deep .el-input__inner {
  height: 30px;
  background: #2c2c2c;
  border: 0;
  color: #fff;
}
.el-select ::v-deep .el-input__icon {
  line-height: 30px;
}
::v-deep .el-select-dropdown {
  background-color: #2c2c2c !important;
  border: 0;
}
.el-select-dropdown__item {
  color: #ffff;
  font-weight: normal;
}
.el-select-dropdown__item.hover,
.el-select-dropdown__item:hover {
  color: #606266;
}
@media only screen and (max-width: 767px) {
  ::v-deep.el-table--scrollable-x .el-table__body-wrapper{
    overflow: auto !important;
  }
  .paginationClass{
    width: 100%;
  }
  ::v-deep .el-pager li{
    margin-left: 0;
  }
  ::v-deep .el-pagination .btn-next {
    margin-left: 0;
  }
  ::v-deep .el-pagination__jump{
    margin-left: 5px;
  }
}
</style>