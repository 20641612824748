<!--  -->
<template>
  <div id="login">
    <div class="login">
      <img src="../assets/login/login_img.png" alt="" class="login_img" @click="goLsit()" />
      <img src="../assets/phone_bg.jpg" alt="" class="login_img1" @click="goLsit()" />
      <div class="login_form">
        <!-- 账号登录 -->
        <div class="login_bar">
          <div class="top_tab">
            <span :class="{ active: liActiveClass == 1 }" @click="select(1)"
              >账号登录</span
            >
            <span
              :class="{ active: liActiveClass == 2 }"
              @click="select(2)"
              style="display: none"
              >短信登录</span
            >
          </div>
          <div v-if="liActiveClass == 1" class="ipt">
            <div class="ipt1">
              <span>手机/邮箱用/用户名</span>
              <div>
                <img src="../assets/login/ipt1.png" alt="" />
                <input
                  type="text"
                  placeholder="手机/邮箱用/用户名"
                  class="inpt"
                  v-model="account"
                />
              </div>
            </div>
            <div class="ipt2">
              <span>登录密码</span>
              <div>
                <img src="../assets/login/ipt2.png" alt="" />
                <input
                  type="password"
                  placeholder="请输入密码"
                  class="inpt"
                  v-model="password"
                />
              </div>
            </div>
            <button @click="login" @keyup.enter="keyDown(e)" v-preventReClick>
              登录
            </button>
            <div class="set">
              <span class="forget" @click="forget"><u>忘记密码?</u></span>
              <span class="register"
                ><router-link :to="{ name: 'register' }">
                  立即注册</router-link
                ></span
              >
            </div>

            <div class="third">
              <div class="third_text">
                <span class="line line1"></span>
                <p class="texts">第三方直接登录</p>
                <span class="line line2"></span>
              </div>
              <div class="third_box">
                <img src="../assets/login/third1.png" alt="" />
                <img src="../assets/login/third2.png" alt="" />
                <img src="../assets/login/third3.png" alt="" />
              </div>
            </div>
          </div>
          <div v-if="liActiveClass == 2" class="ipt">
            <div class="ipt1">
              <span>手机号</span>
              <div>
                <img src="../assets/login/ipt3.png" alt="" class="ipt_img" />
                <input
                  type="text"
                  placeholder="手机/邮箱用/用户名"
                  class="inpt"
                />
              </div>
            </div>
            <div class="ipt2">
              <span>验证码</span>
              <div>
                <img src="../assets/login/ipt4.png" alt="" />
                <input type="text" placeholder="请输入验证码" class="inpt" />
                <p @click.prevent="getCode()" id="code-btn" :disabled="!show">
                  <span v-show="show"><u>获取验证码</u></span>
                  <span v-show="!show" class="count">{{ count }} s</span>
                </p>
              </div>
            </div>
            <button v-preventReClick>登录</button>
            <div class="set">
              <span class="forget"><u>忘记密码?</u></span>
              <span class="register"
                ><router-link :to="{ name: 'register' }">
                  立即注册</router-link
                ></span
              >
            </div>

            <div class="third">
              <div class="third_text">
                <span class="line line1"></span>
                <p class="texts">第三方直接登录</p>
                <span class="line line2"></span>
              </div>
              <div class="third_box">
                <img src="../assets/login/third1.png" alt="" />
                <img src="../assets/login/third2.png" alt="" />
                <img src="../assets/login/third3.png" alt="" />
              </div>
            </div>
          </div>
        </div>
        <!-- 短信登录 -->
      </div>
    </div>
  </div>
</template>

<script>
import axios from "../../untils/request.js";
import qs from "qs";
import cookie from "../../public/js/cookie.js";
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';

export default {
  name: "login",

  //import引入的组件需要注入到对象中才能使用
  components: {},

  //父组件传入子组件数据
  props: {},

  //格式化数据
  filters: {},

  data() {
    //这里存放数据
    return {
      account: "",
      password: "",
      input1: "",
      input2: "",
      activeName: "second",
      liActiveClass: 1, //默认选中‘首页’
      count: "获取验证码",
      show: true,
      timer: null,
    };
  },

  //计算属性 类似于data概念
  computed: {},

  //监控data中的数据变化
  watch: {},

  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.getToken();
  },

  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    window.addEventListener("keydown", this.keyDown);
  },
  keyDown(e) {
    // 回车则执行登录方法 enter键的ASCII是13
    if (e.keyCode == 13 || e.keyCode == 100) {
      this.login(); // 定义的登录方法
    }
  },
  //方法集合
  methods: {
    getToken() {
      if (cookie.get("token") != undefined) {
        this.$router.push({ name: "home" });
      }
    },
    goLsit() {
      // this.$router.push({ name: "index" });
      // window.open('https://www.wiiboox.net/wiibooxmeta.php')
    },
    handleClick(tab, event) {
    },
    select(i) {
      this.liActiveClass = i;
    },
    forget() {
      this.$router.push({ name: "forgot" });
    },
    //获取短信验证码
    getCode() {
      //axios请求
      // 验证码倒计时
      if (!this.timer) {
        this.count = 60;
        this.show = false;
        this.timer = setInterval(() => {
          if (this.count > 0 && this.count <= 60) {
            this.count--;
          } else {
            this.show = true;
            clearInterval(this.timer);
            this.timer = null;
          }
        }, 1000);
      }
    },
    //登录
    login() {
      var data = {
        account: this.account,
        password: this.password,
      };
      axios
        .post("/wiiboox-admin/sysUser/login", data, {
          headers: {
            "content-type": "application/json",
          },
        })
        .then((res) => {
          if (res.code != 200) {
            this.$notify.info({
              title: "提示",
              message: res.message,
              type: "warning",
            });
          } else {
            cookie.set("token", res.data.token);
            let path = cookie.get("preRoute");
            let id = cookie.get("preRoute_id");
            let accuracyLevel = cookie.get("preRoute_accuracyLevel");
            if (path == "/model/modelDetail" && accuracyLevel != "undefined") {
              this.$router.push({
                path: path,
                query: { id: id, accuracyLevel: accuracyLevel },
              });
            } else if (
              path == "/model/modelDetail" &&
              accuracyLevel == "undefined"
            ) {
              this.$router.push({ path: path, query: { id: id } });
            } else {
              this.$router.push({ name: "index" });
            }
          }
        });
    },
  },

  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>

<style scoped>
#login {
  background: #2c2c2c;
  width: 100%;
  height: 100vh;
  /* width: 80%;
    height: 100vh;
    background: url('../assets/login/login_img.png')center / cover no-repeat; */
}
.login {
  width: 1200px;
  height: 80vh;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.login_img {
  width: 50%;
  height: 100%;
  display: inline-block;
}
.login_form {
  float: right;
  width: 50%;
  height: 100%;
  background: #333333;
}
.login_bar {
  color: #fff;
  font-size: 21px;
  font-family: "苹方" !important;
  width: 70%;
  margin: 100px auto;
}
.el-tabs >>> .el-tabs__item {
  color: #666666;
  font-size: 27px;
  font-family: "苹方" !important;
  font-weight: 500;
}
.el-tabs >>> .el-tabs__item.is-active {
  color: #fff;
}
.el-tabs >>> .el-tabs__nav {
  background: #333333;
  font-weight: 600;
}
.top_tab {
  color: #666666;
  width: 70%;
  margin: 0 auto;
  display: flex;
  justify-content: space-around;
}
.first {
}
.second {
  margin-left: 40px;
}
.active {
  color: #fff;
  border-bottom: 3px solid #2170c2;
  padding-bottom: 5px;
}

.ipt {
  font-size: 15px;
  color: #666666;
  margin-top: 50px;
  font-weight: 500;
  font-family: "苹方" !important;
}
.ipt1 {
  position: relative;
}
.ipt2 {
  margin-top: 50px;
  position: relative;
}
.el-input {
  margin-top: 10px;
}

.el-input--prefix >>> .el-input__inner {
  border: 0;
  background: #2c2c2c;
}
.ipt span {
  padding-bottom: -20px;
}
.ipt button {
  cursor: pointer;
  display: block;
  width: 80%;
  margin: 30px auto;
  border: 0;
  height: 43px;
  background: #1a8aff;
  font-size: 21px;
  color: #fff;
  border-radius: 25px;
  font-family: "苹方" !important;
  letter-spacing: 5px;
}
.inpt {
  height: 40px;
  width: 90%;
  padding-left: 35px;
  border: 0;
  background: #2c2c2c;
  margin-top: 10px;
  color: #fff;
}
.ipt1 img {
  position: absolute;
  top: 40px;
  left: 10px;
  width: 20px;
}
.ipt_img {
  width: 15px !important;
}
.ipt2 img {
  position: absolute;
  top: 37px;
  left: 10px;
  width: 20px;
}
.set {
  width: 80%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}
.register {
  color: #1a8aff;
}
.third {
  display: none;
}
.third_box {
  margin: 20px auto;
  width: 50%;
  display: flex;
  justify-content: space-around;
}
.line {
  width: 20%;
  height: 1px;
  background: #666666;
  display: block;
}
.third_text {
  position: relative;
  margin-top: 150px;
}
.line1 {
  position: absolute;
  left: 0;
  top: 10px;
  width: 35%;
}
.line2 {
  position: absolute;
  right: 0;
  bottom: 10px;
  width: 35%;
}
.texts {
  text-align: center;
}
.pr {
  position: relative;
}
#code-btn {
  position: absolute;
  top: 40px;
  right: 15px;
  z-index: 222;
  text-align: right;
  color: #1a8aff;
  font-size: 14px;
  border: none;
  padding-left: 10px;
  background-color: none;
  cursor: pointer;
  letter-spacing: 0;
}
a {
  text-decoration: none;
  color: #1a8aff;
}
.forget {
  cursor: pointer;
}
.login_img1{
  display: none;
}
@media only screen and (max-width: 767px) {
  .login_form{
   width: 100%;
    background: none;
    content: "";
    clear: both;
  }
  .login_img{
    width: 100%;
    height: 200px;
  }
  .login {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    height: auto;
    position: static;
    transform:translate(0,0)
  }
  .login_bar{
    margin: 20px auto;
  }
  .inpt{
    background: #000;
  }
  .login_img{
    display: none;
  }
  .login_img1{
    width: 100%;
    display: block;
    /* float: left; */
    /* top: 0; */
  }
  .login{
    top: 45%;
  }
}
</style>