<!-- 通知 -->
<template>
  <div class="notice">
    <div class="header">
      <span>评论回复</span>
    </div>
    <div class="content">
      <el-tabs type="border-card">
        <el-tab-pane>
          <div slot="label" @click="native(0)">我收到的评论</div>
          <MainComment1
            v-if="isRouterAlive"
            v-for="(comment, index) in list"
            :key="index"
            :commentData="comment"
          />
          <!-- <div class="list">
            <div class="list_head">
              <span class="sp1">用户：{{item.createBy}}</span
              ><span class="sp2">{{item.createTime}}</span>
              <p>对我的模型《萌虎_老虎_幼崽》发表了评论</p>
              <img src="../assets/test.png" alt="" class="head_img" />
            </div>
            <div class="list_content">
              <p>
               {{item.commentContent}}
              </p>
            </div>
            <div class="list_text">
              <div class="icon">
                <span class="icon_1"
                  ><img src="../assets/reply.png" alt="" />回复</span
                >
                <span class="icon_2"
                  ><img src="../assets/jubao.png" alt="" />举报</span
                >
              </div>
              <el-input
                type="textarea"
                :rows="3"
                placeholder="请输入内容"
                v-model="textarea"
              >
              </el-input>
              <div class="btn">
                <button class="gray">取消</button>
                <button class="blue">确认</button>
              </div>
            </div>
          </div> -->
        </el-tab-pane>
        <el-tab-pane>
          <div slot="label" @click="native(1)">我发出的评论</div>
          <MainComment1
            v-if="isRouterAlive"
            v-for="(comment, index) in list"
            :key="index"
            :commentData="comment"
            :num="num"
          />

          <!-- <div class="list">
            <div class="list_head">
              <span class="sp1">用户：Enjoying3d</span
              ><span class="sp2">2022/05/26 15:51</span>
              <p>对我的模型《萌虎_老虎_幼崽》发表了评论</p>
              <img src="../assets/test.png" alt="" class="head_img" />
            </div>
            <div class="list_content">
              <p>
                模型非常棒。什么时候我也能像作者一样优秀啊。太强了，我都只能默默的留言。这作品让我看到光辉，希望，以及人间的美好！
                这样的高级创作是真实存在的吗？这个模型是用什么渲染器渲染的啊？
              </p>
            </div>
          </div> -->
        </el-tab-pane>
      </el-tabs>
      <div class="paginationClass">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="page_current"
          :page-sizes="[10, 20, 30, 40]"
          :page-size="100"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import axios from "../../untils/request.js";
import MainComment1 from "../views/template/mainComment1.vue";
import qs from "qs";
import cookie from "../../public/js/cookie.js";
export default {
  name: "",

  //import引入的组件需要注入到对象中才能使用
  components: { MainComment1 },
  provide() {
    return {
      reload: this.reload,
    };
  },
  //父组件传入子组件数据
  props: {},

  //格式化数据
  filters: {},

  data() {
    //这里存放数据
    return {
      textarea: "",
      list: [],
      num: 1,
      isRouterAlive: true,
      page_current: 0,
      size: 0,
      total: 0,
    };
  },

  //计算属性 类似于data概念
  computed: {},

  //监控data中的数据变化
  watch: {},

  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.getList(this.num);
  },

  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},

  //方法集合
  methods: {
    reload() {
      this.isRouterAlive = false;
      this.$nextTick(function () {
        this.isRouterAlive = true;
      });
    },
    native(i) {
      this.num = i;
      this.getList(this.num);
    },
    getList(num) {
      if ((this.num = 1)) {
        this.num = num;
      }
      axios
        .get("/wiiboox-biz/tUserReplay/page?type=" + num, {
          headers: {
            "Content-type": "application/x-www-form-urlencoded",
            Authorization: "Bearer " + cookie.get("token"),
          },
        })
        .then((res) => {
          this.list = res.data.records;
          this.list.type = this.num;
          this.reload();
          this.page_current = res.data.current;
          this.size = res.data.size;
          this.total = res.data.total;
        });
    },
    //评论分页
    handleSizeChange(val) {
      this.size = val;
      axios
        .get(
          "/wiiboox-biz/tUserReplay/page?type=" +
            this.num +
            "&size=" +
            this.size +
            "&current=" +
            this.page_current,
          {
            headers: {
              "Content-type": "application/x-www-form-urlencoded",
              Authorization: "Bearer " + cookie.get("token"),
            },
          }
        )
        .then((res) => {
          this.list = res.data.records;
          this.page_current = res.data.current;
          this.size = res.data.size;
          this.total = res.data.total;
          this.reload();
        });
    },
    handleCurrentChange(val) {
      this.page_current = val;
      axios
        .get(
          "/wiiboox-biz/tUserReplay/page?type=" +
            this.num +
            "&size=" +
            this.size +
            "&current=" +
            this.page_current,
          {
            headers: {
              "Content-type": "application/x-www-form-urlencoded",
              Authorization: "Bearer " + cookie.get("token"),
            },
          }
        )
        .then((res) => {
          this.list = res.data.records;
          this.page_current = res.data.current;
          this.size = res.data.size;
          this.total = res.data.total;
          this.reload();
        });
    },
  },

  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>

<style scoped>
::v-deep .el-tabs--border-card > .el-tabs__header {
  background: #2a2a2a;
}
.el-tabs--border-card {
  border: 0;
  box-shadow: none;
}
::v-deep.el-tabs--border-card > .el-tabs__header .el-tabs__item.is-active {
  color: #ffffff;
  border: 0;
  background: #2a2a2a;
}
::v-deep.el-tabs--border-card > .el-tabs__header .el-tabs__item {
  border: 0;
  padding: 0 30px;
}
::v-deep
  .el-tabs--border-card
  > .el-tabs__header
  .el-tabs__item:not(.is-disabled):hover {
  color: #ffffff;
}
::v-deep .el-tabs__nav-wrap {
  margin-bottom: 0;
}
::v-deep .el-tabs__item.is-active {
  color: #ffffff;
}
.content {
  margin-top: 30px;
  margin-bottom: 30px;
}
::v-deep.el-tabs--border-card > .el-tabs__header {
  border: 0;
}
.el-tabs--border-card {
  background: #333333;
}
.list {
  background: #2a2a2a;
  position: relative;
  padding: 30px;
  margin-top: 20px;
}
.list .head_img {
  width: 57px;
  height: 57px;
  position: absolute;
  right: 50px;
  top: 26px;
}
.sp1 {
  color: #fff;
}
.sp2 {
  color: #666666;
  margin-left: 12px;
  font-size: 14px;
}
.list p {
  color: #666666;
  padding-top: 7px;
}
.list_head {
  border-bottom: 1px solid #333333;
  padding-bottom: 15px;
}
.list_content {
  font-size: 14px;
  border-bottom: 1px solid #333333;
  padding-bottom: 15px;
}
.list_content p {
  color: #999999;
}
.list_text .icon {
  padding: 10px;
  color: #666666;
  font-size: 14px;
}
.list_text .icon img {
  position: relative;
  top: 3px;
  right: 4px;
}
.list_text .icon .icon_2 {
  margin-left: 30px;
}
.btn {
  display: flex;
  margin-top: 20px;
  justify-content: right;
}
.blue {
  background: #1a8aff;
  color: #fff;
  padding: 2px 15px;
  border: none;
  border-radius: 5px;
  margin-left: 10px;
}
::v-deep .el-textarea__inner {
  background: #2a2a2a;
  border: 1px solid #6666;
  margin-top: 10px;
}
.gray {
  background: #333;
  border: 1px solid #4a4a4a;
  color: #fff;
  padding: 2px 15px;

  border-radius: 5px;
}
/***分页样式 */
.paginationClass {
  margin-top: 50px;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0);
  height: 40px;
  width: 100%;
  text-align: center;
  margin-top: 50px;

  /* padding-left: 15%; */
}
::v-deep .el-pager li {
  margin-left: 15px;
  background: #2b2b2b;
  color: #999999;
}
::v-deep .el-pagination .btn-next {
  margin-left: 15px;
}
::v-deep .el-pagination button:disabled {
  background: #2b2b2b !important;
  color: #999999;
}
::v-deep .el-pagination .btn-next {
  background: #2b2b2b !important;
  color: #999999;
}
::v-deep .el-pager li.btn-quicknext,
.el-pager li.btn-quickprev {
  background: #2b2b2b !important;
  color: #999999;
}
::v-deep .el-pagination .btn-prev {
  background: #2b2b2b !important;
  color: #999999;
}
::v-deep .el-pagination__editor.el-input .el-input__inner {
  background: #2b2b2b !important;
  color: #999999;
  border: 0;
}
::v-deep.el-pagination .el-select .el-input .el-input__inner {
  background: #2b2b2b !important;
  color: #999999;
  border: 0;
}
::v-deep .el-input__icon {
  line-height: 30px;
}
::v-deep .el-input__inner {
  color: #fff;
}
::v-deep .msg .el-textarea__inner {
  width: 97%;
  margin-left: 40px;
}
</style>