<!-- 轻量化 -->
<template>
  <div class="lightweight">
    <div class="header">
      <span>选择模型</span>
    </div>
    <el-row v-if="first" class="first">
      <el-table
        ref="multipleTable"
        :data="tableData"
        tooltip-effect="dark"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55"> </el-table-column>
        <!-- <el-table-column label="序号" width="60" type="index">
        </el-table-column> -->
        <el-table-column prop="name" label="模型名称" width="180">
        </el-table-column>
        <!-- <el-table-column label="上传状态" width="100">
          <template slot-scope="scope">
            <span v-if="scope.row.publishStatus == 0">上传失败</span>
            <span v-if="scope.row.publishStatus == 1">上传成功</span>
          </template>
        </el-table-column> -->
        <el-table-column prop="formatList" label="模型格式" width="100">
        </el-table-column>
        <el-table-column prop="size" label="模型大小" width="100">
        </el-table-column>
        <el-table-column prop="uploadTime" label="上传时间" width="160">
        </el-table-column>
      </el-table>
      <div class="paginationClass">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="page_current"
          :page-sizes="[10, 20, 30, 40]"
          :page-size="100"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
      <div class="text">
        <span>最多可选择<em>10</em>个模型</span>
        <span
          >已选择<em>{{ num }}</em
          >个模型</span
        >
      </div>
      <div class="btn">
        <button class="cancel" @click="cancel" v-preventReClick>取消</button>
        <button class="confirm" @click="confirm1" v-preventReClick>确认</button>
      </div>
    </el-row>
    <el-row
      v-if="second"
      class="second"
      v-loading="loading"
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(0, 0, 0, 0.8)"
    >
      <div style="width: 100%">
        <div class="header_small">
          <span>参数设置</span>
        </div>
        <div style="padding: 0 30px">
          <span>模型精度：</span>
          <el-select
            v-model="value1"
            placeholder="请选择精度"
            :popper-append-to-body="false"
          >
            <el-option
              :popper-append-to-body="false"
              v-for="item in options"
              :key="item.level"
              :label="item.label"
              :value="item.level"
            >
            </el-option>
          </el-select>
        </div>
        <div style="padding: 0 30px">
          <span>模型格式：</span>
          <el-select
            v-model="value2"
            placeholder="请选择格式"
            :popper-append-to-body="false"
          >
            <el-option
              :popper-append-to-body="false"
              v-for="item in options2"
              :key="item.id"
              :label="item.code"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>
      </div>

      <div class="btn">
        <button class="confirm" @click="confirm">开始轻量化</button>
      </div>
    </el-row>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import { json } from "body-parser";
import cookie from "../../../public/js/cookie";
import axios from "../../../untils/request";
import qs from "qs";
export default {
  name: "",

  //import引入的组件需要注入到对象中才能使用
  components: {},

  //父组件传入子组件数据
  props: {},

  //格式化数据
  filters: {},

  data() {
    //这里存放数据
    return {
      first: true,
      second: false,
      num: 0,
      id: "",
      ids: [],
      loading: false,
      tableData: [],
      fileId: "",
      fileIds: [],
      value1: "",
      options: [],
      value2: "",
      options2: [],
      multipleSelection: [],
      page_size: 0,
      total: 0,
      page_current: 1,
      status: "", 
    };
  },

  //计算属性 类似于data概念
  computed: {},

  //监控data中的数据变化
  watch: {},

  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    
    this.getOptions();
    this.getLevels();
    this.getList();
    this.status = this.$route.params.status
    if (this.$route.params.type != 1) {
      this.id = this.$route.params.row.id;
      this.status = this.$route.params.row.status
    } else {
      this.id == ''
    }
    this.show();
    // this.gettype();
  },

  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},

  //方法集合
  methods: {
    show() {
      if (this.status == 1) {
        this.second = true;
        this.first = false;
        this.fileId = this.id;
      } else {
        this.first = true;
        this.second = false;
      }
    },
    cancel() {
      this.$router.go(-1);
    },
    handleCurrentChange(val) {
      this.page_current = val;
      axios
        .get(
          "/wiiboox-biz/tModelProject/page?current=" +
            this.page_current +
            "&size=" +
            this.page_size +
            "&handleStatus=" +
            1,
          {
            headers: {
              "content-type": "application/x-www-form-urlencoded",
              Authorization: "Bearer " + cookie.get("token"),
            },
          }
        )
        .then((res) => {
          this.page_size = res.data.size;
          this.total = res.data.total;
          this.page_current = res.data.current;
          this.tableData = res.data.records;
          this.tableData.forEach((item, index) => {
            item.parentIndex = index + 1;
            item.isIndex = true;
          });
        });
    },
    handleSizeChange(val) {
      this.page_size = val;
      axios
        .get("/wiiboox-biz/tModelProject/page?size=" + this.page_size +
            "&handleStatus=" +
            1, {
          headers: {
            "content-type": "application/x-www-form-urlencoded",
            Authorization: "Bearer " + cookie.get("token"),
          },
        })
        .then((res) => {
          this.page_size = res.data.size;
          this.total = res.data.total;
          this.page_current = res.data.current;
          this.tableData = res.data.records;
          this.tableData.forEach((item, index) => {
            item.parentIndex = index + 1;
            item.isIndex = true;
          });
        });
    },
    // toggleSelection() {
    //   if (this.first == true) {
    //     if (this.tableData) {
    //       this.tableData.forEach((row) => {
    //         console.log(row);
    //         this.ids = row.id;
    //         this.$refs.multipleTable.toggleAllSelection();
    //         //element文档方法
    //       });
    //     } else {
    //       this.$refs.multipleTable.clearSelection();
    //     }
    //   }
    // },
    handleSelectionChange(val) {
      this.fileId = val;
      this.num = val.length;
    },
    //获取列表
    getList() {
              axios
          .get("/wiiboox-biz/tModelProject/page?handleStatus=" + 1, {
            headers: {
              "content-type": "application/x-www-form-urlencoded",
              Authorization: "Bearer " + cookie.get("token"),
            },
          })
          .then((res) => {
            this.page_size = res.data.size;
            this.total = res.data.total;
            this.page_current = res.data.current;
            this.tableData = res.data.records;
            // this.toggleSelection();
            this.tableData.forEach((item, index) => {
              item.parentIndex = index + 1;
              item.isIndex = true;
            });
          });
      return
      if (this.id == undefined && this.first != false) {
        console.log(1111111)
        axios
          .get("/wiiboox-biz/tModelProject/page?handleStatus=" + 1, {
            headers: {
              "content-type": "application/x-www-form-urlencoded",
              Authorization: "Bearer " + cookie.get("token"),
            },
          })
          .then((res) => {
            console.log(res.data.records);
            console.log(this.tableData);
            this.page_size = res.data.size;
            this.total = res.data.total;
            this.page_current = res.data.current;
            this.tableData = res.data.records;
            // this.toggleSelection();
            this.tableData.forEach((item, index) => {
              item.parentIndex = index + 1;
              item.isIndex = true;
            });
          });
      } else if (this.id != undefined  && this.first != false) {
        console.log(2222222)
        axios
          .get("/wiiboox-biz/tModelProject/page?handleStatus=" + 1, {
            headers: {
              "content-type": "application/x-www-form-urlencoded",
              Authorization: "Bearer " + cookie.get("token"),
            },
          })
          .then((res) => {
            console.log(res.data.records);
            console.log(this.tableData);
            this.page_size = res.data.size;
            this.total = res.data.total;
            this.page_current = res.data.current;
            this.tableData = res.data.records;
            // this.toggleSelection();
            this.tableData.forEach((item, index) => {
              item.parentIndex = index + 1;
              item.isIndex = true;
            });
          });
      }
      console.log(this.id);
    },
    // gettype() {
    //   axios
    //     .get("/wiiboox-biz/tModelformat/getAll", {
    //       headers: {
    //         "content-type": "application/x-www-form-urlencoded",
    //         Authorization: "Bearer " + cookie.get("token"),
    //       },
    //     })
    //     .then((res) => {
    //       console.log(res.data);
    //       this.options2 = [];
    //       res.data.forEach((item) => {
    //         this.options2.push({ label: item.code, value: item.id });
    //       });
    //     });
    // },
    confirm1() {
      this.first = false;
      this.second = true;
    },
    confirm() {
      this.loading = true;
      let data;
      if (this.id == '') {
        this.fileId.forEach((item) => {
          this.fileIds.push(item.id);
        });
        data = {
          accuracyLevel: this.value1,
          ids: this.fileIds.toString(),
          modelFormatId: this.value2,
        };
      } else {
        data = {
          accuracyLevel: this.value1,
          ids: this.id,
          modelFormatId: this.value2,
        };
      }
      axios
        .post("/wiiboox-biz/tModelFileCompress/compress", data, {
          headers: {
            "content-type": "application/json",
            Authorization: "Bearer " + cookie.get("token"),
          },
        })
        .then((res) => {
          if(res.code == 200){
            this.loading = false;
            this.$message({
              message: res.message,
              type: "success",
            });
            this.$router.push({ name: "results" });
          } else {
            this.$message(res.message)
          }
        });
    },
    getOptions() {
      axios
        .get("/wiiboox-biz/tModelFileCompress/compressTypes", {
          headers: {
            "content-type": "application/x-www-form-urlencoded",
            Authorization: "Bearer " + cookie.get("token"),
          },
        })
        .then((res) => {
          this.options2 = res.data;
        });
    },
    getLevels() {
      axios
        .get("/wiiboox-biz/tModelFileCompress/compressLevels", {
          headers: {
            "content-type": "application/x-www-form-urlencoded",
            Authorization: "Bearer " + cookie.get("token"),
          },
        })
        .then((res) => {
          this.options = res.data;
        });
    },
  },

  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>

<style scoped>
.header {
  color: #fff;
  font-size: 18px;
  border-bottom: 1px solid #2c2c2c;
  padding: 15px 30px;
}
.first {
  background: #333333;
  border: 1px solid #4a4a4a;
  border-radius: 5px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 50px;
}
.second {
  width: 700px;
  height: 250px;
  background: #333333;
  border: 1px solid #4a4a4a;
  border-radius: 5px;
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 10px;
}
::v-deep .el-table {
  width: 700px;
  min-height: 400px;
  background: #262626;
}
.el-table {
  background: #262626 !important;
  padding-left: 15px;
}
.el-table >>> tr {
  background: none !important;
  color: #999999 !important;
  border: none !important;
}
.el-table >>> th.el-table__cell {
  background: none !important;
  color: #ffffff !important;
}
.el-table::before {
  background: none;
}
>>> .el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background-color: #333333 !important;
  border-color: #999999 !important;
}
>>> .el-table--striped
  .el-table__body
  tr.el-table__row--striped
  td.el-table__cell {
  background: #2b2b2b !important;
  /* color: #ffffff !important; */
}
/* // 去掉表格单元格边框 */
.customer-table th {
  border: none;
}
.customer-table td,
.customer-table th.is-leaf {
  border: none;
}
/* // 表格最外边框 */
.el-table--border,
.el-table--group {
  border: none;
}
/* // 头部边框 */
.customer-table thead tr th.is-leaf {
  border: 1px solid #ebeef5;
  border-right: none;
}
.customer-table thead tr th:nth-last-of-type(2) {
  border-right: 1px solid #ebeef5;
}
/* // 表格最外层边框-底部边框 */
.el-table--border::after,
.el-table--group::after {
  width: 0;
}
>>> .el-checkbox__input.is-checked + .el-checkbox__label {
  color: #999999;
}
>>> .el-checkbox__input.is-checked .el-checkbox__inner,
.el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background-color: #333333;
  border-color: #999999;
}
.el-checkbox {
  margin-right: 15px;
}
>>> .el-checkbox__inner {
  background-color: #333333;
  color: #999999;
}
.customer-table::before {
  width: 0;
}
.customer-table .el-table__fixed-right::before,
.el-table__fixed::before {
  width: 0;
}
/* // 表格有滚动时表格头边框 */
.el-table--border th.gutter:last-of-type {
  border: 1px solid #ebeef5;
  border-left: none;
}
>>> .el-table td.el-table__cell,
.el-table th.el-table__cell.is-leaf {
  border: 0 !important;
}
.el-table td.el-table__cell,
::v-deep .el-table th.el-table__cell.is-leaf {
  border: 0 !important;
}
::v-deep .el-table--enable-row-hover .el-table__body tr:hover > td {
  background-color: #383838 !important;
}
::v-deep .el-checkbox__inner:hover {
  border-color: #fff !important;
}
::v-deep .el-checkbox__input.is-focus .el-checkbox__inner {
  border-color: #fff !important;
}
.text span {
  font-size: 12px;
  color: #999999;
}
.text {
  width: 80%;
  margin: 5px auto;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}
button {
  width: 90px;
  height: 23px;
  margin: 0 auto;
  border: 0;
  border-radius: 3px;
}
.btn {
  width: 40%;
  margin: -23px auto;
  display: flex;
}
.confirm {
  background: #1a8aff;
  color: #fff;
}
.cancel {
  background: #333;
  border: 1px solid #4a4a4a;
  color: #fff;
}
em {
  color: #1a8aff;
  font-style: initial;
}
.second .header_small {
  color: #ffffff;
  font-size: 15px;
  padding: 15px 30px;
  text-align: center;
}
.second .header_small span {
  color: #ffffff;
  font-size: 16px;
}
.el-select {
  width: 87%;
  margin-top: 20px;
}
.btn button {
  position: relative;
  top: 66px;
}
.second >>> .el-input__inner {
  background: #2c2c2c;
  height: 30px;
  border: 0;
}
/* .second >>>.el-input__suffix{
    top: 6px;
} */
.second span {
  font-size: 14px;
  color: #999999;
}
.el-select >>> .el-popper .popper__arrow {
  display: none !important;
}
/deep/ .el-select-dropdown {
  background-color: #2c2c2c;
  /* border: none; */
  border-color: #999999;
}
.el-select-dropdown__item {
  font-size: 13px;
  color: #999999;
  font-weight: 500;
}
/deep/.el-select-dropdown__list {
  padding: 0;
}
/deep/.el-popper[x-placement^="bottom"] {
  margin-top: 4px;
}
.paginationClass {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0);
  height: 40px;
  /* width: 100%; */
  text-align: center;
  /* padding-left: 15%; */
}
::v-deep .el-pager li {
  margin-left: 15px;
  background: #2b2b2b;
  color: #999999;
}
::v-deep .el-pagination .btn-next {
  margin-left: 15px;
}
::v-deep .el-pagination button:disabled {
  background: #2b2b2b !important;
  color: #999999;
}
::v-deep .el-pagination .btn-next {
  background: #2b2b2b !important;
  color: #999999;
}
::v-deep .el-pager li.btn-quicknext,
.el-pager li.btn-quickprev {
  background: #2b2b2b !important;
  color: #999999;
}
::v-deep .el-pagination .btn-prev {
  background: #2b2b2b !important;
  color: #999999;
}
::v-deep .el-pagination__editor.el-input .el-input__inner {
  background: #2b2b2b !important;
  color: #999999;
  border: 0;
}
::v-deep.el-pagination .el-select .el-input .el-input__inner {
  background: #2b2b2b !important;
  color: #999999;
  border: 0;
}
@media only screen and (max-width: 767px) {
  .second{
    width: 100%;
  }
 .first{
  width: 100%;
  padding: 10px;
 }
 .paginationClass{
  bottom: -50px;
 }
}
</style>